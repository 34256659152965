import { scrollToSection } from "src/component/scroll";

export const scrollAfterRouteChange = (
  id: any,
  location: any,
  e: any,
  history: any
) => {
  e.preventDefault();
  location.pathname === "/"
    ? scrollToSection(`#${id}`, e)
    : history.push("/", {
        to: `#${id}`,
      });
};
