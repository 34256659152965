import React from "react";
import footerLogo from "src/assets/images/footerLogo.svg";
import location from "src/assets/images/location.png";
import youtube from "src/assets/images/youtube.png";
import phone from "src/assets/images/phone.png";
import email from "src/assets/images/email.png";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  let lang = i18n.language;

  return (
    <div className="footer" id="footer">
      <div className="footer__items center-block">
        <div className="footer__item">
          <div className="footer__item-logo">
            <img src={footerLogo} alt="" />
          </div>
          <div className="footer__item-location">
            <div>
              <div className="footer__item-location-img">
                <img src={location} alt="" />
              </div>
              <span> {t("footer.address.city")},</span>
            </div>
            <span>{t("footer.address.street")}</span>
          </div>
        </div>
        <div className="footer__item">
          <div className="footer__item-title">{t("footer.titles.title1")}</div>
          <div className="footer__item-inner">
            <div className="footer__item-block">
              <div className="footer__item-block-phone">
                <img src={phone} alt="" />
              </div>
              <a href="tel:+998993709767" className="footer__item-block-info">
                {" "}
                +998(99)370-97-67
              </a>
            </div>
            <div className="footer__item-block">
              <div className="footer__item-block-email">
                <img src={email} alt="" />
              </div>
              <a
                href="mailto:sales@socialprogress.uz"
                className="footer__item-block-info"
              >
                sales@socialprogress.uz
              </a>
            </div>
          </div>
        </div>
        <div className="footer__item">
          <div className="footer__item-title">{t("footer.titles.title2")}</div>
          <div className="footer__item-inner">
            {/* <div className="footer__item-block">
              <div className="footer__item-block-phone">
                <img src={phone} alt="" />
              </div>
              <a href="tel:+998993709747" className="footer__item-block-info">
                +998(99)370-97-47
              </a>
            </div> */}
            <div className="footer__item-block">
              <div className="footer__item-block-email">
                <img src={email} alt="" />
              </div>
              <a
                href="mailto:support@socialprogress.uz"
                className="footer__item-block-info"
              >
                support@socialprogress.uz
              </a>
            </div>
          </div>
        </div>
        <div className="footer__item">
          <div className="footer__item-title">{t("footer.titles.title3")}</div>
          <div className="footer__item-inner">
            <a
              target="blank"
              href="https://www.youtube.com/user/SIMICONTV"
              className="footer__item-block"
            >
              <div className="footer__item-block-img footer__item-block-youtube">
                <img src={youtube} alt="" />
              </div>
              <div className="footer__item-block-info"> Youtube</div>
            </a>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <div className="center-block">
          &copy;{new Date().getFullYear()}&nbsp;
          {lang === "ru"
            ? t("copyright.copyright")
            : lang === "en"
            ? t("copyright.copyright")
            : ""}
          {lang === "уз"
            ? t("copyright.copyright.copyright1")
            : lang === "o'z"
            ? t("copyright.copyright.copyright1")
            : ""}
          &nbsp;<a href="/">Social Progress</a>.&nbsp;
          {lang === "уз"
            ? t("copyright.copyright.copyright2")
            : lang === "o'z"
            ? t("copyright.copyright.copyright2")
            : ""}
          &nbsp;{t("copyright.rights")}
        </div>
      </div>
    </div>
  );
};

export default Footer;
