import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

import crossmain from "src/assets/images/crossmain.png";
import cordon from "src/assets/images/cordoncross.png";
import arrow from "src/assets/images/arrow.svg";

// slider
import cordonSlider1 from "src/assets/images/image 11.png";
import cordonSlider2 from "src/assets/images/image 12.png";
import cordonSlider3 from "src/assets/images/image 13.png";

// road signs
import one from "src/assets/images/01.svg";
import two from "src/assets/images/02.svg";
import three from "src/assets/images/03.svg";
import four from "src/assets/images/04.svg";
import five from "src/assets/images/05.svg";
import six from "src/assets/images/06.svg";
import seven from "src/assets/images/07.svg";
import eight from "src/assets/images/08.svg";
import nine from "src/assets/images/09.svg";
import ten from "src/assets/images/10.svg";
import eleven from "src/assets/images/11.svg";
import twelve from "src/assets/images/12.svg";
import thirteen from "src/assets/images/13.svg";
import fourteen from "src/assets/images/14.svg";
import fiveteen from "src/assets/images/15.svg";
import sixteen from "src/assets/images/16.svg";
import seventeen from "src/assets/images/17.svg";
import eightteen from "src/assets/images/18.svg";

// functions
import func1 from "src/assets/images/1111.png";
import func2 from "src/assets/images/1112.png";
import func3 from "src/assets/images/1113.png";
import func4 from "src/assets/images/1114.png";
import func5 from "src/assets/images/1115.png";
import func6 from "src/assets/images/1116.png";
import func7 from "src/assets/images/1117.svg";
import func8 from "src/assets/images/1118.svg";

// guarantee
import guarantee1 from "src/assets/images/1.1.png";
import guarantee2 from "src/assets/images/1.2.png";
import guarantee3 from "src/assets/images/1.3.png";
import guarantee4 from "src/assets/images/1.4.svg";
import guarantee5 from "src/assets/images/1.5.svg";

import schemeRu from "src/assets/images/schemeRu.png";
import schemeUz from "src/assets/images/schemeUz.jpg";
import schemeOz from "src/assets/images/schemeOz.jpg";
import schemeEn from "src/assets/images/schemeEn.jpg";

import compoundRu from "src/assets/images/compoundRu.png";
import compoundOz from "src/assets/images/compoundUz.jpg";
import compoundUz from "src/assets/images/compoundOz.jpg";
import compoundEn from "src/assets/images/compoundEn.jpg";

const CordonCross = () => {
  const [scroll, setScroll] = useState(false);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  console.log(history.location.pathname);

  const baseUrl = "images/slider/image 1";
  const settings = {
    customPaging: function (i: any) {
      return <img src={`${baseUrl}${i + 1}.png`} alt="" />;
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`next__arr ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span> &gt;</span>
      </button>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`prev__arr ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span>&lt;</span>
      </button>
    );
  }

  useEffect(() => {
    const scrollEvent = () => {
      setScroll(window.scrollY > 50);
    };
    window.addEventListener("scroll", scrollEvent);
    return () => window.removeEventListener("scroll", scrollEvent);
  }, []);
  return (
    <div className="main" id="main">
      <Header className={scroll ? "scrolled" : ""} />
      <div className="video">
        <div className="video__inner center-block">
          <img
            src={crossmain}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              bottom: "0",
              right: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              pointerEvents: "none",
            }}
            alt=""
          />
          <div className="video__content">
            <div className="video__content-btns">
              <Link
                to="/"
                className={`video__content-btn ${
                  history.location.pathname === "/" ? "active" : ""
                }`}
              >
                {t("cross.link.link1")}
              </Link>
              <Link
                to="/CordonCross"
                className={`video__content-btn ${
                  history.location.pathname === "/CordonCross" ? "active" : ""
                }`}
              >
                {t("cross.link.link2")}
              </Link>
            </div>
            <div className="video__title">
              <strong>{t("cross.title.top")}</strong>
              <span>{t("cross.title.bot")}</span>
            </div>
            <div className="video__subtitle">{t("cross.subtitle")}</div>
            <Link to="/WorkInMove" className="video__button">
              {t("video.button")}
            </Link>
          </div>
        </div>
      </div>
      <div className="cordon" id="cordon">
        <div className="cordon__content center-block">
          <div className="cordon__img">
            <img src={cordon} alt="" />
          </div>
          <div className="cordon__data">
            <div className="cordon__title" style={{ fontSize: "50px" }}>
              {t("cross.link.link2")}
            </div>
            <div className="cordon__subtitle">{t("cordon.subtitle")}</div>
            <p className="cordon__info">{t("cross.cordon.subtitle")}</p>
            <div className="cordon__qualities">
              <div className="cordon__qualities-title">
                {t("qualities.title")}
              </div>
              <div className="cordon__qualities-list">
                <ul>
                  <li>
                    <img src={arrow} alt="" className="list-arrow" />
                    {t("qualities.list.speed")}
                  </li>
                  <li>
                    <img src={arrow} alt="" className="list-arrow" />
                    {t("qualities.list.install")}
                  </li>
                  <li>
                    <img src={arrow} alt="" className="list-arrow" />
                    {t("qualities.list.switch")}
                  </li>
                  <li>
                    <img src={arrow} alt="" className="list-arrow" />
                    {t("qualities.list.recognition")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Slider {...settings} className="cordon__slider">
          <div className="cordon__slider-item">
            <img src={cordonSlider1} alt="" />
            <div className="cordon__slider-text">
              <div className="cordon__slider-title">
                {t("cross.slider.slider1.title")}
              </div>
              <p className="cordon__slider-subtitle">
                {t("cross.slider.slider1.subtitle")}
              </p>
            </div>
          </div>
          <div className="cordon__slider-item">
            <img src={cordonSlider2} alt="" />
            <div className="cordon__slider-text">
              <div className="cordon__slider-title">
                {t("cross.slider.slider2.title")}
              </div>
              <p className="cordon__slider-subtitle">
                {t("cross.slider.slider2.subtitle")}
              </p>
            </div>
          </div>
          <div className="cordon__slider-item">
            <img src={cordonSlider3} alt="" />
            <div className="cordon__slider-text">
              <div className="cordon__slider-title">
                {t("cross.slider.slider3.title")}
              </div>
              <p className="cordon__slider-subtitle">
                {t("cross.slider.slider3.subtitle")}
              </p>
            </div>
          </div>
        </Slider>
      </div>
      <div className="fixation" id="fixation">
        <div className="fixation__inner center-block">
          <div className="fixation__title">{t("cross.fixation.title")} </div>
          <p className="fixation__subtitle">{t("cross.fixation.subtitle")}</p>
          <div className="fixation__items">
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={one} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item1")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={two} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item2")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={three} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item3")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={four} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item4")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={five} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item5")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={six} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item6")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={seven} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item7")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={eight} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item8")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={nine} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item9")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={ten} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item10")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={eleven} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item11")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={twelve} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item12")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={thirteen} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item13")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={fourteen} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item14")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={fiveteen} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item15")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={sixteen} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item16")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={seventeen} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item17")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={eightteen} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("cross.fixation.items.item18")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="compound" id="compound">
        <div className="compound__inner center-block">
          <div className="compound__title">{t("cross.compound.title")}</div>
          <div className="compound__subtitle">
            {t("cross.compound.subtitle")}
          </div>
          <div className="compound__img">
            <img
              src={
                i18n.language === "ru"
                  ? compoundRu
                  : i18n.language === "уз"
                  ? compoundUz
                  : i18n.language === "o'z"
                  ? compoundOz
                  : i18n.language === "en"
                  ? compoundEn
                  : compoundRu
              }
              alt=""
            />
          </div>
          <div className="compound__text">
            <p>{t("cross.compound.text.text1")}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: t("cross.compound.text.text2"),
              }}
            ></p>
            <p>{t("cross.compound.text.text1")}</p>
          </div>
        </div>
      </div>
      <div className="scheme" id="scheme">
        <div className="scheme__inner center-block">
          <div className="scheme__title">{t("cross.scheme.title")}</div>
          <div className="scheme__subtitle">{t("cross.scheme.subtitle")}</div>
          <div className="scheme__img">
            <img
              src={
                i18n.language === "ru"
                  ? schemeRu
                  : i18n.language === "уз"
                  ? schemeOz
                  : i18n.language === "o'z"
                  ? schemeUz
                  : i18n.language === "en"
                  ? schemeEn
                  : schemeRu
              }
              alt=""
            />
          </div>
          <div className="scheme__text">
            <div className="scheme__text-top">
              <p>{t("cross.scheme.text.text1")}</p>
              <p>{t("cross.scheme.text.text2")}</p>
            </div>
            <div className="scheme__text-bot">
              <p>{t("cross.scheme.text.text3")}</p>
              <p>{t("cross.scheme.text.text4")}</p>
              <p>{t("cross.scheme.text.text5")}</p>
              <p>{t("cross.scheme.text.text6")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="functions" id="functions">
        <div className="functions__inner center-block">
          <div className="functions__title">{t("cross.functions.title")} </div>
          <div className="functions__items">
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func1} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("cross.functions.items.item1.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("cross.functions.items.item1.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func2} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("cross.functions.items.item2.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("cross.functions.items.item2.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func3} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("cross.functions.items.item3.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("cross.functions.items.item3.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func4} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("cross.functions.items.item4.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("cross.functions.items.item4.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func5} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("cross.functions.items.item5.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("cross.functions.items.item5.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func6} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("cross.functions.items.item6.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("cross.functions.items.item6.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func7} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("cross.functions.items.item7.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("cross.functions.items.item7.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func8} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("cross.functions.items.item8.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("cross.functions.items.item8.info"),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="guarantee center-block">
        <div className="guarantee__title">{t("guarantee.title")} </div>
        <div className="guarantee__top">
          <div className="guarantee__top-item">
            <div className="guarantee__top-img">
              <img src={guarantee1} alt="" />
            </div>
            <div
              className="guarantee__top-text"
              dangerouslySetInnerHTML={{ __html: t("guarantee.top.item1") }}
            ></div>
          </div>
          <div className="guarantee__top-item">
            <div className="guarantee__top-img">
              <img src={guarantee2} alt="" />
            </div>
            <div
              className="guarantee__top-text"
              dangerouslySetInnerHTML={{ __html: t("guarantee.top.item2") }}
            ></div>
          </div>
        </div>
        <div className="guarantee__bot">
          <div className="guarantee__bot-img">
            <img src={guarantee3} alt="" />
          </div>
          <div className="guarantee__bot-group">
            <div className="guarantee__bot-text">
              {t("guarantee.bot.item1")}
            </div>
            <div className="guarantee__bot-text">
              {t("guarantee.bot.item2")}
            </div>
          </div>
        </div>
        <div className="guarantee__bot">
          <div className="guarantee__bot-img">
            <img src={guarantee4} alt="" />
          </div>
          <div className="guarantee__bot-group">
            <div className="guarantee__bot-text" style={{ marginBottom: "0" }}>
              {t("cross.guarantee.bot1")}
            </div>
          </div>
        </div>
        <div className="guarantee__bot">
          <div className="guarantee__bot-img">
            <img src={guarantee5} alt="" />
          </div>
          <div className="guarantee__bot-group">
            <div className="guarantee__bot-text" style={{ marginBottom: "0" }}>
              {t("cross.guarantee.bot2")}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CordonCross;
