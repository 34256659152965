//eslint-disable-next-line
export default {
  header: {
    purpose: "назначение",
    workPrinciples: "ПРИНЦИП РАБОТЫ",
    installation: "УСТАНОВКА",
    functions: "функции",
    specifications: "ХАРАКТЕРИСТИКИ",
    questions: "вопросы",
    contacts: "контакты",
  },
  video: {
    title: {
      top: "измерения в движении",
      bottom: "кордон.про",
    },
    subtitle: "Новый универсальный комплекс фотовидеофиксации нарушений ПДД",
    button: "узнать больше",
  },
  cordon: {
    title: "КОРДОН.ПРО",
    subtitle: "КОНТРОЛЬ МГНОВЕННОЙ И СРЕДНЕЙ СКОРОСТИ",
    info: "Комплекс предназначен для автоматической фотовидеофиксации нарушений ПДД в различных режимах (мобильный, передвижной, стационарный) и передачи данных о зафиксированных нарушениях в режиме реального времени на сервер ЦАФАП.",
  },
  qualities: {
    title: "Ключевые особенности:",
    list: {
      speed:
        "Автоматическое измерение скорости и фотовидеофиксация нарушений ПДД.",
      install:
        "Возможность работы в движении при установке комплекса в автомобиле.",
      switch:
        "Возможность оперативного переключения между стационарным, передвижным и мобильным режимами работы.",
      recognition:
        "Распознавание номеров и классификация ТС с одновременной проверкой по загруженным в память базам данных.",
    },
  },
  slider: {
    slide1: {
      title: "РАБОТАЕТ В ДВИЖЕНИИ",
      subtitle: "Работает в движении без изменения конструкции автомобиля.",
    },
    slide2: {
      title: "УНИВЕРСАЛЬНЫЙ",
      subtitle: "Работает в стационарном, передвижном и мобильном режимах",
    },
    slide3: {
      title: "НАДЕЖНЫЙ",
      subtitle:
        "Разработан на базе проверенных временем комплексов «КОРДОН», «КОРДОН-М»",
    },
  },
  fixation: {
    title: "ЧТО ФИКСИРУЕТ",
    subtitle:
      "Фиксируемые комплексом нарушения ПДД. Возможность фиксации тех или иных нарушений зависит от выбранного размещения и режима работы комплекса.",
    items: {
      item1:
        "Превышение установленной скорости движения (мгновенной и/или средней)",
      item2: "Выезд на полосу, предназначенную для встречного движения",
      item3: "Выезд на трамвайные пути встречного направления",
      item4:
        "Движение во встречном направлении по дороге с односторонним движением.",
      item5: "Движение по разделительной полосе",
      item6: "Движение по полосе для общественного транспорта",
      item7: "Движение по обочине",
      item8: "Движение по велосипедным или пешеходным дорожкам либо тротуарам",
      item9: "Пересечение сплошной линии разметки при перестроении.",
      item10: "Нарушение запрета движения грузовых ТС",
      item11: "Движение грузовых ТС далее второй полосы.",
      item12: "Проезд знака СТОП без остановки",
    },
  },
  work: {
    title: "КАК ЭТО РАБОТАЕТ",
    subtitle:
      "Новый универсальный автоматический комплекс фотовидеофиксации «КОРДОН.ПРО» обладает уникальными возможностями.",
    links: {
      link1: "Измерение скорости",
      link2: "Монтаж и настройка",
      link3: "Как работает в движении",
      link4: "На крыше автомобиля",
      link5: "Для чего классификатор",
      link6: "Защита от огнестрела",
      link7: "Розыск ТС по базам",
      link8: "Примеры нарушений",
    },
    previews: {
      preview: "Автоматический контроль мгновенной и средней скорости",
      preview1: {
        top: "РАБОТА В ДВИЖЕНИИ",
        bot: "Установка без доработок и изменений конструкции автомобиля",
      },
      preview2: {
        top: "КЛАССИФИКАТОР",
        bot: "Определение категории ТС по изображению",
      },
      preview3: {
        top: "РОЗЫСК",
        bot: "Распознавание номеров и розыск ТС по загруженных базам данных",
      },
      preview4: {
        top: "НАСТРОЙКА И МОНТАЖ",
        bot: "Простая и быстрая настройка",
      },
      preview5: {
        top: "Р-АВТО",
        bot: "Установка комплекса на крыше автомобиля",
      },
      preview6: {
        top: "БРОНЕЗАЩИТА",
        bot: "Защита от огнестрельного оружия",
      },
      preview7: {
        top: "ДОКАЗАТЕЛЬНАЯ БАЗА",
        bot: "Примеры зафиксированных нарушений",
      },
    },
  },
  installation: {
    title: "ГДЕ МОЖНО УСТАНОВИТЬ",
    subtitle:
      "Комплекс может работать в мобильном, передвижном и стационарном режимах.",
    text: "Комплекс имеет несколько способов установки и подключения питания, в зависимости от решаемых задач и технических возможностей на контролируемых участках. Заказчик может выбрать при заказе необходимый монтажно-эксплуатационный комплект.",
    tabs: {
      tab1: "СТАЦИОНАРНЫЙ РЕЖИМ",
      tab2: "ПЕРЕДВИЖНОЙ РЕЖИМ",
      tab3: "МОБИЛЬНЫЙ РЕЖИМ",
    },
    tabpanes: {
      tabpane1: {
        paragraph1: {
          title: "Установка с подключением к сети 220В",
          text: "Комплект с кронштейном крепления на опору с подключением к сети 220 В. Устанавливается рядом с проезжей частью дороги на высоте до 10 метров. Допускается также установка над проезжей частью или разделительной полосой дороги.",
        },
        paragraph2: {
          title: "Установка c подключением к осветительной сети",
          text: "Комплект крепления на опору с блоками БПФ для подключения к осветительной сети, работающей в ночное время. Аккумулятор постоянно подключен к сети уличного освещения через блок БПФ24/220, который обеспечивает его подзарядку в ночное время.",
        },
      },
      tabpane2: {
        paragraph1: {
          title: "Установка на штативе",
          text: "Комплект на штативе с аккумуляторным боксом. В комплект может входить планшет или ноутбук. Датчик устанавливается сбоку от проезжей части дороги и подключается к аккумуляторному боксу.",
        },
        paragraph2: {
          title: "Установка в автомобиле",
          text: "Датчик, установленный на заднем сидении или в багажнике автомобиля может эксплуатироваться не только в мобильном, но и передвижном режиме. При необходимости автомобиль можно припарковать на контролируемом участке и оперативно переключить комплекс в передвижной режим.",
        },
        paragraph3: {
          title: "«Р-Авто» — установка на крыше автомобиля",
          text: `Комплект крепления на крыше автомобиля. В комплект также входит аккумуляторный бокс и ноутбук. <a href="/">Подробнее</a>`,
        },
      },
      tabpane3: {
        paragraph1: {
          title: "Установка в автомобиле",
          text: `<div class="installation__tabPane-text">
          <strong>Комплект «С-Авто»</strong>
          Комплект для установки комплекса в транспортных средствах с
          кузовом типа хэтчбек, микроавтобус, внедорожник или
          универсал. Датчик устанавливается в багажнике патрульного
          автомобиля на мини-штативе с дополнительной фиксацией для
          обеспечения жесткости и надёжности фиксации.
        </div>
        <div class="installation__tabPane-text">
          <strong>Комплект «Д-Авто»</strong>
          Комплект для установки комплекса внутри салона легкового
          автомобиля. Датчик крепится на заднем сидении с помощью
          специализированного кронштейна, адаптера подголовника и
          штатных креплений стандарта ISOFIX. Комплект учитывает
          особенности конструкции автомобиля Заказчика (марка
          автомобиля указывается при заказе).
        </div>
        <div class="installation__tabPane-text">
          Оба комплекта включают в себя аккумуляторный бокс для
          питания комплекса и планшет оператора. Для установки
          комплекса не требуется вносить какие-либо изменения и
          доработки в конструкцию автомобиля.
          <strong>
            Монтаж комплекса в обоих вариантах занимает не более 5
            минут.
          </strong>
        </div>`,
        },
      },
    },
  },
  functions: {
    title: "КАКИЕ ОСНОВНЫЕ ФУНКЦИИ",
    items: {
      item1: {
        title: "АВТОМАТИЧЕСКАЯ ФОТОВИДЕОФИКСАЦИЯ",
        info: `<ul>
        <li>
          Автоматическая фотовидеофиксация нарушений ПДД в зоне
          контроля <strong>до шести полос движения</strong>
          одновременно в обоих направлениях.
        </li>
        <li>
          Отдельные пороги скорости для разных полос движения и для
          ТС категорий «В», «С» и «D».
        </li>
        <li>
          Автоматическое сохранение фотоматериалов и видеоролика по
          каждому зафиксированному нарушению.
        </li>
        <li>
          Модуль ГЛОНАСС/GPS с автоматической коррекцией системного
          времени комплекса.
        </li>
      </ul>`,
      },
      item2: {
        title: "РАСПОЗНАВАНИЕ НОМЕРНЫХ ЗНАКОВ",
        info: `<ul>
        <li>
          Автоматическое распознавание номерных знаков многих стран
          мира, включая
          <strong>
            двустрочные номера и российские ГРЗ нового образца
          </strong>
          по ГОСТ Р 50577-2018.
        </li>
        <li>
          Воможность включения и выключения распознавания ГРЗ тех
          или иных государств.
        </li>
        <li>
          Проверка распознанных номеров по базам данных с
          оповещением оператора и голосовым озвучиванием номера.
        </li>
        <li>
          Специальный режим работы <strong>«Перехват»</strong> для
          розыска ТС с отсутствующими или нечитаемыми номерными
          знаками.
        </li>
      </ul>`,
      },
      item3: {
        title: "КЛАССИФИКАЦИЯ ТС",
        info: `<ul>
        <li>
          Автоматическое определение типа ТС и их
          <strong>
            классификация по четырём основным категориям
          </strong>
          классификация по четырём основным категориям
        </li>
        <li>
          Автоматическое присвоение соответствующей категории ТС
          порога скорости по ПДД.
        </li>
        <li>
          Автоматический контроль запрета движения ТС для заданной
          категории (грузовые, автобусы и т.д.) по отдельным полосам
          или по дороге в целом.
        </li>
      </ul>`,
      },
      item4: {
        title: "ПЕРЕДАЧА ДАННЫХ",
        info: `<ul>
        <li>
        Передача данных на сервер ЦОД по зашифрованным проводным или беспроводным каналам связи (3G/4G).
        </li>
        <li>
          Автоматическое переключение на резервные каналы связи
          (Wi-Fi, 4G) при сбоях или отказе основного канала.
        </li>
        <li>
          Возможность параллельной передачи данных с комплекса на
          различные серверы.
        </li>
      </ul>`,
      },
      item5: {
        title: "ВИДЕОНАБЛЮДЕНИЕ",
        info: `<ul>
        <li>
          Трансляция видеопотока с высоким разрешением по протоколу
          RTSP.
        </li>
        <li>
          Ведение непрерывной видеозаписи с возможностью скачивания
          видеоролика по заданному промежутку времени.
        </li>
      </ul>`,
      },
      item6: {
        title: "РАБОТА В НОЧНОЕ ВРЕМЯ",
        info: `<ul>
        <li>
          Встроенная инфракрасная подсветка для работы в ночное
          время.
        </li>
        <li>
          Дополнительный внешний ИК-прожектор для гарантированного
          определения марки ТС по изображению.
        </li>
      </ul>`,
      },
    },
  },
  specifications: {
    title: "ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ",
    tabs: {
      tab1: {
        title: "МЕТРОЛОГИЧЕСКИЕ ПАРАМЕТРЫ",
        text: `<table>
        <tbody>
          <tr>
            <td>Рабочая частота излучения</td>
            <td>от 24,05 до 24,25 ГГц</td>
          </tr>
          <tr>
            <td>Диапазон измеряемых скоростей</td>
            <td>2 — 300 км/ч</td>
          </tr>
          <tr>
            <td>
              Пределы допускаемой абсолютной погрешности измерений
              скорости
            </td>
            <td>± 1,0 км/ч</td>
          </tr>
          <tr>
            <td>
              Пределы допускаемой абсолютной погрешности синхронизации
              внутренней шкалы времени с UTC(SU)
            </td>
            <td>± 5 мкс</td>
          </tr>
        </tbody>
      </table>`,
      },
      tab2: {
        title: "ЭЛЕКТРОПИТАНИЕ И МАССОГАБАРИТЫ",
        text: `<table>
        <tbody>
          <tr>
            <td>
              Потребляемая мощность (при положительных температурах)
            </td>
            <td>не более 25 Вт</td>
          </tr>
          <tr>
            <td>Напряжение питания постоянного тока</td>
            <td>10—16 В</td>
          </tr>
          <tr>
            <td>
              Напряжение питания переменного тока с поставляемым блоком
              питания
            </td>
            <td>90—300 В</td>
          </tr>
          <tr>
            <td>Масса датчика</td>
            <td>не более 6,0 кг</td>
          </tr>
          <tr>
            <td>Габаритные размеры</td>
            <td>не более 460×180×280 мм</td>
          </tr>
        </tbody>
      </table>`,
      },
      tab3: {
        title: "РАБОЧИЕ УСЛОВИЯ И НАДЕЖНОСТЬ",
        text: `<table>
        <tbody>
          <tr>
            <td>
              Температура окружающего воздуха:
              <div></div>
              — стандартное исполнение
              <br />— арктическое исполнение
            </td>
            <td>
              <div></div>
              <br />
              <div></div>
              от −40°С до +60°С
              <br />
              от −55°С до +60°С
              <br />
            </td>
          </tr>
          <tr>
            <td>Относительная влажность воздуха</td>
            <td>98% при t°=+25°С</td>
          </tr>
          <tr>
            <td>Атмосферное давление</td>
            <td>от 60 до 106,7 кПа</td>
          </tr>
          <tr>
            <td>Средняя наработка на отказ</td>
            <td>не менее 35 000 часов</td>
          </tr>
          <tr>
            <td>Средний срок службы до списания </td>
            <td>не менее 6 лет</td>
          </tr>
          <tr>
            <td>
              Степень защиты оболочки от проникновения твердых предметов
              и воды
            </td>
            <td>IP65</td>
          </tr>
          <tr>
            <td>
              Степень защиты оболочки от наружного механического удара
            </td>
            <td>IK08</td>
          </tr>
          <tr>
            <td>
              Класс защиты от стрелкового оружия (с использованием
              бронированного кожуха)
            </td>
            <td>«Бр2» и «С1»</td>
          </tr>
        </tbody>
      </table>`,
      },
      tab4: {
        title: "ИНФОРМАЦИОННАЯ БЕЗОПАСНОСТЬ",
        lists: {
          list1:
            "Защита данных и встроенного ПО от несанкционированных изменений.",
          list2:
            "Экспортируемые данные защищены ЭЦП с помощью сертифицированного аппаратного ключа.",
          list3: "Ведение журнала событий и действий пользователя комплекса.",
          list4:
            "Передача уведомлений по SMS и электронной почте о зафиксированных фактах физических воздействий на прибор (удары, вибрация).",
        },
      },
    },
  },
  statistics: {
    items: {
      item1: {
        title: "ВЕРОЯТНОСТЬ РАСПОЗНАВАНИЯ",
        progressbar: {
          progressbar1: "Распознавание ГНЗ (98%)",
          progressbar2: "Классификация ТС по изображению (95%)",
        },
      },
      item2: {
        title: "ПОКАЗАТЕЛИ ПОГРЕШНОСТИ",
        progressbar: {
          progressbar1: "Измерение скорости (± 1,0 км/ч)",
          progressbar2: "Синхронизация времени с UTC (± 5 мкс)",
        },
      },
    },
  },
  guarantee: {
    title: "СЕРТИФИКАЦИЯ И ГАРАНТИЯ",
    top: {
      item1: `Межповерочный интервал – <span>2 года</span>`,
      item2: `Гарантийный срок – <span>2 года</span>`,
    },
    bot: {
      item1: `Сертификат утверждения типа средств измерений O'T 0000590 №02-2.0070 Узбекского агентства стандартизации, метрологии и сертификации (Агентство "Узстандарт") Сертификат соответствия Национальной системы сертификации Республики Узбекистан № UZ.SMT.01.354.72116889 от 13.12.2021г. Свидетельство об утверждении типа средств измерений ОС.C.33.158.A №75141 Федерального агентства по техническому регулированию и метрологии Российской Федерации.`,
      item2:
        "Сертификат соответствия Таможенного Союза № TC RU C-RU.ME83.B.00002/18.",
    },
  },
  faq: {
    title: "ВОПРОСЫ И ОТВЕТЫ",
    subtitle: "Наиболее часто задаваемые вопросы по комплексу «КОРДОН.Про»М",
    text: "После представления комплекса «Кордон.Про»М на международной выставке «Дорога 2019» к нам поступило большое количество вопросов от журналистов. Кроме того, в прессе появились публикации, которые неточно (а иногда просто неверно!) объясняют техническую сторону этого проекта. Ниже даны ответы и разъяснения по наиболее важным особенностям комплекса «Кордон.Про»М:",
    accordion: {
      accordion1: {
        head: "Насколько законно применение комплекса в движении, установленного в патрульном автомобиле, если на контролируемом участке дороги отсутствует предупреждающий знак фотовидеофиксации?",
        body: `<p>
        Знак 5.43 «Радар» или знаки 5.41 и 7.19  «Фото- и видео фиксация», согласно ПДД Узбекистана (постановление Кабинета Министров Узбекистана от 24 декабря 2015 года № 370), должны устанавливаться перед местами размещения стационарных комплексов. «Кордон.Про» — в зависимости от их функций. И при использовании его в стационарном режиме предупреждающий знак, разумеется, должен быть установлен.
      </p>
      <p>
        Необходимо отметить, что электронные табло с предупреждениями
        об автоматическом контроле скорости на той или иной
        автомагистрали широко используются уже сегодня. Так что
        водитель всегда предупрежден, что он под контролем.
      </p>
      <p>
        Но возможности искусственного интеллекта растут, и завтра
        такие предупреждения могут появиться и на въезде в город. И
        что ж тут удивительного? Мы искренне надеемся, что вы тоже не
        против необходимости обуздать, наконец, гонщиков-экстремалов
        на наших дорогах.
      </p>`,
      },
      accordion2: {
        head: "В чем заключается инновационность комплекса «Кордон.Про», ведь приборы, установленные в машине и измеряющие скорость в движении, существовали давно??",
        body: `<p>
        Совершенно верно, именно компания Симикон 
        <strong>впервые в России в 1999 году </strong> выпустила
        полицейский радар, работающий в движении. Прибор назывался 
        <strong>«Искра-1Д»</strong> , он был внесен в реестр средств
        измерений РФ и обеспечивал радиолокационное измерение
        собственной скорости и скорости автомобиля-нарушителя. Решение
        было запатентовано. Так что алгоритмы радиолокационного
        измерения собственной скорости разрабатываются и
        совершенствуются в нашей компании <strong>уже 20 лет.</strong>
      </p>
      <p>
        Впоследствии появились радиолокационные видеофиксаторы, также
        работающие в движении ( 
        <strong>«Искра-Видео», «Бинар»</strong>
        ). Управлять этими приборами должен был инспектор: без
        человеческого участия работать они не могли. Инспектор
        останавливал нарушителя и предъявлял ему доказательства
        совершенного нарушения, поскольку распознавать номерные знаки
        и выявлять нарушителя в потоке эти приборы не умели. Именно
        этот факт и привел к тому, что в 2016 году использование таких
        измерителей было запрещено указанием министра МВД —
        требовалось исключить «человеческий фактор» и «коррупционную
        составляющую». Сейчас эта задача решена.
      </p>
      <p>
        <strong>
          Теперь «Кордон.Про» автоматически выполняет несколько задач:
        </strong>
      </p>
      <p>
        <strong>
          1. определяет с помощью доплеровского радиолокатора
          собственную скорость, а также измеряет скорости и координаты
          всех целей, которые попали в его поле зрения;
        </strong>
      </p>
      <p>
        <strong>
          2. оптическим методом распознает номерные знаки и определяет
          их координаты;
        </strong>
      </p>
      <p>
        <strong>
          3. безошибочно определяет, какому номеру принадлежит какая
          скорость;
        </strong>
      </p>
      <p>
        <strong>
          4. формирует фотоматериал и видеоролик по каждому нарушителю
          и сохраняет их в памяти;
        </strong>
      </p>
      <p>
        <strong>
          5. определяет категорию транспортного средства, чтобы знать,
          по какому порогу вычислять нарушения скорости — они же для
          разных автомобилей разные!
        </strong>
      </p>
      <p>
        <strong>
          6. немедленно отправляет фотоматериалы в ЦОД по каналу связи
          3G/4G/Ethernet;
        </strong>
      </p>
      <p>
        <strong>
          7. и главное — не допускает никакого участия человека в этом
          процессе, все происходит автоматически.
        </strong>
      </p>
      <p>
        <strong>
          Так что да, в целом Вы правы, практически ничего нового,
          обычный искусственный интеллект!.. 
        </strong>
      </p>`,
      },
      accordion3: {
        head: "Как будет обеспечиваться доказательная база правонарушения в условиях частой смены скоростного режима на контролируемом участке? Ведь не секрет, что точность определения GPS координат пока невысокая.",
        body: `<p>
        Для использования комплекса «Кордон.Про» заранее готовится электронная карта, в которой указаны координаты знаков ограничения скорости, границы населенных пунктов, названия улиц, номера домов.
      </p>
      <p>
      Карта загружается в память комплекса, и дальше он именно по ней определяет, является ли данный автомобиль нарушителем. Понятно, что внутри населенного пункта действует 
      ограничение скорости не более 70 км/ч., а в жилых зонах и прилегающих территориях (участок земли между домовыми постройками) не более 30 км/ч (п.78 ПДД РУз).
      Вне населенных пунктов разрешается движение (п.79 ПДД РУз):
      легковым автомобилям и грузовым автомобилям с разрешённой максимальной массой не более 3,5 тн — со скоростью не более 100 км/ч;
      междугородным автобусам и микроавтобусам — не более 90 км/ч;
      другим автобусам, легковым автомобилям при буксировке прицепа, мотоциклам, грузовым автомобилям с разрешённой максимальной массой более 3,5 тн — не более 80 км/ч;
      грузовым автомобилям при буксировке прицепа — не более 70 км/ч.</p>
      <p>
      При совершении правонарушений связанных со скоростным режимом, административное взыскание применяется с учетом допустимой общей погрешности показателей специальных устройств, измеряющих скорость, и спидометра транспортных средств с вычетом 5 километров в час от зафиксированной ими скорости (ст.1283 КоАО РУз) .
      </p>
      <p>
        Погрешность определения спутниковых координат 
        <strong>
          метрологически подтверждена, сертифицирована и составляет не
          более ±4,5 м
        </strong>
        . Однако в комплексе «Кордон.Про» для автоматического
        определения границ зоны ограничений поправка на неточность
        определения координат 
        <strong>увеличена втрое (!) и составляет ±15 м.</strong>
      </p>
      <p>
        Это сделано для того, чтобы не было случайных ошибок,
        связанных с неверным применением установленных ограничений.
        Географическое положение знака ограничения скорости и конец
        зоны действия этого знака всегда определяются с учетом этой
        поправки.
      </p>`,
      },
      accordion4: {
        head: "Каким образом обеспечивается погрешность измерения скорости 1 км/ч во время движения патрульного автомобиля?",
        body: `<p>
        <strong>Погрешность ±1 км/ч</strong>– сегодня далеко не
        предел. Так, метрологическое оборудование, на котором
        производилась проверка точности измерений комплекса
        «Кордон.Про», имеет погрешность ±0,3 км/ч.
      </p>
      <p>
        Современные высокопроизводительные процессоры в сочетании с
        детально проработанными алгоритмами математического анализа
        могут очень многое! Алгоритмы радиолокационных измерений,
        реализованных в комплексе, предусматривают частое и
        многократное повторение измерений, а также проверку их
        достоверности.
      </p>
      <p>
         
        Результаты подтверждены многочисленными экспериментами.
        Полученные характеристики подтверждены Свидетельством об
        утверждении типа средств измерений 
        <span>ОС.C.33.158.A №75141</span> от 19 сентября 2019 года.
      </p>`,
      },
    },
  },
  footer: {
    address: {
      city: "100164, г. Ташкент, Мирзо-Улугбекский р-н",
      street: "м. Ялангач, д. 119а",
    },
    titles: {
      title1: "ОТДЕЛ ПРОДАЖ",
      title2: "ТЕХПОДДЕРЖКА",
      title3: "СОЦСЕТИ",
    },
  },
  buttons: {
    prev: " Предыдущий",
    next: "Далее ",
  },
  speedChange: {
    title: "ИЗМЕРЕНИЕ СКОРОСТИ",
    text: {
      text1:
        "Комплексы семейства «КОРДОН» оснащены многоцелевым радаром, обеспечивающим целый ряд уникальных возможностей:",
      text2:
        "Отсутствие строгих требований к углу установки комплекса. Многоцелевой радар измеряет сразу все параметры движения цели (скорость, дистанция, азимут).",
      text3:
        "Одновременная фиксация всех целей в зоне контроля. С многоцелевым радаром исчезло требование о наличии только одного ТС на фотографии. Даже при наличии нескольких ТС в зоне контроля гарантируется точность привязки измеренных скоростей к зафиксированным автомобилям.",
      text4:
        "Фоторадары первого поколения не могли фиксировать больше 1—2 полос. Один комплекс семейства «КОРДОН» может контролировать всю дорогу, заменяя тем самым до 6 комплексов предыдущего поколения. Размеры зоны контроля — по углу от оси комплекса ±15°, по расстоянию от места установки комплекса от 10 до 80 м.",
      text5:
        "Комплекс обеспечивает возможность измерения средней скорости на участках и цепочках участков совместно с любым другим комплексом «Кордон.Про», «Кордон-М» или «Кордон-Темп». Минимальная дистанция для измерения средней скорости — всего 200 метров, при этом диапазон измеряемых скоростей составляет от 1 до 350 км/ч.",
      text6:
        "Измерение средней скорости движения производится косвенным методом: скорость определяется как отношение длины пройденного пути к затраченному на его прохождение времени. При необходимости контроля протяженных дорог допускается использование двух, трех и более комплексов в цепочке. Время прохождения участка определяется как разность времен фиксации на входе и выходе. Расстояние между точками установки комплексов измеряется поверенными измерителями длины (расстояния), при этом в момент фиксации учитываются и радарные координаты ТС с обоих комплексов.",
    },
  },
  workInMove: {
    title: "РАБОТА В ДВИЖЕНИИ",
    text: {
      text1:
        "Уникальной особенностью комплекса «Кордон.Про»М является возможность его установки в автомобиле для работы в движении.",
      text2:
        "Во время движения комплекс автоматически измеряет радиолокационным способом свою собственную скорость и скорости транспортных средств в зоне контроля. При этом одновременно распознаются их номерные знаки. Для корректного определения разрешенного порога скорости при работе в движении в память комплекса загружается предварительно подготовленная электронная карта с отмеченными координатами участков ограничения скорости. Редактирование карты производится администратором.",
      text3:
        "На основании метрологически достоверных данных комплекс автоматически выявляет нарушителей скоростного режима и мгновенно передает зафиксированные данные в ЦАФАП.",
    },
  },
  classifier: {
    title: "КЛАССИФИКАТОР",
    text: {
      text1:
        "Комплекс автоматически распознаёт категорию зафиксированного ТС (легковые, грузовые, автобусы). Классификация производится оптическим методом без обращения к каким-либо базам данных. Это позволяет автоматически фиксировать нарушения запрета движения грузовых ТС, а также автоматически назначать ограничение скорости по ПДД, соответствующее категории ТС.",
      text2:
        "Автоматическое корректное присвоение соответствующего типу транспортных средств (легковые, грузовые, автобусы) порога скорости по ПДД на основании данных от встроенного оптического классификатора ТС.",
    },
  },
  wanted: {
    title: "РОЗЫСК",
    text: {
      text1:
        "Автоматическое распознавание номерных знаков многих стран мира, включая ГРН по Постановлению Кабинета Министров Республики Узбекистан, от 27.06.2007 г. № 131, двустрочные и трёхстрочные номера. Невозможность автоматического распознавания ГРН, как правило, означает и невозможность его уверенного распознавания оператором.",
      text2:
        "Комплексы семейства «Кордон» успешно применяются на Дальнем Востоке для контроля движения транспортных средств Китая (распознавание иероглифов), а также в таких зарубежных странах, как Корея и Иран (арабская вязь).",
      text3:
        "В соответствии с требованиями п. 5.2 ГОСТ Р 57144-2016, измерительные комплексы семейства «КОРДОН» обеспечивают распознавание ГРЗ не менее 4 зарубежных стран по запросу и перечню Заказчика. В зависимости от условий договора с Заказчиком, на отгружаемых с завода комплексах может быть включено распознавание ГРЗ тех или иных иностранных государств. Также имеется возможность перенастройки системы распознавания на другие страны или увеличения количества стран для распознавания ГРЗ на коммерческой основе.",
      text4:
        "Технология нейронных сетей обеспечивает быстрое обучение новым форматам ГРЗ.",
      text5:
        "Комплекс обеспечивает получение информации о транспортных средствах, найденных по загруженным в память прибора базам розыска, а также оперативную запись видеороликов на карту памяти SD. При подключении к комплексу оператор может воспользоваться функцией озвучивания номерных знаков, найденных по базам.",
      text6:
        "Все комплексы семейства «КОРДОН» имеют специальный режим работы «Перехват», предназначенный для розыска и фиксации тех ТС, номерные знаки которых не могут быть распознаны, либо отсутствуют. По отзывам пользователей нашего оборудования, данная возможность незаменима при проведении оперативно-розыскных мероприятий.",
      text7:
        "Протокол передачи данных TCP/XML дает возможность интеграции комплексов с централизованными системами розыска ТС.",
    },
  },
  settings: {
    title: "НАСТРОЙКА И МОНТАЖ",
    text: {
      text1:
        "Комплекс «Кордон.Про» может быть смонтирован на стационарной опоре аналогично комплексу «Кордон-М» — с помощью поворотного кронштейна, имеющего возможность регулировки положения во всех плоскостях. Кронштейн может быть закреплен на опоре уголками или с помощью монтажной ленты.",
      text2:
        "Настройка выполняется через Web-интерфейс удаленно, либо на месте установки с помощью ноутбука или планшета. Существенно сократилось время, необходимое для начальной настройки. В интерфейсе используются интерактивные индикаторы, которые отображают качество настройки и дают рекомендации по коррекции положения датчика. Более того, комплекс начинает фотовидеофиксацию нарушений ПДД только в том случае, если он правильно сориентирован и прошел самодиагностику.",
      text3:
        "Возможны различные варианты подключения к сетям электропитания, в том числе подключение к осветительной сети и автономная работа от АКБ.",
    },
  },
  rcar: {
    title: "Р-АВТО",
    text: "Комплект крепления на багажные рейлинги автомобиля обеспечивает возможность оперативной смены дислокации комплекса при использовании в передвижном режиме. Таким образом, комплекс может быть оперативно развёрнут на выбранном участке дороги, в том числе в условиях, не допускающих применения стандартного штатива. Кронштейн устанавливается на любом автомобиле, имеющем багажные рейлинги, и обеспечивает удобную регулировку наклона и угла поворота датчика.",
  },
  protection: {
    title: "БРОНЕЗАЩИТА",
    text: "Кожух из броневой стали специально разработан для комплекса «Кордон.Про»М. Обеспечивает защиту от огнестрельного оружия, сертифицирован на пулестойкость по классам «Бр2» (пистолеты СПС и ТТ) и «С1» (охотничье ружье со свинцовой пулей). Кожух может быть выполнен в двух исполнениях: с бронированным стеклом и без стекла. Бронезащита крепится на комплексы, уже установленные на опоре с помощью стандартного кронштейна. Для монтажа требуется два человека.",
  },
  base: {
    title: "ДОКАЗАТЕЛЬНАЯ БАЗА",
    text: "Комплекс автоматически фиксирует нарушения ПДД в зоне контроля и формирует необходимую доказательную базу для каждой цели: обзорные фотографии, фотографии с визуально различимым номерным знаком, видеозаписи каждого нарушения и т.д. Одновременно ведется непрерывная видеозапись событий в зоне контроля комплекса. Фотовидеофиксация ТС в ночное время производится с помощью встроенной инфракрасной подсветки, качество изображения позволяет определить в том числе и марку автомобиля.",
  },
  copyright: {
    copyright: "Авторские права принадлежат",
    rights: "Все права защищены",
  },
  cross: {
    link: {
      link1: "КОРДОН ПРО М",
      link2: "КОРДОН КРОСС",
    },
    title: {
      top: "АВТОМАТИЧЕСКИЙ КОНТРОЛЬ ПЕРЕКРЕСТКОВ",
      bot: "КОРДОН-КРОСС",
    },
    subtitle: "Комплекс измерительный с видеофиксацией «КОРДОН-КРОСС»",
    cordon: {
      subtitle:
        "Стационарный комплекс «КОРДОН-КРОСС» предназначен для контроля и фотовидеофиксации нарушений правил дорожного движения (ПДД) на перекрестках с передачей данных в центр обработки данных (ЦОД). Обеспечивает автоматическое распознавание номеров и проверку всех ТС по различным базам данных.",
    },
    slider: {
      slider1: {
        title: "РАБОТА В НОЧНОЕ ВРЕМЯ",
        subtitle: "Автоматическое переключение в ночной режим сьемки",
      },
      slider2: {
        title: "Детектирование сигналов светофора",
        subtitle:
          "Распознавание по видеокадрам и/или при помощи проводного подключения к дорожному контроллеру.",
      },
      slider3: {
        title: "(при наличии радара) Измерение скорости до 300 км/ч",
        subtitle:
          "Измерение скоростей в диапазоне от 2 до 300 км/ч (при наличии радара).",
      },
    },
    fixation: {
      title: "ФИКСИРУЕМЫЕ НАРУШЕНИЯ ПДД",
      subtitle:
        "Перечень фиксируемых нарушений ПДД зависит от комплектации комплекса и схемы размещения камер на перекрестке.",
      items: {
        item1: "Проезд на запрещающий сигнал светофора",
        item2:
          "Невыполнение требования об остановке перед стоп-линией на запрещающий сигнал светофора",
        item3: "Движение по разделительной полосе",
        item4: "Выезд на полосу встречного движения",
        item5: "Движение транспортных средств по обочине",
        item6: "Движение по велосипедным, пешеходным дорожкам или тротуарам",
        item7:
          "Нарушение запрета движения грузовых транспортных средств (в том числе по отдельным полосам)",
        item8:
          "Нарушение минимальной дистанции между транспортными средствами при движении",
        item9: "Движение с не пристёгнутым ремнем водителя или пассажира",
        item10: "Превышение установленной скорости движения",
        item11:
          "Движение во встречном направлении по дороге с односторонним движением",
        item12: "Нарушение правил поворота или разворота",
        item13: "Движение по полосе для маршрутных транспортных средств",
        item14:
          "Перестроение транспортных средств через сплошную линию разметки",
        item15:
          "Нарушение требований, предписанных дорожными знаками или разметкой",
        item16:
          "Остановка на вафельной разметке и выезд на перекресток при заторе",
        item17: "Превышение средней скорости на протяженном участке дороги *",
        item18:
          "Движение без включенных фар (ближнего света / дневных ходовых огней)",
      },
    },
    compound: {
      title: "СОСТАВ КОМПЛЕКСА",
      subtitle:
        "Комплекс состоит из одного или нескольких видеодатчиков (распознающих камер) с ИК-прожекторами, обзорных камер и блока обработки информации (БОИ «Кросс»).",
      text: {
        text1:
          "За счет своей модульности комплекс обладает уникальной масштабируемостью. Для контроля скоростного режима один или несколько видеодатчиков комплекса могут быть оборудованы радарными модулями.",
        text2: `<p>По запросу видеодатчики могут быть дополнительно оснащены:
        — автоматическими системами очистки стекол;
        — моторизованными наклонно-поворотными устройствами с дистанционным управлением.</p>`,
        text3:
          "Все камеры имеют оптические интерфейсы и подключаются к БОИ с помощью волоконно-оптических линий связи (ВОЛС).",
      },
    },
    scheme: {
      title: "СХЕМА РАЗМЕЩЕНИЯ И ПОРЯДОК РАБОТЫ",
      subtitle:
        "Видеодатчики и обзорные камеры монтируются на высоте нескольких метров на стационарных конструкциях (мачта освещения, опора фермы и т. д.) и подключаются к сети 220 В.",
      text: {
        text1: "Зоны контроля и пример размещения оборудования на перекрестке",
        text2: "Обозначения: ВД - Видеодатчик, ОК - Обзорная камера",
        text3:
          "Для полного контроля типичного перекрестка двух дорог с двухсторонним движением и двумя полосами в каждом направлении потребуется 4 видеодатчика, 4 обзорных камеры и один блок обработки информации. Конкретное количество и состав оборудования уточняется в каждом проекте в зависимости от конфигурации перекрестка.",
        text4:
          "Установка всех требуемых параметров работы комплекса выполняется удаленно через встроенный Web-интерфейс с помощью компьютера или планшета. Определение сигнала светофора (включая дополнительные секции) производится автоматически по изображению с обзорной камеры, либо комбинированным способом с подключением к контроллеру светофора — это обеспечивает непревзойденную степень достоверности и защиты от ошибок.",
        text5:
          "Блок обработки информации «Кросс» производит распознавание государственный регистрационный номер (ГРН) по данным с видеодатчиков, анализ движения в кадре и автоматическое распознавание светофорных сигналов. В процессе обработки информации комплекс формирует коллажи фотографий нарушителя, которые могут включать в себя общее фото перекрестка и увеличенное фото нарушителя, и/или фотографии дорожной обстановки, сделанные с заданным интервалом. Данные с распознанными ГРН и сопутствующей информацией передаются с использованием защищенных каналов связи на сервер Центра обработки данных для обработки нарушений.",
        text6:
          "В процессе работы комплекс фиксирует все транспортные средства, проехавшие через перекресток. Предусмотрена возможность фиксации транспортных средств с нераспознанными или отсутствующими ГРН для целей розыска. ",
      },
    },
    functions: {
      title: "КАКИЕ ОСНОВНЫЕ ФУНКЦИИ",
      items: {
        item1: {
          title: "АВТОМАТИЧЕСКАЯ ФОТОВИДЕОФИКСАЦИЯ",
          info: `<ul>
          <li>
          Автоматическое определение положения всех транспортных средств на перекрестке по всем полосам дорожного движения и фотовидеофиксация нарушений ПДД.
          </li>
          <li>
          Контроль одной камерой до шести полос движения.
          </li>
          <li>
          Детектирование сигналов светофора – распознавание по видеокадрам и/или при помощи проводного подключения к дорожному контроллеру.
          </li>
          <li>
          Измерение скоростей в диапазоне от 2 до 300 км/ч (при наличии радара).
          </li>
          <li>
          Автоматическое сохранение фотоматериалов и видеоролика по каждому зафиксированному нарушению.
          Модуль ГЛОНАСС/GPS с автоматической коррекцией системного времени комплекса.
          </li>
        </ul>`,
        },
        item2: {
          title: "РАСПОЗНАВАНИЕ НОМЕРНЫХ ЗНАКОВ И РОЗЫСК транспортных средств",
          info: `<ul>
            <li>
            Автоматическое распознавание ГРН на основе технологии нейронных сетей, обеспечивающей быстрое обучение новым форматам и распознавание номерных знаков многих стран мира, включая двустрочные номера.
            </li>
            <li>
            Возможность включения и выключения распознавания ГРН тех или иных государств.
            </li>
            <li>
            Проверка распознанных номеров по базам данных с оповещением оператора и голосовым озвучиванием номера.
            </li>
            <li>
            Специальный режим работы «Перехват» для розыска транспортных средств с отсутствующими или нечитаемыми номерными знаками.
            </li>
          </ul>`,
        },
        item3: {
          title: "КЛАССИФИКАЦИЯ транспортных средств",
          info: `<ul>
            <li>
            Автоматическое определение типа транспортных средств и их классификация по четырём основным категориям (легковые, грузовые, автобусы, среднегабаритные).
            </li>
            <li>
            Автоматическое распознавание марок и моделей транспортных средств (по запросу).
            </li>
            <li>
            Автоматическое присвоение порога скорости по ПДД, соответствующего категории транспортных средств.
            </li>
            <li>
            Автоматический контроль запрета движения транспортных средств для заданной категории (грузовые, автобусы и т.д.) по отдельным полосам или по дороге в целом.
            </li>
          </ul>`,
        },
        item4: {
          title: "ВИДЕОНАБЛЮДЕНИЕ",
          info: `<ul>
            <li>
            Трансляция видеопотока с высоким разрешением по протоколу RTSP.
            </li>
            <li>
            Ведение непрерывной видеозаписи с возможностью скачивания видеоролика по заданному промежутку времени.
            </li>
          </ul>`,
        },
        item5: {
          title: " ПЕРЕДАЧА ДАННЫХ",
          info: `<ul>
            <li>
            Передача данных на сервер центра обработки данных по зашифрованным проводным или беспроводным каналам связи (3G/4G).
            </li>
            <li>
            Автоматическое переключение на резервные каналы связи (Wi-Fi, 4G) при сбоях или отказе основного канала.
            Полная совместимость с системами МВД Республики Узбекистан.
            </li>
            <li>
            Возможность параллельной передачи данных с комплекса на различные серверы.
            </li>
          </ul>`,
        },
        item6: {
          title: "ЗАЩИТА И БЕЗОПАСНОСТЬ",
          info: `<ul>
            <li>
            Защита данных и встроенного программного обеспечения от несанкционированных изменений.
            </li>
            <li>
            Экспортируемые данные защищены электронно-цифровой подписью.
            </li>
            <li>
            Ведение журнала событий и действий пользователя комплекса.
            </li>
          </ul>`,
        },
        item7: {
          title: "РАБОТА В НОЧНОЕ ВРЕМЯ",
          info: `<ul>
            <li>
            Инфракрасная подсветка для работы в ночное время.
            </li>
            <li>
            Возможность установки дополнительных внешних ИК-прожекторов.
          </li>
          </ul>`,
        },
        item8: {
          title: "СТАТИСТИКА",
          info: `<ul>
            <li>
            Сбор статистических данных об интенсивности транспортного потока на перекрестке.
            </li>
            <li>
            Анализ зафиксированных нарушений ПДД с разбивкой по видам нарушений ПДД, категориям транспортных средств на всём перекрестке, либо только по выбранным камерам.
          </li>
          </ul>`,
        },
      },
    },
    guarantee: {
      bot1: "Сертификат соответствия Евразийского экономического союза № ЕАЭС RU C-RU.ME83.B.00005/19. Действителен до 28 08.2024 года.",
      bot2: "Свидетельство об аттестации методики измерения средней скорости №1084/253-(RA.RU.310494)-2019.",
    },
  },
};
