import React, { useEffect, useState } from "react";
import cordon from "src/assets/images/cordon.png";

import arrow from "src/assets/images/arrow.svg";

// slider
import cordonSlider1 from "src/assets/images/cordon_slider1.jpeg";
import cordonSlider2 from "src/assets/images/cordon_slider2.jpeg";
import cordonSlider3 from "src/assets/images/cordon_slider3.jpeg";

// road signs
import one from "src/assets/images/1.png";
import two from "src/assets/images/2.png";
import three from "src/assets/images/3.png";
import four from "src/assets/images/4.png";
import five from "src/assets/images/5.png";
import six from "src/assets/images/6.png";
import seven from "src/assets/images/7.png";
import eight from "src/assets/images/8.png";
import nine from "src/assets/images/9.png";
import ten from "src/assets/images/10.png";
import eleven from "src/assets/images/11.png";
import twelve from "src/assets/images/12.png";

// how it works
import work11 from "src/assets/images/11.jpeg";
import work12 from "src/assets/images/12.jpeg";
import work13 from "src/assets/images/13.jpeg";
import work14 from "src/assets/images/14.jpeg";
import work15 from "src/assets/images/15.jpeg";
import work16 from "src/assets/images/16.jpeg";
import work17 from "src/assets/images/17.jpeg";
import work18 from "src/assets/images/18.jpeg";

// instalation
import instal1 from "src/assets/images/111.jpeg";
import instal2 from "src/assets/images/112.jpeg";
import instal3 from "src/assets/images/113.jpeg";
import instal4 from "src/assets/images/114.png";
import instal5 from "src/assets/images/115.png";
import instal6 from "src/assets/images/116.png";
import instal7 from "src/assets/images/117.png";
import instal8 from "src/assets/images/118.png";
import instal9 from "src/assets/images/119.png";
import instal10 from "src/assets/images/120.png";
import instal11 from "src/assets/images/121.png";

// functions
import func1 from "src/assets/images/1111.png";
import func2 from "src/assets/images/1112.png";
import func3 from "src/assets/images/1113.png";
import func4 from "src/assets/images/1114.png";
import func5 from "src/assets/images/1115.png";
import func6 from "src/assets/images/1116.png";

// guarantee
import guarantee1 from "src/assets/images/1.1.png";
import guarantee2 from "src/assets/images/1.2.png";
import guarantee3 from "src/assets/images/1.3.png";

import Slider from "react-slick";
import Footer from "../Footer";
// import HeaderMain from "../Headermain";
import plus from "src/assets/images/plus.svg";

import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../Header";

import video from "src/traffic_univer.mp4";

import { useTranslation } from "react-i18next";
import { scrollToSection } from "src/component/scroll";

const Main = () => {
  const [activeAccordion, setActiveAccordion] = useState(0);
  const [activeSpecification, setActiveSpecification] = useState(0);
  const [activeInstallationTab, setActiveInstallationTab] = useState(0);
  const [scroll, setScroll] = useState(false);
  const { t } = useTranslation();

  const accordionInfo = [
    {
      id: 1,
      head: t("faq.accordion.accordion1.head"),
      body: t("faq.accordion.accordion1.body"),
    },
    {
      id: 2,
      head: t("faq.accordion.accordion2.head"),
      body: t("faq.accordion.accordion2.body"),
    },
    {
      id: 3,
      head: t("faq.accordion.accordion3.head"),
      body: t("faq.accordion.accordion3.body"),
    },
    {
      id: 4,
      head: t("faq.accordion.accordion4.head"),
      body: t("faq.accordion.accordion4.body"),
    },
  ];

  const specificationsTab = [
    {
      id: 1,
      tab: `${t("specifications.tabs.tab1.title")}`,
      tabPane: `${t("specifications.tabs.tab1.text")}`,
    },
    {
      id: 2,
      tab: `${t("specifications.tabs.tab2.title")}`,
      tabPane: `${t("specifications.tabs.tab2.text")}`,
    },
    {
      id: 3,
      tab: `${t("specifications.tabs.tab3.title")}`,
      tabPane: `${t("specifications.tabs.tab3.text")}`,
    },
    {
      id: 4,
      tab: `${t("specifications.tabs.tab4.title")}`,
      tabPane: `<ul>
      <li>
        <img src=${arrow} alt="" className="list-arrow" />
        ${t("specifications.tabs.tab4.lists.list1")}
      </li>
      <li>
        <img src=${arrow} alt="" className="list-arrow" />
        ${t("specifications.tabs.tab4.lists.list2")}
      </li>
      <li>
        <img src=${arrow} alt="" className="list-arrow" />
        ${t("specifications.tabs.tab4.lists.list3")}
      </li>
      <li>
        <img src=${arrow} alt="" className="list-arrow" />
        ${t("specifications.tabs.tab4.lists.list4")}
      </li>
    </ul>`,
    },
  ];

  const installationTab = [
    {
      id: 1,
      tab: t("installation.tabs.tab1"),
      tabPane: `<div class="installation__tabPane">
      <div class="installation__tabPane-img">
        <img src=${instal1} alt="" />
      </div>
      <div class="installation__tabPane-info">
        <div class="installation__tabPane-item">
          <div class="installation__tabPane-logos">
            <div class="installation__tabPane-logo">
              <img src=${instal4} alt="" />
            </div>
            <div class="installation__tabPane-logo">
              <img src=${instal5} alt="" />
            </div>
            <div class="installation__tabPane-subtitle">
              ${t("installation.tabpanes.tabpane1.paragraph1.title")}
            </div>
          </div>
          <div class="installation__tabPane-text">
              ${t("installation.tabpanes.tabpane1.paragraph1.text")}
          </div>
        </div>
        <div class="installation__tabPane-item">
          <div class="installation__tabPane-logos">
            <div class="installation__tabPane-logo">
              <img src=${instal4} alt="" />
            </div>
            <div class="installation__tabPane-logo">
              <img src=${instal6} alt="" />
            </div>
            <div class="installation__tabPane-subtitle">
            ${t("installation.tabpanes.tabpane1.paragraph2.title")}
            </div>
          </div>
          <div class="installation__tabPane-text">
            ${t("installation.tabpanes.tabpane1.paragraph2.text")}
          </div>
        </div>
      </div>
    </div>`,
    },
    {
      id: 2,
      tab: t("installation.tabs.tab2"),
      tabPane: `<div class="installation__tabPane">
      <div class="installation__tabPane-img">
        <img src=${instal2} alt="" />
      </div>
      <div class="installation__tabPane-info">
        <div class="installation__tabPane-item">
          <div class="installation__tabPane-logos">
            <div class="installation__tabPane-logo">
              <img src=${instal7} alt="" />
            </div>
            <div class="installation__tabPane-logo">
              <img src=${instal8} alt="" />
            </div>
            <div class="installation__tabPane-subtitle">
            ${t("installation.tabpanes.tabpane2.paragraph1.title")}
            </div>
          </div>
          <div class="installation__tabPane-text">
            ${t("installation.tabpanes.tabpane2.paragraph1.text")}
          </div>
        </div>
        <div class="installation__tabPane-item">
          <div class="installation__tabPane-logos">
            <div class="installation__tabPane-logo">
              <img src=${instal9} alt="" />
            </div>
            <div class="installation__tabPane-logo">
              <img src=${instal8} alt="" />
            </div>
            <div class="installation__tabPane-subtitle">
            ${t("installation.tabpanes.tabpane2.paragraph2.title")}
            </div>
          </div>
          <div class="installation__tabPane-text">
            ${t("installation.tabpanes.tabpane2.paragraph2.text")}
          </div>
        </div>
        <div class="installation__tabPane-item">
          <div class="installation__tabPane-logos">
            <div class="installation__tabPane-logo">
              <img src=${instal10} alt="" />
            </div>
            <div class="installation__tabPane-logo">
              <img src=${instal11} alt="" />
            </div>
            <div class="installation__tabPane-subtitle">
            ${t("installation.tabpanes.tabpane2.paragraph3.title")}
            </div>
          </div>
          <div class="installation__tabPane-text">
            ${t("installation.tabpanes.tabpane2.paragraph3.text")}
          </div>
        </div>
      </div>
    </div>`,
    },
    {
      id: 3,
      tab: t("installation.tabs.tab3"),
      tabPane: `<div class="installation__tabPane">
      <div class="installation__tabPane-img">
        <img src=${instal3} alt="" />
      </div>
      <div class="installation__tabPane-info">
        <div class="installation__tabPane-item">
          <div class="installation__tabPane-logos">
            <div class="installation__tabPane-logo">
              <img src=${instal9} alt="" />
            </div>
            <div class="installation__tabPane-logo">
              <img src=${instal8} alt="" />
            </div>
            <div class="installation__tabPane-subtitle">
            ${t("installation.tabpanes.tabpane3.paragraph1.title")}
            </div>
          </div>
            ${t("installation.tabpanes.tabpane3.paragraph1.text")}
        </div>
      </div>
    </div>`,
    },
  ];

  const openTab = (e: any) => {
    setActiveAccordion(e.target.dataset.index);
  };

  const openTabSpecification = (e: any) => {
    setActiveSpecification(e.target.dataset.index);
  };

  const openTabInstallationTab = (e: any) => {
    setActiveInstallationTab(e.target.dataset.index);
  };

  const baseUrl = "images/slider/cordon_slider";
  const settings = {
    customPaging: function (i: any) {
      return <img src={`${baseUrl}${i + 1}.jpeg`} alt="" />;
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`next__arr ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span> &gt;</span>
      </button>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`prev__arr ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span>&lt;</span>
      </button>
    );
  }

  const location = useLocation<{ to?: string | undefined }>();
  const history = useHistory();

  useEffect(() => {
    const scrollEvent = () => {
      setScroll(window.scrollY > 50);
    };
    window.addEventListener("scroll", scrollEvent);
    return () => window.removeEventListener("scroll", scrollEvent);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (location.state && location.state.to) {
        scrollToSection(location.state.to);
      }
    }, 100);
  }, [location, history]);

  return (
    <div className="main" id="main">
      <Header className={scroll ? "scrolled" : ""} />
      <div className="video">
        <div className="video__inner center-block">
          <video
            id="video__background"
            controls={false}
            autoPlay
            loop
            muted
            className="video__background-content"
            playsInline
          >
            <source src={video} type="video/mp4" />
          </video>
          <div className="video__content">
            <div className="video__content-btns">
              <Link
                to="/"
                className={`video__content-btn ${
                  history.location.pathname === "/" ? "active" : ""
                }`}
              >
                {t("cross.link.link1")}
              </Link>
              <Link
                to="/CordonCross"
                className={`video__content-btn ${
                  history.location.pathname === "/CordonCross" ? "active" : ""
                }`}
              >
                {t("cross.link.link2")}
              </Link>
            </div>
            <div className="video__title">
              <strong>{t("video.title.top")}</strong>
              <span>{t("video.title.bottom")}</span>
            </div>
            <div className="video__subtitle">{t("video.subtitle")}</div>
            <Link to="/WorkInMove" className="video__button">
              {t("video.button")}
            </Link>
          </div>
        </div>
      </div>
      <div className="cordon" id="cordon">
        <div className="cordon__content center-block">
          <div className="cordon__img">
            <img src={cordon} alt="" />
          </div>
          <div className="cordon__data">
            <div className="cordon__title">{t("cordon.title")}</div>
            <div className="cordon__subtitle">{t("cordon.subtitle")}</div>
            <p className="cordon__info">{t("cordon.info")}</p>
            <div className="cordon__qualities">
              <div className="cordon__qualities-title">
                {t("qualities.title")}
              </div>
              <div className="cordon__qualities-list">
                <ul>
                  <li>
                    <img src={arrow} alt="" className="list-arrow" />
                    {t("qualities.list.speed")}
                  </li>
                  <li>
                    <img src={arrow} alt="" className="list-arrow" />
                    {t("qualities.list.install")}
                  </li>
                  <li>
                    <img src={arrow} alt="" className="list-arrow" />
                    {t("qualities.list.switch")}
                  </li>
                  <li>
                    <img src={arrow} alt="" className="list-arrow" />
                    {t("qualities.list.recognition")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Slider {...settings} className="cordon__slider">
          <div className="cordon__slider-item">
            {/* <div className="cordon__slider-img"> */}
            <img src={cordonSlider1} alt="" />
            {/* </div> */}
            <div className="cordon__slider-text">
              <div className="cordon__slider-title">
                {t("slider.slide1.title")}
              </div>
              <p className="cordon__slider-subtitle">
                {t("slider.slide1.subtitle")}
              </p>
            </div>
          </div>
          <div className="cordon__slider-item">
            {/* <div className="cordon__slider-img"> */}
            <img src={cordonSlider2} alt="" />
            {/* </div> */}
            <div className="cordon__slider-text">
              <div className="cordon__slider-title">
                {t("slider.slide2.title")}
              </div>
              <p className="cordon__slider-subtitle">
                {t("slider.slide2.subtitle")}
              </p>
            </div>
          </div>
          <div className="cordon__slider-item">
            {/* <div className="cordon__slider-img"> */}
            <img src={cordonSlider3} alt="" />
            {/* </div> */}
            <div className="cordon__slider-text">
              <div className="cordon__slider-title">
                {t("slider.slide3.title")}
              </div>
              <p className="cordon__slider-subtitle">
                {t("slider.slide3.subtitle")}
              </p>
            </div>
          </div>
        </Slider>
      </div>
      <div className="fixation" id="fixation">
        <div className="fixation__inner center-block">
          <div className="fixation__title">{t("fixation.title")} </div>
          <p className="fixation__subtitle">{t("fixation.subtitle")}</p>
          <div className="fixation__items">
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={one} alt="" />
              </div>
              <p className="fixation__item-text">{t("fixation.items.item1")}</p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={two} alt="" />
              </div>
              <p className="fixation__item-text">{t("fixation.items.item2")}</p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={three} alt="" />
              </div>
              <p className="fixation__item-text">{t("fixation.items.item3")}</p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={four} alt="" />
              </div>
              <p className="fixation__item-text">{t("fixation.items.item4")}</p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={five} alt="" />
              </div>
              <p className="fixation__item-text">{t("fixation.items.item5")}</p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={six} alt="" />
              </div>
              <p className="fixation__item-text">{t("fixation.items.item6")}</p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={seven} alt="" />
              </div>
              <p className="fixation__item-text">{t("fixation.items.item7")}</p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={eight} alt="" />
              </div>
              <p className="fixation__item-text">{t("fixation.items.item8")}</p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={nine} alt="" />
              </div>
              <p className="fixation__item-text">{t("fixation.items.item9")}</p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={ten} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("fixation.items.item10")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={eleven} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("fixation.items.item11")}
              </p>
            </div>
            <div className="fixation__item">
              <div className="fixation__item-img">
                <img src={twelve} alt="" />
              </div>
              <p className="fixation__item-text">
                {t("fixation.items.item12")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="work" id="work">
        <div className="work__inner center-block">
          <div className="work__title">{t("work.title")} </div>
          <p className="work__subtitle">{t("work.subtitle")}</p>
          <div className="work__items">
            <div className="work__item">
              <Link to="/SpeedChange">
                <img src={arrow} className="list-arrow" alt="" />
                {t("work.links.link1")}
              </Link>
              <Link to="/Settings">
                <img src={arrow} className="list-arrow" alt="" />
                {t("work.links.link2")}
              </Link>
            </div>
            <div className="work__item">
              <Link to="/WorkInMove">
                <img src={arrow} className="list-arrow" alt="" />
                {t("work.links.link3")}
              </Link>
              <Link to="/RCar">
                <img src={arrow} className="list-arrow" alt="" />
                {t("work.links.link4")}
              </Link>
            </div>
            <div className="work__item">
              <Link to="/Classifier">
                <img src={arrow} className="list-arrow" alt="" />
                {t("work.links.link5")}
              </Link>
              <Link to="/Protection">
                <img src={arrow} className="list-arrow" alt="" />
                {t("work.links.link6")}
              </Link>
            </div>
            <div className="work__item">
              <Link to="/Wanted">
                <img src={arrow} className="list-arrow" alt="" />
                {t("work.links.link7")}
              </Link>
              <Link to="/Base">
                <img src={arrow} className="list-arrow" alt="" />
                {t("work.links.link8")}
              </Link>
            </div>
          </div>
        </div>
        <div className="work__articles">
          <Link to="/SpeedChange" className="work__article">
            <img src={work11} alt="" />
            <div className="work__article-link">
              <span className="work__article-link-top">
                {t("work.links.link1")}
              </span>
              <span className="work__article-link-bot">
                {t("work.previews.preview")}
              </span>
            </div>
          </Link>
          <Link to="/WorkInMove" className="work__article">
            <img src={work12} alt="" />
            <div className="work__article-link">
              <span className="work__article-link-top">
                {t("work.previews.preview1.top")}
              </span>
              <span className="work__article-link-bot">
                {t("work.previews.preview1.bot")}
              </span>
            </div>
          </Link>
          <Link to="/Classifier" className="work__article">
            <img src={work13} alt="" />
            <div className="work__article-link">
              <span className="work__article-link-top">
                {t("work.previews.preview2.top")}
              </span>
              <span className="work__article-link-bot">
                {t("work.previews.preview2.bot")}
              </span>
            </div>
          </Link>
          <Link to="/Wanted" className="work__article">
            <img src={work14} alt="" />
            <div className="work__article-link">
              <span className="work__article-link-top">
                {t("work.previews.preview3.top")}
              </span>
              <span className="work__article-link-bot">
                {t("work.previews.preview3.bot")}
              </span>
            </div>
          </Link>
          <Link to="/Settings" className="work__article">
            <img src={work15} alt="" />
            <div className="work__article-link">
              <span className="work__article-link-top">
                {t("work.previews.preview4.top")}
              </span>
              <span className="work__article-link-bot">
                {t("work.previews.preview4.bot")}
              </span>
            </div>
          </Link>
          <Link to="/RCar" className="work__article">
            <img src={work16} alt="" />
            <div className="work__article-link">
              <span className="work__article-link-top">
                {t("work.previews.preview5.top")}
              </span>
              <span className="work__article-link-bot">
                {t("work.previews.preview5.bot")}
              </span>
            </div>
          </Link>
          <Link to="/Protection" className="work__article">
            <img src={work17} alt="" />
            <div className="work__article-link">
              <span className="work__article-link-top">
                {t("work.previews.preview6.top")}
              </span>
              <span className="work__article-link-bot">
                {t("work.previews.preview6.bot")}
              </span>
            </div>
          </Link>
          <Link to="/Base" className="work__article">
            <img src={work18} alt="" />
            <div className="work__article-link">
              <span className="work__article-link-top">
                {t("work.previews.preview7.top")}
              </span>
              <span className="work__article-link-bot">
                {t("work.previews.preview7.bot")}
              </span>
            </div>
          </Link>
        </div>
      </div>
      <div className="installation center-block" id="installation">
        <div className="installation__title">{t("installation.title")} </div>
        <p className="installation__subtitle">{t("installation.subtitle")}</p>
        <p className="installation__text">{t("installation.text")}</p>
        <div className="installation__content">
          <div className="installation__tabs">
            {installationTab.map((n, i) => (
              <button
                key={n.id}
                className={`installation__tab ${
                  i === Number(activeInstallationTab) ? "active" : ""
                }`}
                onClick={openTabInstallationTab}
                data-index={i}
              >
                {n.tab}
              </button>
            ))}
          </div>
          <div className="installation__tabPane-wrapper">
            {installationTab.map((n, i) => (
              <div
                key={n.id}
                data-index={i}
                dangerouslySetInnerHTML={{ __html: n.tabPane }}
                className={`installation__tabPane-data ${
                  i === Number(activeInstallationTab) ? "active" : ""
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
      <div className="functions" id="functions">
        <div className="functions__inner center-block">
          <div className="functions__title">{t("functions.title")} </div>
          <div className="functions__items">
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func1} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("functions.items.item1.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("functions.items.item1.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func2} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("functions.items.item2.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("functions.items.item2.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func3} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("functions.items.item3.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("functions.items.item3.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func4} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("functions.items.item4.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("functions.items.item4.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func5} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("functions.items.item5.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("functions.items.item5.info"),
                  }}
                ></div>
              </div>
            </div>
            <div className="functions__item">
              <div className="functions__item-img">
                <img src={func6} alt="" />
              </div>
              <div className="functions__item-content">
                <div className="functions__item-title">
                  {t("functions.items.item6.title")}
                </div>
                <div
                  className="functions__item-list"
                  dangerouslySetInnerHTML={{
                    __html: t("functions.items.item6.info"),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="specifications center-block" id="specifications">
        <div className="specifications__title">{t("specifications.title")}</div>
        <div className="specifications__tabs">
          <div className="specifications__tabs-left">
            {specificationsTab.map((n, i) => (
              <div
                key={n.id}
                className={`specifications__tabs-sidebar ${
                  i === Number(activeSpecification) ? "active" : ""
                }`}
              >
                <button
                  className="specifications__tab"
                  onClick={openTabSpecification}
                  data-index={i}
                >
                  {n.tab}
                </button>
              </div>
            ))}
          </div>
          <div className="specifications__tabs-right">
            {specificationsTab.map((n, i) => (
              <div
                key={n.id}
                className={`specifications__tabs-content ${
                  i === Number(activeSpecification) ? "active" : ""
                }`}
              >
                <div
                  className="specifications__tabs-item"
                  data-index={i}
                  dangerouslySetInnerHTML={{ __html: n.tabPane }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="statistics">
        <div className="statistics__inner center-block">
          <div className="statistics__item">
            <div className="statistics__item-title">
              {t("statistics.items.item1.title")}
            </div>
            <div className="statistics__item-progressbar statistics__item-progressbar-first">
              <span>
                {t("statistics.items.item1.progressbar.progressbar1")}
              </span>
            </div>
            <div className="statistics__item-progressbar statistics__item-progressbar-second">
              <span>
                {t("statistics.items.item1.progressbar.progressbar2")}
              </span>
            </div>
          </div>
          <div className="statistics__item">
            <div className="statistics__item-title">
              {t("statistics.items.item2.title")}
            </div>
            <div className="statistics__item-progressbar statistics__item-progressbar-third">
              <span>
                {t("statistics.items.item2.progressbar.progressbar1")}
              </span>
            </div>
            <div className="statistics__item-progressbar statistics__item-progressbar-fourth">
              <span>
                {t("statistics.items.item2.progressbar.progressbar2")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="guarantee center-block">
        <div className="guarantee__title">{t("guarantee.title")} </div>
        <div className="guarantee__top">
          <div className="guarantee__top-item">
            <div className="guarantee__top-img">
              <img src={guarantee1} alt="" />
            </div>
            <div
              className="guarantee__top-text"
              dangerouslySetInnerHTML={{ __html: t("guarantee.top.item1") }}
            ></div>
          </div>
          <div className="guarantee__top-item">
            <div className="guarantee__top-img">
              <img src={guarantee2} alt="" />
            </div>
            <div
              className="guarantee__top-text"
              dangerouslySetInnerHTML={{ __html: t("guarantee.top.item2") }}
            ></div>
          </div>
        </div>
        <div className="guarantee__bot">
          <div className="guarantee__bot-img">
            <img src={guarantee3} alt="" />
          </div>
          <div className="guarantee__bot-group">
            <div className="guarantee__bot-text">
              {t("guarantee.bot.item1")}
            </div>
            <div className="guarantee__bot-text">
              {t("guarantee.bot.item2")}
            </div>
          </div>
        </div>
      </div>
      <div className="faq" id="faq">
        <div className="faq__inner center-block">
          <div className="faq__title">{t("faq.title")} </div>
          <div className="faq__subtitle">{t("faq.subtitle")}</div>
          <div className="faq__text">{t("faq.text")}</div>
          <div className="faq__accordion__wrapper">
            {accordionInfo.map((n, i) => (
              <div className="faq__accordion" key={i}>
                <div
                  className={`faq__accordion-header ${
                    i === Number(activeAccordion) ? "active" : ""
                  }`}
                  onClick={openTab}
                  data-index={i}
                >
                  <div
                    className="faq__accordion-btn"
                    onClick={openTab}
                    data-index={i}
                  >
                    <img src={plus} alt="" data-index={i} />
                  </div>
                  <div className="faq__accordion-title" data-index={i}>
                    {n.head}
                  </div>
                </div>
                {i === Number(activeAccordion) ? (
                  <div
                    className={`faq__accordion-body ${
                      i === Number(activeAccordion) ? "active" : ""
                    }`}
                  >
                    <div
                      className="faq__accordion-body-content"
                      dangerouslySetInnerHTML={{ __html: n.body }}
                    ></div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
