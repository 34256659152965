import React from "react";
// how it works
import work11 from "src/assets/images/11.jpeg";
import work12 from "src/assets/images/12.jpeg";
import work13 from "src/assets/images/13.jpeg";
import work14 from "src/assets/images/14.jpeg";
import work15 from "src/assets/images/15.jpeg";
import work16 from "src/assets/images/16.jpeg";
import work17 from "src/assets/images/17.jpeg";
import work18 from "src/assets/images/18.jpeg";
import Slider from "react-slick";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SliderComp = () => {
  const { t } = useTranslation();
  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`next__arr ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span> &gt;</span>
      </button>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`prev__arr ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span>&lt;</span>
      </button>
    );
  }
  const settings = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cordon__slider cordon__slider-secondary">
      <Link to="/SpeedChange" className="work__article  work__article-inner">
        <img src={work11} alt="" />
        <div className="work__article-link">
          <span className="work__article-link-top">
            {t("work.links.link1")}
          </span>
          <span className="work__article-link-bot">
            {t("work.previews.preview")}
          </span>
        </div>
      </Link>
      <Link to="/WorkInMove" className="work__article  work__article-inner">
        <img src={work12} alt="" />
        <div className="work__article-link">
          <span className="work__article-link-top">
            {t("work.previews.preview1.top")}
          </span>
          <span className="work__article-link-bot">
            {t("work.previews.preview1.bot")}
          </span>
        </div>
      </Link>
      <Link to="/Classifier" className="work__article  work__article-inner">
        <img src={work13} alt="" />
        <div className="work__article-link">
          <span className="work__article-link-top">
            {t("work.previews.preview2.top")}
          </span>
          <span className="work__article-link-bot">
            {t("work.previews.preview2.bot")}
          </span>
        </div>
      </Link>
      <Link to="/Wanted" className="work__article  work__article-inner">
        <img src={work14} alt="" />
        <div className="work__article-link">
          <span className="work__article-link-top">
            {t("work.previews.preview3.top")}
          </span>
          <span className="work__article-link-bot">
            {t("work.previews.preview3.bot")}
          </span>
        </div>
      </Link>
      <Link to="/Settings" className="work__article  work__article-inner">
        <img src={work15} alt="" />
        <div className="work__article-link">
          <span className="work__article-link-top">
            {t("work.previews.preview4.top")}
          </span>
          <span className="work__article-link-bot">
            {t("work.previews.preview4.bot")}
          </span>
        </div>
      </Link>
      <Link to="/RCar" className="work__article  work__article-inner">
        <img src={work16} alt="" />
        <div className="work__article-link">
          <span className="work__article-link-top">
            {t("work.previews.preview5.top")}
          </span>
          <span className="work__article-link-bot">
            {t("work.previews.preview5.bot")}
          </span>
        </div>
      </Link>
      <Link to="/Protection" className="work__article  work__article-inner">
        <img src={work17} alt="" />
        <div className="work__article-link">
          <span className="work__article-link-top">
            {t("work.previews.preview6.top")}
          </span>
          <span className="work__article-link-bot">
            {t("work.previews.preview6.bot")}
          </span>
        </div>
      </Link>
      <Link to="/Base" className="work__article  work__article-inner">
        <img src={work18} alt="" />
        <div className="work__article-link">
          <span className="work__article-link-top">
            {t("work.previews.preview7.top")}
          </span>
          <span className="work__article-link-bot">
            {t("work.previews.preview7.bot")}
          </span>
        </div>
      </Link>
    </Slider>
  );
};

export default SliderComp;
