import React, { useEffect } from "react";
import Fancybox from "../FancyBox";
import Footer from "./Footer";
import Header from "./Header";
import settings1 from "../assets/images/settings1.jpeg";
import settings2 from "../assets/images/settings2.jpeg";
import Slider from "react-slick";
import SliderComp from "./Slider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Settings: React.FC = () => {
  const { t } = useTranslation();
  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`next__arr ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span> &gt;</span>
      </button>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`prev__arr ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span>&lt;</span>
      </button>
    );
  }
  const baseUrl = "images/settings/settings";
  const settings = {
    customPaging: function (i: any) {
      return <img src={`${baseUrl}${i + 1}.jpeg`} alt="" />;
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb slick-dots-fancybox",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main ">
      <Header />
      <div className="fancybox center-block">
        <Slider {...settings} className="fancybox__slider">
          <Fancybox className="carousel" id="myCarousel">
            <a data-fancybox="gallery" href={settings1}>
              <img alt="" src={settings1} style={{ width: "100%" }} />
            </a>
          </Fancybox>
          <Fancybox className="carousel" id="myCarousel">
            <a data-fancybox="gallery" href={settings2}>
              <img alt="" src={settings2} style={{ width: "100%" }} />
            </a>
          </Fancybox>
        </Slider>
        <div
          className="fancybox__contentText"
          style={{ padding: "0", paddingLeft: "30px" }}
        >
          <div className="fancybox__contentText-title">
            {t("settings.title")}{" "}
          </div>
          <p>{t("settings.text.text1")}</p>
          <p>{t("settings.text.text2")}</p>
          <p>{t("settings.text.text3")}</p>
          <div className="fancybox__contentText-btns">
            <Link to="/Wanted" className="fancybox__contentText-btn">
              <span> {t("buttons.prev")}</span>
            </Link>
            <Link to="/RCar" className="fancybox__contentText-btn">
              <span>{t("buttons.next")} </span>
            </Link>
          </div>
        </div>
      </div>
      <SliderComp />
      <Footer />
    </div>
  );
};

export default Settings;
