import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/images/logo.svg";
import logoWhite from "../assets/images/logoWhite.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import burgerLogo from "../assets/images/Menu.svg";
import { useTranslation } from "react-i18next";
import ru from "src/assets/images/rus.png";
import uz from "src/assets/images/uzb.png";
import en from "src/assets/images/eng.png";
import arr from "src/assets/images/angle.svg";
import { scrollAfterRouteChange } from "src/utils/scrollAfterRouteChange";

interface IHeadermodel {
  className?: string;
}

const Header: React.FC<IHeadermodel> = ({ className }) => {
  const [burger, setBurger] = useState(false);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(false);
  const location = useLocation();
  const history = useHistory();

  let burgerRef = useRef<HTMLDivElement>(null);
  let langRef = useRef<HTMLDivElement>(null);

  const ToggleSwitchLang = () => {
    lang ? setLang(false) : setLang(true);
  };
  const ToggleSwitchBurger = () => {
    burger ? setBurger(false) : setBurger(true);
  };

  const handleLangToggle = (event: Event) => {
    if (burgerRef.current && !burgerRef.current.contains(event.target as any)) {
      setBurger(false);
    }
  };

  const handleBurgerToggle = (event: Event) => {
    if (langRef.current && !langRef.current.contains(event.target as any)) {
      setLang(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleLangToggle);

    return () => {
      document.removeEventListener("click", handleLangToggle);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleBurgerToggle);

    return () => {
      document.removeEventListener("click", handleBurgerToggle);
    };
  }, []);

  const changeLanguage = (language: any) => {
    i18n.changeLanguage(language);
    setLang(false);
  };

  return (
    <div className={`header__wrapper ${className}`}>
      <div
        className={`header center-block ${className} ${
          burger ? "active" : ""
        } ${className === undefined ? "inner" : ""}`}
        ref={burgerRef}
      >
        <Link
          to="/"
          className="header__img"
          onClick={(e) => {
            scrollAfterRouteChange("main", location, e, history);
          }}
        >
          <img
            src={className || className === undefined ? logo : logoWhite}
            alt=""
          />
        </Link>
        <div className="header__burger" onClick={ToggleSwitchBurger}>
          <img src={burgerLogo} alt="" />
        </div>
        <div
          className={`header__nav  ${burger ? "active" : ""}`}
          onClick={ToggleSwitchBurger}
        >
          <ul>
            <li>
              <a
                href="/#cordon"
                onClick={(e) => {
                  scrollAfterRouteChange("cordon", location, e, history);
                }}
              >
                {t("header.purpose")}
              </a>
            </li>
            <li>
              <a
                href="#work"
                onClick={(e) => {
                  scrollAfterRouteChange("work", location, e, history);
                }}
              >
                {t("header.workPrinciples")}
              </a>
            </li>
            <li>
              <a
                href="#installation"
                onClick={(e) => {
                  scrollAfterRouteChange("installation", location, e, history);
                }}
              >
                {t("header.installation")}
              </a>
            </li>
            <li>
              <a
                href="#functions"
                onClick={(e) => {
                  scrollAfterRouteChange("functions", location, e, history);
                }}
              >
                {t("header.functions")}
              </a>
            </li>
            <li>
              <a
                href="#specifications"
                onClick={(e) => {
                  scrollAfterRouteChange(
                    "specifications",
                    location,
                    e,
                    history
                  );
                }}
              >
                {t("header.specifications")}
              </a>
            </li>
            <li>
              <a
                href="#faq"
                onClick={(e) => {
                  scrollAfterRouteChange("faq", location, e, history);
                }}
              >
                {t("header.questions")}
              </a>
            </li>
            <li>
              <a
                href="#footer"
                onClick={(e) => {
                  scrollAfterRouteChange("footer", location, e, history);
                }}
              >
                {t("header.contacts")}
              </a>
            </li>
          </ul>
        </div>
        <div className={`header__lang ${lang ? "active" : ""}`} ref={langRef}>
          <div className="header__lang-title" onClick={ToggleSwitchLang}>
            <div className="header__lang-img">
              <img
                src={
                  i18n.language === "ru"
                    ? ru
                    : i18n.language === "en"
                    ? en
                    : i18n.language === "уз"
                    ? uz
                    : i18n.language === "o'z"
                    ? uz
                    : ru
                }
                alt=""
              />
            </div>
            <span className="header__lang-text ">{i18n.language}</span>
            <div className={`header__lang-title-arr ${lang ? "active" : ""}`}>
              <img src={arr} alt="" />
            </div>
          </div>
          <div className={`header__lang-dropdown ${lang ? "active" : ""}`}>
            <div
              className="header__lang-item"
              onClick={() => {
                changeLanguage("ru");
              }}
            >
              <div className="header__lang-img">
                <img src={ru} alt="" />
              </div>
              <span className="header__lang-text">RU</span>
            </div>
            <div
              className="header__lang-item"
              onClick={() => {
                changeLanguage("уз");
              }}
            >
              <div className="header__lang-img">
                <img src={uz} alt="" />
              </div>
              <span className="header__lang-text">УЗ</span>
            </div>
            <div
              className="header__lang-item"
              onClick={() => {
                changeLanguage("o'z");
              }}
            >
              <div className="header__lang-img">
                <img src={uz} alt="" />
              </div>
              <span className="header__lang-text">O'Z</span>
            </div>
            <div
              className="header__lang-item"
              onClick={() => {
                changeLanguage("en");
              }}
            >
              <div className="header__lang-img">
                <img src={en} alt="" />
              </div>
              <span className="header__lang-text">EN</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
