import React, { useEffect } from "react";
import Fancybox from "../FancyBox";
import Footer from "./Footer";
import Header from "./Header";
import wanted1 from "../assets/images/wanted1.jpeg";
import wanted2 from "../assets/images/wanted2.jpeg";
import Slider from "react-slick";
import arrow from "../assets/images/arrow.svg";
import SliderComp from "./Slider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Wanted: React.FC = () => {
  const { t } = useTranslation();
  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`next__arr ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span> &gt;</span>
      </button>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`prev__arr ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span>&lt;</span>
      </button>
    );
  }
  const baseUrl = "images/wanted/wanted";
  const settings = {
    customPaging: function (i: any) {
      return <img src={`${baseUrl}${i + 1}.jpeg`} alt="" />;
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb slick-dots-fancybox",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main ">
      <Header />
      <div className="fancybox center-block">
        <Slider {...settings} className="fancybox__slider">
          <Fancybox className="carousel" id="myCarousel">
            <a data-fancybox="gallery" href={wanted1}>
              <img alt="" src={wanted1} style={{ width: "100%" }} />
            </a>
          </Fancybox>
          <Fancybox className="carousel" id="myCarousel">
            <a data-fancybox="gallery" href={wanted2}>
              <img alt="" src={wanted2} style={{ width: "100%" }} />
            </a>
          </Fancybox>
        </Slider>
        <div
          className="fancybox__contentText"
          style={{ padding: "0", paddingLeft: "30px" }}
        >
          <div className="fancybox__contentText-title">{t("wanted.title")}</div>
          <p>
            <img src={arrow} className="list-arrow" alt="" />
            {t("wanted.text.text1")}
          </p>
          <p>
            <img src={arrow} className="list-arrow" alt="" />
            {t("wanted.text.text2")}
          </p>
          <p>
            <img src={arrow} className="list-arrow" alt="" />
            {t("wanted.text.text3")}
          </p>
          <p>
            <img src={arrow} className="list-arrow" alt="" />
            {t("wanted.text.text4")}
          </p>
          <p>
            <img src={arrow} className="list-arrow" alt="" />
            {t("wanted.text.text5")}
          </p>
          <p>
            <img src={arrow} className="list-arrow" alt="" />
            {t("wanted.text.text6")}
          </p>
          <p>
            <img src={arrow} className="list-arrow" alt="" />
            {t("wanted.text.text7")}
          </p>
          <div className="fancybox__contentText-btns">
            <Link to="/Classifier" className="fancybox__contentText-btn">
              <span> {t("buttons.prev")}</span>
            </Link>
            <Link to="/Settings" className="fancybox__contentText-btn">
              <span>{t("buttons.next")} </span>
            </Link>
          </div>
        </div>
      </div>
      <SliderComp />
      <Footer />
    </div>
  );
};

export default Wanted;
