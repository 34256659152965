//eslint-disable-next-line
export default {
  header: {
    purpose: "PURPOSE",
    workPrinciples: "PRINCIPLE OF OPERATION",
    installation: "INSTALLATION",
    functions: "FUNCTIONS",
    specifications: "SPECIFICATIONS",
    questions: "QUESTIONS",
    contacts: "CONTACT DETAILS ",
  },
  video: {
    title: {
      top: "Measurements on move",
      bottom: "CORDON.PRO",
    },
    subtitle:
      "New universal system for photo and video recording of traffic violations",
    button: "LEARN MORE",
  },
  cordon: {
    title: "CORDON.PRO",
    subtitle: "INSTANT AND AVERAGE SPEED CONTROL",
    info: `The system is designed for automatic photo and video recording of traffic violations in various modes (portable, mobile, stationary) and data transmission on recorded violations in real time mode in CAIS "Administrative practice" server.`,
  },
  qualities: {
    title: "Key features:",
    list: {
      speed:
        "Automatic speed measurement and photo and video recording of traffic violations.",
      install:
        "Ability to work on motion when the system is installed in the car.",
      switch:
        "Ability to quickly switch between stationary, mobile and portable modes of operation.",
      recognition:
        "Number plate recognition and vehicle classification with simultaneous verification against databases loaded into memory.",
    },
  },
  slider: {
    slide1: {
      title: "WORKS ON MOTION",
      subtitle: "Works on motion without changing the design of the car.",
    },
    slide2: {
      title: "UNIVERSAL",
      subtitle: "Works in stationary, mobile and portable and modes",
    },
    slide3: {
      title: "RELIABLE",
      subtitle: `Developed on the basis of time-tested systems "CORDON", "CORDON-M"`,
    },
  },
  fixation: {
    title: "VIOLATION TYPES",
    subtitle:
      "Traffic violations fixed by the system. The possibility of fixing certain violations depends on the chosen location and mode of operation of the system.",
    items: {
      item1: "Exceeding the set speed (instantaneous and / or average)",
      item2: "Driving in the oncoming traffic lane",
      item3: "Driving to oncoming tram lane",
      item4: "Driving in the opposite direction on a one-way road.",
      item5: "Movement along the dividing strip",
      item6: "Driving on the lane for public transport",
      item7: "Roadside driving",
      item8: "Driving on bicycle or pedestrian paths or sidewalks",
      item9: "Crossing a solid marking line when changing lanes.",
      item10: "Violation of the ban on the movement of trucks",
      item11: "Movement of trucks beyond the second lane.",
      item12: "Passing a STOP sign without stopping",
    },
  },
  work: {
    title: "HOW IT WORKS",
    subtitle: `The new universal automatic system of photo-video recording ""CORDON.PRO"" has unique capabilities.`,
    links: {
      link1: "Speed measurement",
      link2: "Installation and setup",
      link3: "How it works on the motion",
      link4: "On the roof of the car",
      link5: "What is a classifier for?",
      link6: "Protection against hit by a bullet",
      link7: "Search for vehicles by databases",
      link8: "Examples of violations ",
    },
    previews: {
      preview: "Automatic control of instantaneous and average speed",
      preview1: {
        top: "OPERATION ON MOVE",
        bot: "Installation without modifications and changes in the design of the car",
      },
      preview2: {
        top: "CLASSIFIER",
        bot: "Determination of vehicle category by image",
      },
      preview3: {
        top: "WANTED",
        bot: "Number plate recognition and vehicle search using downloaded databases",
      },
      preview4: {
        top: "INSTALLATION AND SETUP",
        bot: "Easy and fast setup",
      },
      preview5: {
        top: "R-AUTO",
        bot: "Installation of the system on the roof of the car",
      },
      preview6: {
        top: "ARMORED PROTECTION",
        bot: "Protection against firearms",
      },
      preview7: {
        top: "EVIDENCE BASE",
        bot: "Examples of recorded violations",
      },
    },
  },
  installation: {
    title: "WHERE TO INSTALL",
    subtitle:
      "The system can operate in portable, mobile and stationary modes.",
    text: "The system has several ways to install and connect power, depending on the tasks to be solved and the technical capabilities in the controlled areas. When ordering, the customer can choose the required installation and maintenance kit.",
    tabs: {
      tab1: "STATIONARY MODE",
      tab2: "PORTABLE MODE",
      tab3: "MOBILE MODE",
    },
    tabpanes: {
      tabpane1: {
        paragraph1: {
          title: "Installation with 220V connection",
          text: "A set with a mounting bracket for a support with a connection to a 220 V network. It is installed next to the carriageway at a height of up to 10 meters. Installation above the carriageway or the median strip of the road is also allowed.",
        },
        paragraph2: {
          title: "Installation with connection to the lighting network",
          text: "Pole mounting kit with FFT units for connection to the lighting network operating at night. The battery is constantly connected to the street lighting network through FFT 24/220 unit, which ensures its recharging at night.",
        },
      },
      tabpane2: {
        paragraph1: {
          title: "Tripod installation",
          text: "Set on tripod with battery box. The kit may include a tablet or laptop. The sensor is installed on the side of the carriageway and connected to the battery box.",
        },
        paragraph2: {
          title: "Installation in the car",
          text: "A sensor installed in the back seat or in the trunk of the car can be used not only in portable, as well as in mobile mode. If required, the car can be parked in a controlled area and quickly switch the system to a portable mode.",
        },
        paragraph3: {
          title: `"R-Auto" - installation on the roof of the car`,
          text: `Car roof mounting kit. The kit also includes a battery box and a laptop. <a href="/">Read more</a>`,
        },
      },
      tabpane3: {
        paragraph1: {
          title: "Installation in the car",
          text: `<div class="installation__tabPane-text">
          <strong>Set «C-Auto»</strong>
           Set for installation of the system in the hatchback, minibus, SUV or station wagon. The sensor is installed in the trunk of a patrol car on a mini tripod with additional fixation to ensure rigidity and reliability of fixation.
If required, the system can be transferred to a mobile mode and installed on a tripod on the side of the carriageway.
        </div>
        <div class="installation__tabPane-text">
          <strong>Set  «D-Auto»</strong>
           Set for installation of the system inside the passenger car. The sensor is mounted on the rear seat using a specialized bracket, headrest adapter and ISOFIX standard mounts. The set takes into account the design features of the Customer's car (the make of the car is specified when ordering).
        </div>
        <div class="installation__tabPane-text">
        "Both sets include a battery box for powering the system and an operator tablet. No changes and modifications to the vehicle design are required in order to install the system,
          <strong>
          Installation of the system in both versions takes no more than 5 minutes."
          </strong>
        </div>`,
        },
      },
    },
  },
  functions: {
    title: "WHAT ARE THE MAIN FUNCTIONS",
    items: {
      item1: {
        title: "AUTOMATIC PHOTO VIDEO RECORDING",
        info: `<ul>
        <li>
          Automatic photo and video recording of traffic violations in the control zone up to  <strong> six lanes </strong> simultaneously in both directions.
        </li>
        <li>
        Separate speed thresholds for different lanes and for vehicles of categories "B", "C" and "D".
        </li>
        <li>
        Automatic saving of photographic materials and video for each recorded violation.
        </li>
        <li>
        GLONASS/GPS module with automatic correction of the system time.
        </li>
      </ul>`,
      },
      item2: {
        title: "LICENSE PLATE RECOGNITION",

        info: `<ul>
        <li>Automatic recognition of license plates of many countries of the world, including two-line numbers.
        </li>
        <li>Possibility to turn on and off the recognition of the state registration of certain states.
        </li>
        <li>Checking recognized numbers in databases with notification of the operator and voice scoring of the number.
        </li>
        <li>
        Special operating mode <strong>"Interception"</strong> для
        for searching for vehicles with missing or unreadable license plates
        </li>
      </ul>`,
      },
      item3: {
        title: "CLASSIFICATION OF Vehicles",
        info: `<ul>
        <li>
        Automatic detection of the type of vehicle and their
          <strong>
          classification into four main categories
          </strong>
          (cars, trucks, buses, medium-sized).
        </li>
        <li>
          Automatic assignment to the Vehicle of the appropriate category of the speed threshold according to traffic rules.
        </li>
        <li>Automatic control of banning traffic for a given category (trucks, buses, etc.) in separate lanes or on the road as a whole.
        </li>
      </ul>`,
      },
      item4: {
        title: "DATA TRANSFER",
        info: `<ul>
        <li>Data transfer to the data center server via encrypted wired or wireless communication channels (3G/4G).
        </li>
        <li>
        Automatic switching to backup communication channels (Wi-Fi, 4G) in case of failure or malfunction of the main channel.
        </li>
        <li>Possibility of parallel data transfer from the system to different servers.
        </li>
      </ul>`,
      },
      item5: {
        title: "VIDEO MONITORING",
        info: `<ul>
        <li>
        High resolution video streaming via RTSP protocol.
        </li>
        <li>Maintaining continuous video recording with the ability to download a video according to specified period of time.
        </li>
      </ul>`,
      },
      item6: {
        title: "NIGHT TIME WORK",
        info: `<ul>
        <li>Built-in infrared light for night work.
        </li>
        <li>Additional external IR illuminator for guaranteed identification of the vehicle brand by image.
        </li>
      </ul>`,
      },
    },
  },
  specifications: {
    title: "SPECIFICATIONS",
    tabs: {
      tab1: {
        title: "METROLOGICAL PARAMETERS",
        text: `<table>
        <tbody>
          <tr>
            <td>Operating radiation frequency</td>
            <td>from 24.05 to 24.25 GHz</td>
          </tr>
          <tr>
            <td>Range of measured speeds</td>
            <td> 2 — 300 km/h</td>
          </tr>
          <tr>
            <td>
            Limits of permissible absolute error of speed measurements 
            </td>
            <td>± 1.0 km/hч</td>
          </tr>
          <tr>
            <td>
            Limits of permissible absolute error of synchronization of the internal time scale with UTC (SU)
            </td>
            <td> ± 5 µs</td>
          </tr>
        </tbody>
      </table>`,
      },
      tab2: {
        title: "POWER SUPPLY AND WEIGHT DIMENSIONS",
        text: `<table>
        <tbody>
          <tr>
            <td>
            Power consumption (at positive temperatures)
            </td>
            <td> no more than 25 W</td>
          </tr>
          <tr>
            <td>Supply voltage DC</td>
            <td> 10-16 V</td>
          </tr>
          <tr>
            <td>
            AC supply voltage with supplied power unit
            </td>
            <td> 90-300 V</td>
          </tr>
          <tr>
            <td>Sensor weight is </td>
            <td>no more than 6.0 kg</td>
          </tr>
          <tr>
            <td>Overall dimensions are </td>
            <td>not more than 460×180×280 mm</td>
          </tr>
        </tbody>
      </table>`,
      },
      tab3: {
        title: "OPERATING CONDITIONS AND RELIABILITY",
        text: `<table>
        <tbody>
          <tr>
            <td>
            Ambient temperature:
              <div></div>
              — standard version:
              <br />
              — arctic version:
            </td>
            <td>
              <div></div>
              <br />
              <div></div>
              from −40°С to +60°С
              <br />
              from −55°С to +60°С
              <br />
            </td>
          </tr>
          <tr>
            <td>Relative air humidity: </td>
            <td>98% at t°=+25°С</td>
          </tr>
          <tr>
            <td>Atmospheric pressure:</td>
            <td> 60 to 106.7 kPa</td>
          </tr>
          <tr>
            <td>MTBF:</td>
            <td> at least 35,000 hours</td>
          </tr>
          <tr>
            <td>Average service life before decommissioning:</td>
            <td> at least 6 years</td>
          </tr>
          <tr>
            <td>
            Degree of protection of the shell against penetration of solid objects and water: 
            </td>
            <td>IP65</td>
          </tr>
          <tr>
            <td>
            Degree of protection of the shell against external mechanical shock: 
            </td>
            <td>IK08</td>
          </tr>
          <tr>
            <td>
            Protection class against small arms (using an armored casing): 
            </td>
            <td>"Br2" and "C1"</td>
          </tr>
        </tbody>
      </table>`,
      },
      tab4: {
        title: "INFORMATION SECURITY",
        lists: {
          list1: "Protection of data and firmware from unauthorized changes.",
          list2:
            " The exported data are protected by digital signature using a certified hardware key.",
          list3:
            "Keeping a log of events and actions of the user of the system.",
          list4:
            "Sending notifications via SMS and e-mail about the recorded facts of physical impacts on the device (shocks, vibration).",
        },
      },
    },
  },
  statistics: {
    items: {
      item1: {
        title: "PROBABILITY OF RECOGNITION",
        progressbar: {
          progressbar1: "SRN recognition (98%)",
          progressbar2: "Vehicle classification by video (95%)",
        },
      },
      item2: {
        title: "INDICATORS OF ERROR",
        progressbar: {
          progressbar1: "Speed measurement (± 1.0 km/h)",
          progressbar2: "Time synchronization with UTC (± 5 µs)",
        },
      },
    },
  },
  guarantee: {
    title: "CERTIFICATION AND WARRANTY",
    top: {
      item1: `Verification interval -  <span>2 years</span>`,
      item2: `Warranty period -  <span>2 years</span>`,
    },
    bot: {
      item1: `Type approval certificate for measuring instruments O'T 0000590 No. 02-2.0070 of the Uzbek Agency for Standardization, Metrology and Certification ("Uzstandard" Agency)
        Certificate of Conformity of the National Certification System of the Republic of Uzbekistan No. UZ.SMT.01.354.72116889 dated 13.12.2021
        Certificate of approval of the type of measuring instruments OS.C.33.158.A No. 75141 of the Federal Agency for Technical Regulation and Metrology of the Russian Federation.
        `,
      item2:
        "Certificate of Conformity of the Customs Union No. TC RU C-RU.ME83.B.00002/18.",
    },
  },
  faq: {
    title: "QUESTIONS AND ANSWERS",
    subtitle: `The most frequently asked questions about "CORDON.Pro"M system`,
    text: "After the presentation of Cordon.Pro M system at the international exhibition Road 2019, we received a large number of questions from journalists. In addition, publications appeared in the press that explained the technical side of this project inaccurately (and sometimes simply incorrectly!). Below there are the answers and explanations on the most important features of Cordon.Pro M system:",
    accordion: {
      accordion1: {
        head: "How legal is the use of the system on motion, installed in a patrol car, if there is no warning sign for photo and video recording on the controlled section of the road?",
        body: `<p>
        Sign  
        <span>
        5.43 "Radar" or signs 5.41 and 7.19 "Photo and video recording"
        </span>
        , should be installed in front of the locations of stationary systems "Cordon.Pro" - depending on their functions. according to the traffic rules of Uzbekistan (Resolution of the Cabinet of Ministers of Uzbekistan dated December 24, 2015 No. 370). And when using it in stationary mode, a warning sign must be installed, of course.
      </p>
      <p>
      It should be noted that electronic boards with warnings about automatic speed control on a particular highway are already widely used today. So the driver is always warned that he is under control.
      </p>
      <p>
      But the possibilities of artificial intelligence are growing, and tomorrow such warnings may appear at the entrance to the city. And what is so amazing about that? We sincerely hope that you do not mind either to curb  extreme riders on our roads finally.
      </p>`,
      },
      accordion2: {
        head: "What is the innovativeness of Cordon.Pro system, because the devices installed in the car and measuring the speed on move have existed for a long time ??",
        body: `<p>
        Quite right, it was Simicon company that released a police radar that worked on move for the first time in Russia in 1999 . The device was called <strong>«Искра-1Д»</strong> , it was included in the register of measuring instruments of the Russian Federation and provided a radar measurement of its own speed and the speed of the offending vehicle. The solution was patented. So algorithms for radar measurement of own speed have been developed and improved for <strong>more than 20 years.</strong>
      </p>
      <p>
      Later, radar video recorders appeared, also working on the move (<strong>Iskra-Video, Binar</strong>). The inspector had to manage these devices: they could not work without human participation. The inspector stopped the violator and presented him with evidence of the violation, since these devices were not able to recognize license plates and identify the violator in the stream of cars.  This fact showed  that it was required to exclude the "human factor" and the "corruption component". Now this task is solved.
      </p>
      <p>
        <strong>
          "Now ""Cordon.Pro"" performs several tasks automatically:"
        </strong>
      </p>
      <p>
        <strong>1. determines its own speed using a Doppler radar, and also measures the speeds and coordinates of all targets that fall into its field of view;
        </strong>
      </p>
      <p>
        <strong>2. using optical method, it recognizes license plates and determines their coordinates;
        </strong>
      </p>
      <p>
        <strong>3.  determines accurately which number belongs to which speed;
        </strong>
      </p>
      <p>
        <strong>4. generates photographic material and a video clip for each violator and stores them in memory;
        </strong>
      </p>
      <p>
        <strong>5. determines the category of the vehicle in order to know by what threshold to calculate speed violations - they are different for different cars!
        </strong>
      </p>
      <p>
        <strong>6. immediately sends photographic materials to the data center via a 3G/4G/Ethernet communication channel;
        </strong>
      </p>
      <p>
        <strong>7. and most importantly, it does not allow any human participation in this process, everything happens automatically.</strong>
      </p>
      <p>
        <strong>So yes, in general, you are right, practically nothing new, ordinary artificial intelligence! ..
        </strong>
      </p>`,
      },
      accordion3: {
        head: "How will the evidentiary basis of the offense be provided in the conditions of frequent changes in the speed limit in the controlled area? It's no secret that the accuracy of determining GPS coordinates is still low.",
        body: `<p>Electronic map is prepared in advance to use Cordon.Pro system, in which the coordinates of speed limit signs, boundaries of settlements, street names, house numbers are indicated.
      </p>
      <p>The map is loaded into the memory of the system, and then it determines whether this car is an intruder. It is clear that inside speed limit is not more than 70 km/h, and  is not more than 30 km/h in residential areas and adjacent areas (a piece of land between house buildings)- (paragraph 78 of the Traffic rules  of the Republic of Uzbekistan).
      Outside settlements, movement is allowed (clause 79 of the Traffic rules of the Republic of Uzbekistan):
      cars and trucks with a maximum authorized weight of not more than 3.5 tons - at a speed of not more than 100 km/h;
      intercity buses and minibuses - no more than 90 km/h;
      other buses, cars when towing a trailer, motorcycles, trucks with a maximum permissible weight of more than 3.5 tons - no more than 80 km / h;
      trucks when towing a trailer - no more than 70 km / h.
      </p>
      <p>The error in determining satellite coordinates is confirmed 
        <strong>
        metrologically, certified and is no more than ±4.5 m
        </strong>
        . However, in Cordon.Pro system, for  determining the boundaries of the restricted zone automatically, the correction for inaccuracy in determining the coordinates
        <strong>is tripled (!) and is ±15 m.</strong>
      </p>
      <p>This is done so that there are no random errors associated with the incorrect application of the established restrictions. The geographic position of a speed limit sign and the end of the sign's coverage are always subject to this amendment.
      </p>`,
      },
      accordion4: {
        head: "How is the speed measurement error of 1 km/h ensured while the patrol car is moving?",
        body: `<p>
        <strong>An error of ±1 km / h</strong> is far from the limit today. Thus, the metrological equipment, which was used to check the accuracy of measurements of Cordon.Pro system, has an error of ±0.3 km/h.
      </p>
      <p>Modern high-performance processors in combination with detailed algorithms of mathematical analysis can do a lot! The radar measurement algorithms implemented in the system provide for frequent and multiple repetition of measurements, as well as verification of their reliability.
      </p>
      <p>
      The results are confirmed by numerous experiments. The obtained characteristics are confirmed by the Type Approval Certificate of measuring instruments OS.C.33.158.A No. 75141 dated September 19, 2019.
      </p>`,
      },
    },
  },
  footer: {
    address: {
      city: "100164, Tashkent city, Mirzo-Ulugbek district",
      street: "m. Yalangach, 119a",
    },
    titles: {
      title1: "SALES DEPARTMENT",
      title2: "TECHNICAL SUPPORT",
      title3: "SOCIAL NETWORK",
    },
  },
  buttons: {
    prev: "Previous",
    next: "Next ",
  },
  speedChange: {
    title: "SPEED MEASUREMENT",
    text: {
      text1:
        "systems of CORDON family are equipped with a multi-purpose radar that provides a number of unique features:",
      text2:
        "Lack of strict requirements for the installation angle of the system. The multi-purpose radar measures all parameters of the target movement at once (speed, distance, azimuth).",
      text3:
        "Simultaneous recording of all targets in the control zone. The requirement to have only one vehicle in the photo has disappeared with the use of the multi-purpose radar. Even if there are several vehicles in the control zone, the accuracy of binding the measured speeds to the recorded vehicles is guaranteed.",
      text4:
        "Photoradars of the first generation could not record more than 1-2 lanes. One system of CORDON family can control the entire road, thereby replacing up to 6 systems of the previous generation. The dimensions of the control zone are ± 15° along the angle from the axis of the system, and from 10 to 80 m along the distance from the installation site of the system.",
      text5:
        "The system provides the ability to measure the average speed on sections and chains of sections together with any other Cordon.Pro, Cordon-M or Cordon-Temp system. The minimum distance for measuring the average speed is only 200 meters, while the range of measured speeds is from 1 to 350 km/h.",
      text6:
        "Measuring the average speed of movement is carried out by an indirect method: the speed is defined as the ratio of the length of the traveled path to the time spent on its passage. If it is necessary to control long roads, the  use of  two, three or more systems in a chain is allowed. The passage time of the segment is defined as the difference between the recording time at the input and output. The distance between the installation points of the systems is measured by verified length (distance) meters, while at the time of recording, the radar coordinates of the vehicle from both systems are also taken into account.",
    },
  },
  workInMove: {
    title: "OPERATION ON MOVE",
    text: {
      text1: `A unique feature of "Cordon.Pro"M system is the possibility of its installation in a car for operation on move.`,
      text2:
        "During movement, the system automatically measures its own speed and the speed of vehicles in the control zone by means of radar. At that, their license plates are recognized at the same time. Previously prepared electronic map with the marked coordinates of the speed limit areas is loaded into the memory of the system for the correct determination of the permitted speed threshold when working on move. The map is edited by the administrator.",
      text3:
        "Based on metrologically reliable data, the comple detects violators of the speed limit x automatically and transmits the recorded data to CARTO instantly.",
    },
  },
  classifier: {
    title: "CLASSIFIER",
    text: {
      text1:
        "The system  recognizes the category of the recorded vehicle (cars, trucks, buses) automatically. Classification is performed by an optical method without recourse to any databases. This allows automatical  recording violations of the ban on the movement of trucks, as well as automatical assignment of  a speed limit according to traffic rules corresponding to the vehicle category.",
      text2:
        "Automatic correct assignment of the speed threshold corresponding to the type of vehicles (cars, trucks, buses) according to traffic rules based on data from the built-in optical classifier of the vehicle.",
      text3:
        "The function of automatic qualification of violations of paragraph 91 of the traffic rules of Uzbekistan by drivers of the vehicle.",
      text4:
        "Automatic fixation of violation of the ban on the movement of trucks (Sign 3.4. traffic rules of Uzbekistan) based on data from the built-in optical classifier of the vehicle.",
    },
  },
  wanted: {
    title: "SEARCH",
    text: {
      text1:
        "Automatic recognition of license plates of many countries of the world, including the state registration number according to the Decree of the Cabinet of Ministers of the Republic of Uzbekistan, dated June 27, 2007 No. 131, two-line and three-line numbers.",
      text2: `systems of "Cordon" family are used successfully n the Far East to control the movement of vehicles in China (hieroglyphs recognition), as well as in such foreign countries as Korea and Iran (Arabic script).`,
      text3:
        "In accordance with the requirements of  the Resolution of the Cabinet of Ministers of the Republic of Uzbekistan, dated april 21, 2021. No. 232, clause 5.2 of GOST R 57144-2016, measuring systems of CORDON family provide recognition of SRN of at least 4 foreign countries upon request and the list of the Customer. Depending on the terms of the contract with the Customer, the systems shipped from the factory may be enabled for recognition of SRN of certain foreign states. It is also possible to reconfigure the recognition system to other countries or increase the number of countries for recognition of SRN on a commercial basis.",
      text4:
        "Neural network technology provides fast learning of new SRN formats.",
      text5:
        "The system provides obtaining information about vehicles found according to the search bases loaded into the device memory, as well as prompt recording of videos on an SD memory card. When connected to the system, the operator can use the function of voicing license plates found in the databases.",
      text6: `All systems of  "CORDON" family have a special "Interception" mode of operation, designed to search for and record those vehicles whose license plates cannot be recognized or are absent. According to the feedback from users of our equipment, this feature is indispensable when conducting operational-search activities.`,
      text7:
        "The TCP/XML data transfer protocol makes  possible integration of systems with centralized vehicle search systems.",
    },
  },
  settings: {
    title: "SETUP AND INSTALLATION",
    text: {
      text1: `The "Cordon.Pro" system can be mounted on a stationary support similarly to the "Cordon-M" system - using a swivel bracket that can be adjusted in all planes. The bracket can be fixed to the support with L-bars or with mounting tape.`,
      text2:
        "Configuration is performed via the Web interface remotely, or at the installation site using a laptop or tablet. Significantly reduced the time required for initial setup. The interface uses interactive indicators that display the quality of the setting and give recommendations for correcting the position of the sensor. Moreover, the system starts photo-video recording of traffic violations only if it is correctly oriented and has passed self-diagnosis.",
      text3:
        "There are various options for connecting to power supply networks, including connection to the lighting network and autonomous operation from the battery.",
    },
  },
  rcar: {
    title: "R-AUTO",
    text: "Mounting kit on the luggage rails of the car provides the ability to change the location of the system quickly when used in a portable mode. Thus, the system can be deployed quickly  on a selected section of the road, including in conditions that do not allow the use of a standard tripod. The bracket is installed on any car with luggage rails and provides convenient adjustment of the inclination and angle  of the sensor rotation",
  },
  protection: {
    title: "ARMORED PROTECTION",
    text: `The casing made of armored steel was specially designed for Cordon.Pro M system. Provides protection against firearms, is certified for bullet resistance according to classes ""Br2"" (SPS and TT pistols) and ""C1"" (a shotgun with a lead bullet). The casing can be made in two versions: with armored glass and without glass. Armor protection is attached to the systems already installed on the support using a standard bracket. Two people are required for installation.`,
  },
  base: {
    title: "EVIDENCE BASE",
    text: "The system  records traffic violations in the control zone automatically and forms the necessary evidence base for each purpose: overview photographs, photographs with a visually distinguishable license plate, video recordings of each violation, etc. At the same time, continuous video recording of events in the control zone of the system is carried out. Photo and video recording of the vehicle at night is carried out using the built-in infrared illumination, the record quality allows determining the brand of the car among other things,.",
  },
  copyright: {
    copyright: "Copyright by",
    rights: "All rights reserved",
  },
  cross: {
    link: {
      link1: "CORDON PRO M",
      link2: "CORDON CROSS",
    },
    title: {
      top: "AUTOMATIC INTERSECTION CONTROL",
      bot: "CORDON-CROSS",
    },
    subtitle: "Measuring complex with video recording CORDON-CROSS",
    cordon: {
      subtitle:
        "Stationary complex CORDON-CROSS is designed for monitoring and photo-video recording of traffic violations at intersections with data transmission to a data processing center (data center). Provides automatic number recognition and verification of all vehicles against various databases.",
    },
    slider: {
      slider1: {
        title: "WORKING AT NIGHT",
        subtitle: "Automatic switching to night shooting mode",
      },
      slider2: {
        title: "Detecting traffic light signals",
        subtitle:
          "Recognition by video frames and/or by means of a wired connection to the road controller.",
      },
      slider3: {
        title: "(With radar) Speed measurement up to 300 km/h",
        subtitle:
          "Speed measurement in the range from 2 to 300 km/h (with radar).",
      },
    },
    fixation: {
      title: "FIXED traffic violations",
      subtitle:
        "The list of fixed traffic violations depends on the configuration of the complex and the layout of cameras at the intersection.",
      items: {
        item1: "Travel to a traffic light prohibiting signal",
        item2:
          "Failure to comply with the requirement to stop in front of the stop line at a forbidding traffic light signal",
        item3: "Traffic on the dividing lane",
        item4: "Exit to the oncoming traffic lane",
        item5: "Movement of vehicles on the side of the road",
        item6: "Traffic on bicycle, pedestrian paths or sidewalks",
        item7:
          "Violation of the prohibition of movement of cargo vehicles (including on separate lanes)",
        item8:
          "Violation of the minimum distance between vehicles when driving",
        item9:
          "Driving with the driver's or passenger's seat belt not fastened",
        item10: "Exceeding the set speed limit",
        item11: "Driving in the opposite direction on a one-way road",
        item12: "Violation of the rules of turning or U-turn",
        item13: "Traffic on the lane for route vehicles",
        item14: "Rebuilding vehicles through a solid marking line",
        item15:
          "Violation of the requirements prescribed by road signs or markings",
        item16:
          "Stop at the waffle marking and exit to the intersection during congestion",
        item17: "Exceeding the average speed on a long stretch of road *",
        item18:
          "Driving without headlights on (low beam / daytime running lights)",
      },
    },
    compound: {
      title: "COMPOSITION OF THE COMPLEX",
      subtitle:
        "The complex consists of one or more video sensors (recognition cameras) with IR spotlights, surveillance cameras and an information processing unit (Cross FIGHTS).",
      text: {
        text1:
          "Due to its modularity, the complex has a unique scalability. To control the speed mode, one or more video sensors of the complex can be equipped with radar modules.",
        text2: `<p>On request, video sensors can be additionally equipped with:
        — automatic glass cleaning systems;
        — motorized tilt-and-turn devices with remote control.</p>`,
        text3:
          "All cameras have optical interfaces and are connected to COMBAT using fiber-optic communication lines (fiber optic lines).",
      },
    },
    scheme: {
      title: "LAYOUT AND WORKING PROCEDURE",
      subtitle:
        "Video sensors and surveillance cameras are mounted at a height of several meters on stationary structures (lighting mast, truss support, etc.) and connected to a 220 V network.",
      text: {
        text1:
          "Control zones and an example of equipment placement at an intersection",
        text2: "Designations: VD - Video sensor, OK - Surveillance camera",
        text3:
          "To fully control a typical intersection of two two-way roads with two lanes in each direction, 4 video sensors, 4 surveillance cameras and one information processing unit will be required. The specific quantity and composition of equipment is specified in each project depending on the configuration of the intersection.",
        text4:
          "Installation of all required parameters of the complex is performed remotely via the built-in Web interface using a computer or tablet. The determination of the traffic light signal (including additional sections) is performed automatically from the image from the survey camera, or in a combined way with connection to the traffic light controller — this provides an unsurpassed degree of reliability and protection from errors.",
        text5:
          "The information processing unit Cross performs recognition of the state registration number (UAH) according to data from video sensors, analysis of movement in the frame and automatic recognition of traffic signals. In the process of information processing, the complex forms collages of photos of the violator, which may include a general photo of the intersection and an enlarged photo of the violator, and/or photos of the traffic situation taken at a given interval. Data with recognized UAH and related information is transmitted using secure communication channels to the server of the Data Processing Center for processing violations.",
        text6:
          "During operation, the complex records all vehicles that have passed through the intersection. It is possible to fix vehicles with unrecognized or missing UAH for search purposes. ",
      },
    },
    functions: {
      title: "WHAT ARE THE MAIN FUNCTIONS",
      items: {
        item1: {
          title: "AUTOMATIC PHOTO-VIDEO RECORDING",
          info: `<ul>
          <li>
          Automatic determination of the position of all vehicles at the intersection in all traffic lanes and photo-video recording of traffic violations.
          </li>
          <li>
          Control of up to six traffic lanes with one camera.
          </li>
          <li>
          Detection of traffic light signals – recognition by video frames and/or by means of a wired connection to a road controller.
          </li>
          <li>
          Measurement of speeds in the range from 2 to 300 km/ h (if there is a radar).
          </li>
          <li>
          Automatic saving of photos and videos for each recorded violation.
          GLONASS/GPS module with automatic correction of the system time of the complex.
          </li>
        </ul>`,
        },
        item2: {
          title: "LICENSE PLATE RECOGNITION AND Vehicle Search",
          info: `<ul>
            <li>
            Automatic recognition of UAH based on neural network technology, which provides rapid learning of new formats and recognition of license plates of many countries of the world, including two-line numbers.
            </li>
            <li>
            The ability to turn on and off the recognition of the citizens of certain states.
            </li>
            <li>
            Checking recognized numbers against databases with operator notification and voice voicing of the number.
            </li>
            <li>
            Special mode of operation "Interception" for the search of vehicles with missing or unreadable license plates.
            </li>
          </ul>`,
        },
        item3: {
          title: "Vehicle classification",
          info: `<ul>
            <li>
            Automatic identification of the type of vehicles and their classification into four main categories (passenger cars, trucks, buses, medium-sized).
            </li>
            <li>
            Automatic recognition of vehicle brands and models (on request).
            </li>
            <li>
            Automatic assignment of the speed threshold according to traffic regulations corresponding to the category of vehicles.
            </li>
            <li>
            Automatic control of the prohibition of movement of vehicles for a given category (trucks, buses, etc.) on separate lanes or on the road as a whole.
            </li>
          </ul>`,
        },
        item4: {
          title: "VIDEO SURVEILLANCE",
          info: `<ul>
            <li>
            Broadcast a high-resolution video stream over the RTSP protocol.
            </li>
            <li>
            Continuous video recording with the ability to download a video clip for a specified period of time.
            </li>
          </ul>`,
        },
        item5: {
          title: "DATA TRANSMISSION",
          info: `<ul>
            <li>
            Data transfer to the data center server via encrypted wired or wireless communication channels (3G/4G).
            </li>
            <li>
            Automatic switching to backup communication channels (Wi-Fi, 4G) in case of failures or failure of the main channel.
            Full compatibility with the systems of the Ministry of Internal Affairs of the Republic of Uzbekistan.
            </li>
            <li>
            The possibility of parallel data transfer from the complex to various servers.
            </li>
          </ul>`,
        },
        item6: {
          title: "PROTECTION AND SECURITY",
          info: `<ul>
            <li>
            Protection of data and embedded software from unauthorized changes.
            </li>
            <li>
            The exported data is protected by an electronic digital signature.
            </li>
            <li>
            Logging of events and user actions of the complex.
            </li>
          </ul>`,
        },
        item7: {
          title: "WORKING AT NIGHT",
          info: `<ul>
            <li>
            Infrared illumination for night work.
            </li>
            <li>
            The possibility of installing additional external IR spotlights.
          </li>
          </ul>`,
        },
        item8: {
          title: "STATISTICS",
          info: `<ul>
            <li>
            Collection of statistical data on the intensity of traffic flow at the intersection.
            </li>
            <li>
            Analysis of recorded traffic violations by type of traffic violations, categories of vehicles at the entire intersection, or only by selected cameras.
          </li>
          </ul>`,
        },
      },
    },
    guarantee: {
      bot1: "Certificate of Conformity of the Eurasian Economic Union No. EAEU RU C-RU.ME83.B.00005/19. Valid until 28 08.2024.",
      bot2: "Certificate of certification of the method of measuring average speed No. 1084/253-(RA.RU.310494)-2019.",
    },
  },
};
