//eslint-disable-next-line
export default {
  header: {
    purpose: "QO'LLANILISHI",
    workPrinciples: "ISHLASH TAMOYILI (TARTIBI)",
    installation: "O'RNATILISHI",
    functions: "VAZIFALARI",
    specifications: "TAVSIFI (XUSUSIYATI)",
    questions: "SAVOLLAR",
    contacts: "MUROJAAT UСHUN",
  },
  video: {
    title: {
      top: "Harakat paytida o'lchash",
      bottom: "cordon.pro",
    },
    subtitle:
      "YHQ buzganlikni foto va video qayd etuvchi yangi universal majmua",
    button: "BATAFSIL BILISH",
  },
  cordon: {
    title: "CORDON.PRO",
    subtitle: "TEZ VA O'RTACHA TEZLIKNI NAZORAT QILISH BO'YICHA",
    info: `Majmua turli xil rejimlarda (mobil, harakatlanayotganda, statsionar holatda) YHQ buzilishini avtomatik tarzda foto va video qayd etish va real vaqt rejimida qayd etilgan qoidabuzarliklar toʼgʼrisidagi maʼlumotlarni "Maʼmuriy amaliyot" KАT tizimining serveriga uzatishga moʼljallangan.`,
  },
  qualities: {
    title: "Asosiy xususiyatlari:",
    list: {
      speed:
        "Tezlikni avtomatik ravishda o'lchash va YHQ buzilishini foto va video qayd etish.",
      install:
        "TMajmuani avtomobilga o'rnatib, harakatlanayotgan holda ishlash imkoniyati mavjudligi.",
      switch:
        "Majmua operativ tarzda statsionar, harakatlanayotgan va mobil rejimiga oʼtkazish imkoniyatlari mavjudligi.",
      recognition:
        "Bir vaqtning oʼzida transport vositalarning turini tasniflash, davlat raqam belgilarni aniqlash va yuqlangan maʼlumotlar bazasi orqali tekshirish",
    },
  },
  slider: {
    slide1: {
      title: "HАRАKАTLАNАYOTGАN HOLDА ISHLАYDI",
      subtitle:
        "Majmua avtomobil koʼrinishini oʼzgarmagan holatida oʼrnatiladi va harakatlanayotgan holda ishlaydi.",
    },
    slide2: {
      title: "UNIVERSIAL",
      subtitle: "Statsionar, ko'chib yuruvchi va mobil rejimlarida ishlaydiv",
    },
    slide3: {
      title: "ISHONCHLI",
      subtitle: `Vaqt sinovidan oʼtgan "KORDON", "KORDON-M" majmualari negizida ishlab chiqilgan`,
    },
  },
  fixation: {
    title: "NIMANI QAYD ETADI?",
    subtitle:
      "To'plam tomonidan YHQ buzilishining qayd etilishi. U yoki boshqa qoidabuzarliklarni qayd etish imkonining mavjudligi to'plamning tanlangan joyi va ishlash rejimiga bog'liq bo'ladi.",
    items: {
      item1:
        "Belgilangan harakat tezligining oshirilishi (tez va/yoki o'rtacha)",
      item2: "Qarama-qarshi harakat uchun mo'ljallangan chiziqni kesib o'tish",
      item3: "Qarama-qarshi yo'nalishdagi tramvay yo'lini kesib o'tish.",
      item4:
        "Bir tomonlama harakatli yo'l bo'ylab qarama-qarshi yo'nalishda harakatlanish",
      item5: "Ajratuvchi chiziq bo'yicha harakatlanish.",
      item6: "Jamoat transportiga mo'ljallangan chiziq bo'ylab harakatlanish.",
      item7: "Yo'l cheti bo'ylab harakat",
      item8: "Velosiped yoki yo'lovchi, yoxud yo'lakcha bo'ylab harakatlanish.",
      item9: "Yo'lga turib olishda uzun chiziq belgisini kesib o'tish.",
      item10: "Yuk tashuvchi TV harakatlanishi taqiqlanishining buzilishi",
      item11:
        "Yuk tashuvchi Tvning keyinchalik ikkinchi yo'l bo'ylab harakatlanishi.",
      item12: "TO'XTASH belgisini kesib o'tishda to'xtamasdan o'tib ketish.",
    },
  },
  work: {
    title: "BU QANDAY ISHLAYDI?",
    subtitle: `Suratga va tasvirga olishni avtomatik ravishda qayd etuvchi yangi universal ""Cordon.Pro"" to'plami ajoyib imkoniyatlarga ega.`,
    links: {
      link1: "Tezlikni o'lchash",
      link2: "Montaj qilish va sozlash",
      link3: "Harakatlanish paytida qanday ishlaydi",
      link4: "Avtomobil tomida",
      link5: "Klassifikator nima uchun kerak",
      link6: "O'q otuvchi quroldan himoya",
      link7: "TVni baza bo'yicha qidirish",
      link8: "Qoidabuzarliklarga misollar",
    },
    previews: {
      preview: "Tez va oʼrtacha tezlikni avtomatik nazorat",
      preview1: {
        top: "HARAKATLANAYOTGAN PAYTDA ISHLASH",
        bot: "Avtomobilga o'zgartirishlarsiz o'rnatish",
      },
      preview2: {
        top: "KLASSIFIKATOR",
        bot: "Tasvir bo'yicha avtomobil toifasini aniqlash",
      },
      preview3: {
        top: "qidirish",
        bot: "Yuklab olingan ma'lumotlar bazalari yordamida raqamlarni aniqlash va avtomobilni qidirish",
      },
      preview4: {
        top: "MONTAJ QILISH VA SOZLASH",
        bot: "Oson va tez sozlash",
      },
      preview5: {
        top: "R-AVTO",
        bot: "Mashinaning tomida majmuani o'rnatish",
      },
      preview6: {
        top: "zirhli himoya",
        bot: "O'qotar qurollardan himoya qilish",
      },
      preview7: {
        top: "DALILLAR BAZASI",
        bot: "Ro'yxatga olingan qoidabuzarliklarga misollar",
      },
    },
  },
  installation: {
    title: "QAYERGA O'RNATISH MUMKIN?",
    subtitle: "Majmua mobil, ko'chma va statsionar rejimlarda ishlashi mumkin.",
    text: "Majmua oldida qoʼyilgan vazifalarga qarab va texnik imkoniyatlardan kelib chiqib bir necha usullarda oʼrnatilishi va quvvat tarmoqqa ulanishi mumkin. Buyurtmachi buyurtma berishda zarur montaj-ekspluatatsiya toʼplamini tanlashi mumkin.",
    tabs: {
      tab1: "STATSIONAR REJIM",
      tab2: "KO'CHMA REJIM",
      tab3: "MOBIL REJIMI",
    },
    tabpanes: {
      tabpane1: {
        paragraph1: {
          title: "220V tarmog'iga ulash orqali o'rnatish",
          text: "220V tarmog'iga ulash bilan bog'liq tirgovchilarga mahkamlash to'plami. Yo'lning o'tish qismi bilan yonma-yon bo'lgan 10 metrgacha balandlikka o'rnatiladi. Shuningdek, yo'lning o'tish qismi yoki ajratuvchi chiziq qismiga o'rnatishga ruxsat etiladi.",
        },
        paragraph2: {
          title:
            "Yoritish tirgovchilarda joylashgan tarmoqa ulanish orqali oʼrnatish",
          text: "Tungi vaqtda ishlovchi yoritish tarmog'ini yoqish uchun to'plamni tayanchga BPF bloklari bilan mahkamlash. Akkumulyator doimo uni tungi paytda quvvatlantirib turuvchi BPF24/220 bloki orqali ko'chani yoritish tarmog'iga ulangan bo'ladi.",
        },
      },
      tabpane2: {
        paragraph1: {
          title: "Shtativga o'rnatish",
          text: "Shtativ komplekti bilan akkumulyator uchun boksli toʼplam. Ushbu toʼplamga planshet yoki noutbuk kirishi mumkin. Datchik yoʼlning oʼtish qismi yonga oʼrnatiladi va akkumulyator boksiga ulanadi.",
        },
        paragraph2: {
          title: "Avtomobilga o'rnatish",
          text: "Datchik avtomobilning orqa oʼrindigʼi yoki yukxonasiga oʼrnatilishi mumkin va nafaqat mobil, balki koʼchma rejimda ham foydalansa boʼladi. Zarurat tugʼilganda toʼplamni operativ ravishda koʼchma rejimga oʼtkazish mumkin.",
        },
        paragraph3: {
          title: "«R-Avto» — avtomobil tomiga o'rnatish",
          text: `Avtomobil tomiga mahkamlash to'plami. To'plamga shuningdek, akkumulyator boksi va noutbuk kiradi. <a href="/">Batafsil</a>`,
        },
      },
      tabpane3: {
        paragraph1: {
          title: "Avtomobilga o'rnatish",
          text: `<div class="installation__tabPane-text">
            <strong>"C-Avto" to'plami.</strong> Xetchbek, mikroavtobus, yoltanlamas yoki universal ko'rinishdagi transport vositalariga majmuani o'rnatish to'plami. Datchik patrul avtomobilining yukxonasiga qa'tiy va ishonchli qaydni amalga oshirish maqsadida minishtativga qo'shimcha qayd etgichlar bilan o'rnatiladi. 
            Zarurat tug'ilganda to'plamni ko'chma rejimga o'tkazish va shtativga yo'lning qatnov qismining chetiga o'rnatish mumkin bo'ladi.
          </div>
          <div class="installation__tabPane-text">
            <strong>"D-Avto" to'plami.</strong> To'plamni yengil avtomobilning saloniga o'rnatish uchun qo'llaniladi. Datchik orqa o'rindiqqa maxsus kronshteyn, podgolovnik adapteri va ISOFIX standartining oddiy mahkamlagichi yordamida mahkamlanadi. To'plam Buyurtmachining avtomobili xususiyatlarini inobatga oladi (avtomobil markasi buyurtma vaqtida ko'rsatiladi).
          </div>
          <div class="installation__tabPane-text">
            Ikkala to'plam ham o'zida majmuani va operator planshetini quvvatlantirish uchun akkumulyator boksini qamrab olgan. Majmuani o'rnatish uchun qandaydir o'zgartirishlar va qo'shimchalar talab etilmaydi. <strong>Ikkala majmuada ham montaj ishlari 5 daqiqadan oshmaydi.</strong>
          </div>`,
        },
      },
    },
  },
  functions: {
    title: "QANDAY ASOSIY VAZIFALARI BOR?",
    items: {
      item1: {
        title: "АVTOMАTIK RАVIShDА FOTO VА VIDEO QАYD ETISH",
        info: `<ul>
          <li>
          Olti qatorgacha boʼlgan harakatlanish yoʼllarida bir vaqtning oʼzida ikkala yoʼnalishda YHQ buzilishini avtomatik foto va video qayddan oʼtkazish.
          </li>
          <li>
          Har bir yoʼl qatoriga alohida tezlik chegaralarini va "B", "C" va "D" toifadagi transport vositalar uchun alohida tezlik chegaralari qayd etish.
          </li>
          <li>
          Har bir qayd etilgan qoidabuzarlikning foto va video materiallarini avtomatik saqlash.
          </li>
          <li>
          GLONАSS/GPS moduli orqali majmuaning tizim vaqtini avtomatik ravishda sozlanishi.
          </li>
        </ul>`,
      },
      item2: {
        title: "DAVLAT RAQAM BELGILARINI BILIB OLISH",
        info: `<ul>
          <li>
          Dunyodagi ko'plab mamlakatlarining davlat raqam belgilarini, jumladan, ikki qatorli raqamlarini avtomatik ravishda aniqlash.
          </li>
          <li>U yoki boshqa davlatlarning DRBni tanib olish dastirini yoqish va o'chirish.
          </li>
          <li>Aniqlangan raqamlarni ma'lumotlar bazasidan operatorni xabardor qilish va raqamni ovozli eshittirish orqali tekshirib ko'rish.
          </li>
          <li>Raqami yo'q bo'lgan yoki o'qib bo'lmaydigan transport vositasini qidirish uchun "Ushlab olish" maxsus ish rejimini ishga tushirish.
          </li>
        </ul>`,
      },
      item3: {
        title: "TVning tasnifi",
        info: `<ul>
          <li>
          TV turining avtomatik aniqlanishi va ularning
            <strong>
            to'rtta asosiy toifalar bo'yicha tasniflanishi
            </strong>
            (yengil, yuk tashuvchi, avtobuslar, o'rtacha hajmdagi). 
          </li>
          <li>
          TVga tezlik chegarasi bo'yicha tegishli toifani avtomatik tartibda berish.
          </li>
          <li>
          TV harakatining berilgan toifa uchun alohida chiziqlar yoki butun yo'l bo'yicha harakatlanishini taqiqlanishini avtomatik nazoratga olish.
          </li>
        </ul>`,
      },
      item4: {
        title: "MA'LUMOTLARNI UZATISH",
        info: `<ul>
          <li>
          Ma'lumotlarni shifrlangan simli yoki simsiz aloqa yo'li orqali ma'lumotlarga ishlov berish markazi xizmatiga uzatish (3G/4G). 
          </li>
          <li>
          Asosiy kanal uzilib qolganda yoki to'xtab qolganda aloqaning zaxira kanallariga avtomatik ravishda ulanish (Wi-Fi, 4G) 
          </li>
          <li>
          Turli xil xizmatlarga majmuadan ma'lumotlarni bir vaqtning o'zida uzatish imkonining mavjudligi.
          </li>
        </ul>`,
      },
      item5: {
        title: "VIDEOKUZATUV",
        info: `<ul>
          <li>
          RTSP protokoli bo'yicha yuqori oqimli videooqimni uzatish.
          </li>
          <li>
          Berilgan oraliq vaqt bo'yicha videorolikni yuklash imkoniga ega uzluksiz videoyozuvni olib borish.
          </li>
        </ul>`,
      },
      item6: {
        title: "TUNGI VAQTDA ISHLASH",
        info: `<ul>
          <li>
          Tungi paytda ishlash uchun majmua ichkarisida infraqizil yoritqich oʼrnatilgan.
          </li>
          <li>
          Qoʼshimcha tashqi infrakizil yoritish uskunasi orqali tasvir boʼyicha transport vositasining rusumini kafolatli aniqlash.
          </li>
        </ul>`,
      },
    },
  },
  specifications: {
    title: "ТЕХNIK TASNIFLARI",
    tabs: {
      tab1: {
        title: "МЕТROLOGIK MEZONLARI (O'LCHAMLARI)",
        text: `<table>
          <tbody>
            <tr>
              <td>Nurlanishning ishchi chastotasi</td>
              <td>24,05 dan 24,25 Ggts ga qadar</td>
            </tr>
            <tr>
              <td>"O'lchanadigan tezliklarning diapazoni	 </td>
              <td>2-300 km/s"</td>
            </tr>
            <tr>
              <td>
              Tezlikni o'lchashning yo'l qo'yiladigan mutlaq xatolarining chegarasi
              </td>
              <td> ± 1,0 km/s</td>
            </tr>
            <tr>
              <td>
              Ichki shkala vaqtini xato sinxronlashtirishning yo'l qo'yilgan chegarasi UTC(SU) bilan
              </td>
              <td>  ± 5 mks</td>
            </tr>
          </tbody>
        </table>`,
      },
      tab2: {
        title: "ELEKTR QUVVATI VA KATTA HAJMLILAR",
        text: `<table>
          <tbody>
            <tr>
              <td>
               Ishlatiladigan quvvat (ijobiy haroratlarda)
              </td>
              <td>25 Vtdan ortiq bo'lmagan</td>
            </tr>
            <tr>
              <td>O'zgarmas tokning kuchlanishi</td>
              <td>10-16 V</td>
            </tr>
            <tr>
              <td>
              O'zgaruvchan tokning quvvatlantiruvchi moslama bilan quvvatlantirish kuchlanishi
              </td>
              <td>90-300 V</td>
            </tr>
            <tr>
              <td>Datchikning og'irligi</td>
              <td>6,0 kg dan ortiq bo'lmagan </td>
            </tr>
            <tr>
              <td>Katta hajmdagi o'lchamlar</td>
              <td>460×180×280 mm dan ko'p bo'lmagan</td>
            </tr>
          </tbody>
        </table>`,
      },
      tab3: {
        title: "ISH SHAROITLARI VA ISHONCHLILIGI",
        text: `<table>
          <tbody>
            <tr>
              <td>
              Atrof-muhitning harorati:
                <div></div>
                — standart ko'rinish:
                <br />
                —  аrktik ko'rinish: 
              </td>
              <td>
                <div></div>
                <br />
                <div></div>
                −40° C dan +60° С gacha
                <br />
                −55° С dan +60° С gacha
                <br />
              </td>
            </tr>
            <tr>
              <td>Havoning nisbiy namligi: simob ustunining </td>
              <td> 98% t°=+25° С ga teng</td>
            </tr>
            <tr>
              <td>Аtmosfera bosimi:  </td>
              <td> 60 dan 106,7 kPa gacha</td>
            </tr>
            <tr>
              <td>Rad etish uchun o'rtacha ishlob berish: </td>
              <td>35 000 soatdan kam emas</td>
            </tr>
            <tr>
              <td>Iste'moldan chiqargunga qadar o'rtacha xizmat qilish muddati:</td>
              <td> kamida 6 yil</td>
            </tr>
            <tr>
              <td>
              Qattiq buyumlar va suvning kirib qolishidan himoyalovchi qobiq darajasi: 
              </td>
              <td>IP65</td>
            </tr>
            <tr>
              <td>
              Tashqi mexanik zarbadan himoyalovchi qobiq darajasi:
              </td>
              <td>IK08</td>
            </tr>
            <tr>
              <td>
              "O'qotar quroldan himolash toifasi (zirhli sirtdan foydalangan holda):
              </td>
              <td>«Br2» и «С1»"</td>
            </tr>
          </tbody>
        </table>`,
      },
      tab4: {
        title: "AXBOROT XAVFSIZLIGI",
        lists: {
          list1:
            "Ma'lumotlar va o'rnatilgan POni ruxsatsiz o'zgartirishlardan himoya qilish.",
          list2:
            "Eksport qilinadigan ma'lumotlar ESPdan sertifikatlangan kalit moslamasi yordamida himoyalangan.",
          list3:
            "To'plam foydalanuvchisi tomonidan voqealar va harakatlarni qayd etish jurnalining yuritilishi.",
          list4:
            "Asbobga jismoniy ta'sir etish holatlari (zarba, tebratish) qayd etilgan holatlar haqida SMS va elektron pochta orqali ma'lumotlarni uzatish.",
        },
      },
    },
  },
  statistics: {
    items: {
      item1: {
        title: "ANIQLASH (BILIB OLISH) EHTIMOLI",
        progressbar: {
          progressbar1: "GRN orqali aniqlash (98%)",
          progressbar2:
            "Transport vositasini tasvir yordamida tasniflash (95%)",
        },
      },
      item2: {
        title: "XATO KO'RSATKICHLAR",
        progressbar: {
          progressbar1: "Tezlikni o'lchash (± 1,0 кm/s)",
          progressbar2: "UTC (± 5 mks) bilan vaqtni sinxronlash",
        },
      },
    },
  },
  guarantee: {
    title: "SERTIFIKALASH VA KAFOLAT",
    top: {
      item1: `Tekshiruvlar oralig'idagi muddat – <span>2 yil</span>`,
      item2: `Kafolat muddati – <span>2 yil</span>`,
    },
    bot: {
      item1: `Oʼzbekiston standartlashtirish, metrologiya va sertifikatlashtirish agentligining ("Oʼzstandart" agentligi) Oʼlchov vositalarining turini tasdiqlash O'T 0000590  02-2.0070 -son sertifikati.

      Oʼzbekiston Respublikasi Milliy sertifikatlashtirish tizimining muvofiqlik 13.12.2021y. UZ.SMT.01.354.72116889 -son sertifikati.
      Rossiya Federatsiyasining texnik tartibga solish va metrologiya bo'yicha Federal agentligining ОС.C.33.158.A №75141 ko'rinishidagi o'lchov vositalarini tasdiqlash to'g'risidagi guvohnomasi.
      `,
      item2: "YOIIning TC RU C-RU.ME83.B.00002/18. guvohnomasiga muvofiqligi.",
    },
  },
  faq: {
    title: "SAVOLLAR VA JAVOBLAR",
    subtitle: `"Cordon.Pro" to'plami bo'yicha eng ko'p beriladigan savollar.`,
    text: `"Cordon.Pro" to'plami "Yo'l-2019" xalqaro ko'rgazmasida taqdim etilganidan so'ng bizga jurnalistlardan ko'plab savollar kelib tusha boshladi. Bundan tashqari, matbuotda mazkur loyihaning texnik tomonini noaniq (ba'zida esa shunchaki noto'g'ri) izohlovchi ma'lumotlar chop etila boshlandi. Quyida "Cordon. PRO" to'plamining eng muhim xususiyatlarini izohlovchi javoblar va tushunchalar berilmoqda:`,
    accordion: {
      accordion1: {
        head: "To'plamni agar nazorat qilinayotgan yo'lning fotovideo qayd etish haqida ogohlantiruvchi belgi bo'lmagan hududida partul avtomobiliga o'rnatgan holda harakatda qo'llash qanchalik to'g'ri?",
        body: `<p>5.43 «Radar» belgili yoki 5.41 va 7.19  «Fotovideo qayd etish» belgilari O'zbekiston Respublikasining  YHQ (O'zbekiston Respublikasi Vazirlar Mahkamasining 2015-yil 24-dekabrdagi 370-son qarori)ga muvofiq statsionar to'plamlar qo'yilgan joylarning oldiga,"Cordon.Pro"  –   bajaradigan vazifasiga qarab o'rnatilishi lozim. Va undan statsionar rejimda foydalanilganda  ogohlantiruvchi belgisi o'rnatilishi kerak.
        </p>
        <p>
        U yoki bu avtomagistral yo'lidagi tezlikni avtomatik ravishda nazorat qilish to'g'risida ogohlantirish mavjud bo'lgan elektron tablo hozirgi kunda keng qo'llanilmoqda. Shuning uchun ham haydovchi nazorat ostida ekanligi haqida ogohlantiriladi. 
        </p>
        <p>
        Biroq sun'iy salohiyat imkoniyatlari o'sib bormoqda, ertaga bunday ogohlantirishlar shaharga kirish joylarida ham paydo bo'lishi mumkin. Nimasi ajablanarli? Biz siz ham yo'llarimizda ekstremal poygachilarning harakatlari jilovlanishiga qarshi emasligiga chin dildan umid qilib qolamiz. 
        </p>`,
      },
      accordion2: {
        head: `"Cordon.Pro" to'plamining yangiligi nimadan iborat, axir mashinaga o'rnatilgan va harakatlanayotgan paytdagi tezlikni o'lchovchi  asboblar allaqachon mavjud edi-ku?`,
        body: `<p>
        Mutlaqo to'g'ri, aynan Simikon kompaniyasi ilk bor 1999-yilda Rossiyada harakat paytida ishlovchi politsiya radarini ishlab chiqardi. Asbob "Iskra-1D" (Uchqun) deb nomlanib, RFning o'lchov vositalari reyestriga kiritildi hamda shaxsiy tezlik va qoidabuzar avtomobilning tezligini radiolokatsion tarzda o'lchanishini ta'minladi. Qaror ruxsatnoma oldi. Shunday qilib shaxsiy tezlikni o'lchovchi radiolokatsion algoritmlar 20 yildan ortiq vaqt mobaynida takomillashtirib borilmoqda. 
        </p>
        <p>
        Keyinchalik harakatlanayotganda ishlovchi radiolokatsion video qayd etuvchilar ("Iskra-Video", "Binar") paydo bo'ldi. Bunday asboblar nozir tomonidan boshqarilishi lozim bo'lgan: ular inson ishtirokisiz ishlay olmagan. Nozir qoidabuzarni to'xtatgan va unga sodir etgan qoidabuzarligining dalilini taqdim etgan, chunki oqim asbob ichida raqamli belgilarni tanib olish va qoidabuzarni aniqlash  imkoniga ega bo'lmagan. Aynan mana shu holat "inson omili"ni va "korrupsion tarkibni" istisno etilishini talab qilgan. Hozirda bu muammo hal qilingan. 
        </p>
        <p>
          <strong>
          "Endilikda ""Cordon.Pro"" bir qancha vazifalarni avtomatik ravishda bajaradi:"
          </strong>
        </p>
        <p>
          <strong>
          1. dopler radiolokator yordamida shaxsiy tezligini, shuningdek, tezlikni va uning nazariga tushgan barcha maqsadlarning nuqtalarini o'lchaydi;
          </strong>
        </p>
        <p>
          <strong>
          2. optik usulda raqam belgilarini tanib oladi va ularning joylashgan hududini aniqlaydi;
          </strong>
        </p>
        <p>
          <strong>
          3. qaysi raqamga qaysi tezlik tegishliligini xatosiz aniqlab beradi;
          </strong>
        </p>
        <p>
          <strong>
          4. har bir qoidabuzar bo'yicha fotomaterial va videorolikni shakllantiradi va ularni xotirasida saqlab qoladi;
          </strong>
        </p>
        <p>
          <strong>
          5. tezlikning qaysi chegarasidan buzilganligini hisoblash uchun transport vositasining toifasini aniqlaydi - tezlik axir hamma avtomobilda har xildir!
          </strong>
        </p>
        <p>
          <strong>
          6. Fotomateriallarni zudlik bilan 3G/4G/ Internetaloqa kanali orqali MIBMda jo'natadi;
          </strong>
        </p>
        <p>
          <strong>
          7. va asosiysi — insonning mazkur jarayonda qatnashishiga yo'l qo'ymaydi, bularning hammasi avtomatik tarzda sodir bo'ladi.
          </strong>
        </p>
        <p>
          <strong>
          Umuman olganda, Siz haqsiz, hech qanday yangilik yo'q, oddiy sun'iy salоhiyat xolos!..
          </strong>
        </p>`,
      },
      accordion3: {
        head: "Tezlik rejimi tez-tez o'zgartirilgan sharoitlarda nazorat qilinayotgan hududda qoidabuzarni isbotlovchi ma'lumot qanday ta'minlanadi? Axir GPS nuqtalarini aniq aniqlash hozircha unchalik ham kuchli emas.",
        body: `<p>
        "Cordon.Pro" majmuasini ishlatish uchun oldindan elektron karta tayyorlab qo'yilib, unda tezlikni cheklashning nuqta belgilari, aholi hududlarining chegaralari, ko'chalarning nomlanishi, uy raqamlari ko'rsatiladi. 
        </p>
        <p>
        Каrta majmuaning xotirasiga yuklanadi, so'ngra esa u aynan ushbu karta bo'ylab belgilanadi, ya'ni mazkur avtomobil qoidabuzarmi yoki yo'qligi. Aholi hududining ichida tezlikni cheklashning 70 km/s dan oshmasligiga, yashash hududlari va unga yaqin joylarda (uy-joy qurilishlari oralig'idagi hudud) 30 km/s dan ortiq bo'lmasligi lozim (O'zbekiston Respublikasining YHQ 78-moddasi). 
        Aholi yashamaydigan hududlarda quyidagi harakatlanishga (O'zbekiston Respublikasining YHQ 79-moddasi):                                                                                     
        —  ruxsat etilgan maksimal og'irligi 3,5 tonna bo'lgan yengil va yuk tashuvchi mashinalarga — 100 km/sdan tezlikni oshirmaslikka;
        — shaharlararo avtobuslar va mikroavtobuslarga — 90 km/s dan tezlikni oshirmaslikka;
        — boshqa ruxsat etilgan maksimal og'irligi 3,5 tonnadan ortiq bo'lgan avtobuslar, tirkama ulangan yengil avtomobillar, mototsikllar, yuk avtomobillariga — 80 km/s dan tezlikni oshirmaslikka;
        tirkama ulangan yuk tashish avtomobillariga — 70 km/s dan oshirmaslikka ruxsat etiladi.
        </p>
        <p>
        Tezlik rejimini buzish bilan bog'liq qoidabuzarliklar sodir etilgan taqdirda ma'muriy jazo maxsus moslamarning umumiy xato ko'rsatkichlarini, tezlik o'lchagichlar, va TV pidometrining soatiga 5 kilometr hisoblagan holda qayd etilgan tezlikni  inobatga olgan holda qo'llaniladi (O'zR MJK 128-3-moddasi).
        </p>
        <p>
        Sun'iy koordinatalarni aniqlashdagi xatolik  <strong>metreologik tarzda tasdiqlangan, sertifikatlangan va  ±4,5 m dan  </strong> ortiqni tashkil etmaydi. Biroq chegarani avtomatik aniqlash uchun "Cordon. Pro" majmuasida noaniqliklarni tuzatish cheklovi <strong> uch barobar oshirilgan (!) va  ±15 m ni tashkil etadi.</strong>
        </p>
        <p>
        Bu o'rnatilgan cheklovlarni noto'g'ri qo'llash bilan bog'liq tasodifiy xatolar bo'lmasligi uchun qilingan. Tezlikni cheklash belgisining geografik holati va mazkur belgini amal qilish hududining tugashi doimo ushbu tuzatishni inobatga olgan holda amalga oshiriladi.
        </p>`,
      },
      accordion4: {
        head: "Patrul avtomobili harakatlanayotganda1 km/s da tezlikni o'lchashdagi xato qanday ta'minlanadi?",
        body: `<p>
          <strong>±1 km/s dagi xatolik</strong> hozirgi kunda bu chegara emas. "Cordon.Pro" majmuasida o'lchash aniqligini tekshirish amalga oshirilgan metrologik moslama ±0,3 km/s xatoga ega. 
        </p>
        <p>
        Zamonaviy yuqori samara beruvchi protsessorlar matematik tahlilning batafsil ishlab chiqilgan algoritmlari bilan uyg'unlikda ko'p narsalarga qodir! Majmuaga tatbiq etilgan radiolokatsion o'lcham algoritmlari o'lchovlarni tez-tez va ko'p marotaba takrorlanishini, shuningdek, ularning ishonchliligini tekshirishni nazarda tutadi.
        </p>
        <p>
        Natijalar ko'plab tadqiqotlar bilan tasdiqlangan. Olingan tasniflar "O'lchov vositalarining turini tasdiqlash to'g'risida"gi 2019-yil 19-sentabrdagi  ОС.C.33.158.A  75141-sonli Guvohnoma bilan tasdiqlangan.
        </p>`,
      },
    },
  },
  footer: {
    address: {
      city: "100164, Toshkent shahri, Mirzo Ulug‘bek tumani",
      street: "m.Yalangach, 119a",
    },
    titles: {
      title1: "SOTUV BO'LIMI",
      title2: "TEXNIK QO'LLAB-QUVVATLASH",
      title3: "IJTIMOIY TARMOQLAR",
    },
  },
  buttons: {
    prev: "Oldingi",
    next: "Keyingi",
  },
  speedChange: {
    title: "TEZLIKNI O'LCHASH",
    text: {
      text1:
        "Cordon oilasiga kiruvchi to'plamlar bir qator ajoyib imkoniyatlar bilan ta'minlovchi ko'p maqsadli radarlar bilan jihozlangan:",
      text2:
        "To'plamni o'rnatish burchagiga nisbatan qat'iy talabning yo'qligi. Ko'p maqsadli radar bir zumda maqsad harakatining barcha o'lchamlarini o'lchab chiqadi (tezlik, masofa, azimut). Bir vaqtning o'zida nazorat hududidagi barcha maqsadlarning qayd etilishi.",
      text3:
        "Ko'p maqsadli radar bilan faqat bir dona TVni suratga olishning mavjudligi to'g'risidagi talab yo'qoldi. Nazorat hududida hattoki, bir necha TV mavjud bo'lganda ham o'lchangan tezliklarning qayd etilgan avtomobillarga aniq bog'liqligi kafolatlanadi.",
      text4: `Birinchi avlod fotoradarlari 1-2 chiziqdan ortig'ini qayd eta olmagan. "Cordon" turkumiga kiruvchi to'plamning bittasinining o'zi butun yo'lni nazorat qilishi va bu bilan avvalgi avlodning 6 tasining o'rnini bosishi mumkin. Nazorat hududining o'lchamlari  — to'plamning o'qidan burchagi bo'ylab ±15°, to'plamni o'rnatish joyidan masofasi bo'ylab 10 dan 80 m gachani tashkil etadi.`,
      text5: `Majmua hududlarda va istalgan boshqa "Cordon.Pro", "Cordon-M" yoki  "Cordon-Temp" majmualarida o'rtacha tezlikni aniqlash imkonini ta'minlaydi. O'rtacha tezlikni aniqlashning minimal masofasi bor-yo'g'i 200 metrni tashkil etadi, bunda o'lchanadigan tezliklarning diapazoni 1 dan 350 km/s ni tashkil etadi. `,
      text6:
        "Harakatning o'rtacha tezligini o'lchash to'g'ridan-to'g'ri usulda olib boriladi: tezlik bosib o'tilgan yo'lning sarf qilingan vaqtga uzunlik nisbati sifatida aniqlanadi. O'tgan yo'lni nazorat qilish zarurati tug'ilganda  ikki, uch va undan ortiq majmualarni zanjirda qo'llashga ruxsat etiladi. Hududdan o'tish vaqti kirish va chiqish vaqtini qayd etishdagi tafovut bailan aniqlanadi. Majmualarni o'rnatish nuqtalari o'rtasidagi masofa tekshirilgan o'lchov uzunligi (masofa) bilan o'lchanadi, bunda qayd etish vaqtida TVning ikkala majmuadagi radar koordinatlari ham hisobga olinadi.",
    },
  },
  workInMove: {
    title: "HARAKATLANAYOTGAN PAYTDA ISHLASH",
    text: {
      text1:
        "Cordon.Pro to'plamini avtomobilga harakatlanayotganda ishlashi uchun o'rnatish uning ajoyib xususiyati hisoblanadi.",
      text2:
        "Harakat vaqtida to'plam avtomatik ravishda nazorat hududida radiolokatsion usulda o'zining tezligini va transport vositasining tezligini o'lchaydi. Harakatda ishlayotganda ruxsat etilgan tezlik chegarasini to'g'ri aniqlash uchun to'plamning xotirasiga cheklangan tezlik hududlarining nuqtalari qayd etilgan oldindan tayyorlangan  elektron karta yuklanadi. Karta ma'mur tomonidan tahrir qilinadi.",
      text3:
        "Metrologik ishonchli ma'lumotlar asosida to'plam avtomatik ravishda tezlik rejimini buzuvchilarni aniqlaydi va qayd etilgan ma'lumotlarni zudlik bilan ma'muriy huquqbuzarliklarni avtomatik qayd etish Markaziga uzatadi.",
    },
  },
  classifier: {
    title: "TASNIFLAGICH",
    text: {
      text1:
        "Majmua qayd etilgan TVning toifasini avtomatik taniydi (yengil, yuk tashuvchi, avtobuslar). Tasniflash hech qanday ma'lumotlar bazasiga murojaat qilmagan holda amalga oshiriladi. Bu Yuk tashuvchi TVni harakatini taqiqlashning buzilishini qayd etish, shuningdek,  YHQ bo'yicha TV toifasiga tegishli tezlikni cheklashni belgilash imkonini beradi.",
      text2:
        "Avtotransport vositasining o'rnatilgan optik tasniflagichi ma'lumotlari asosida yo'l harakati qoidalariga muvofiq transport vositalarining (avtomobillar, yuk mashinalari, avtobuslar) turiga mos keladigan tezlik chegarasini avtomatik ravishda to'g'ri belgilash. Avtotransport vositalari haydovchilari tomonidan O‘zbekiston YHQ 91-bandi buzilishini avtomatik kvalifikatsiya qilish funksiyasi. Avtotransport vositalarining o‘rnatilgan optik klassifikatori ma’lumotlari asosida yuk mashinalarining harakatlanishini taqiqlash (O‘zbekiston YHQ 3.4 belgisi) buzilishini avtomatik tarzda aniqlash.",
    },
  },
  wanted: {
    title: "QIDIRUV",
    text: {
      text1:
        "O'zbekiston Respublikasi Vazirlar Mahkamasining 2007.27.06 dagi 131-son Qaroriga muvofiq, ko'plab mamlakatlarning raqamli belgilarini, jumladan ikki qatorli, uch qatorli DRBni avtomatik tarzda aniqlash mumkin. DRBni avtomatik aniqlay olmaslik odatda uni operator tomonidan ishonchli ravishda aniqlanmaganligini anglatadi.",
      text2:
        "Cordon turkumiga mansub majmualar Uzoq Sharqda Xitoyning transport vositalarining harakatlanishini (ierogliflarni tanib olishda), shuningdek, Eron (arab yozuvi) va Koreya kabi mamlakatlarda nazorat qilish maqsadida muvaffaqiyatli qo'llanilmoqda.",
      text3: `Oʼzbekiston Respublikasi Vazirlar Mahkamasining 2021 y. 21 apreldagi 232-son Qaroriga, DavSt R 57144-2016V 5.2. band talablariga muvofiq "Cordon" oilasiga kiruvchi o'lchov asboblari 4 tadan kam bo'lmagan xorijiy davlatning DRBni so'rovnoma bo'yicha va Buyurtmachining ro'yxatiga ko'ra tanib olinishini ta'minlaydi. Buyurtmachi bilan kelishilgan shartnomaning shartlariga, zavoddan tushirilgan majmualarga qarab, u yoki boshqa xorijiy davlatning DRBni aniqlash vazifasi kiritiladi. Shuningdek, unda tijorat asosida boshqa davlatlarning raqamlarini tanib olish tizimini qayta sozlash yoki DRBni tanib olish uchun mamlakatlar sonini ko'paytirish  imkoni mavjud.`,
      text4:
        "Neyron tarmog'ining texnologiyasi GPNning yangi formatlariga tezda o'qitishni ta'minlaydi.",
      text5:
        "Majmua asbobning qidiruv bazasi xotirasiga yuklanishi bo'yicha topilgan transport vositalari to'g'risidagi ma'lumotni, shuningdek, kartadagi videoroliknining operativ yozuvini SD xotiraga  olish imkonini beradi. Majmuaga ulashda operator bazalarda topilgan raqam belgilarini ovoz chiqarib aytish vazifasidan foydalanishi mumkin.",
      text6: `"Cordon" oilasiga kiruvchi barcha to'plamlar TVni qidirish va ularni raqam belgilarini aniqlab bo'lmaydigan yoki ular yo'q bo'lgan TVni qayd etishga mo'ljallangan maxsus "Ushlab olish"  rejimiga ega. Bizning qurilmamizdan foydalanuvchilarning taqrizlariga ko'ra, bunday imkoniyat opertiv*qidiruv tadbirlarini olib borishda bebaho hisoblanadi.`,
      text7:
        "TCP/XML ma'lumotlar uzatish protokoli markazlashtirilgan avtomobil qidiruv tizimlari bilan komplekslarni birlashtirish imkonini beradi.",
    },
  },
  settings: {
    title: "SOZLASH VA O‘RNATISH",
    text: {
      text1: `"Cordon.Pro" kompleksi statsionar tayanchga o'rnatilishi mumkin - aylanma kronshteyn yordamida. Kronshteyn burchaklar yoki o'rnatish lentasi bilan tayanchga o'rnatilishi mumkin. `,
      text2:
        "Konfiguratsiya veb-interfeys orqali masofadan turib, yoki o'rnatish joyida noutbuk (yoki planshet) yordamida amalga oshiriladi. Dastlabki sozlash uchun talab qilinadigan vaqtni sezilarli darajada qisqartirdi. Interfeysda sozlama sifatini aks ettiruvchi va sensorning holatini to'g'rilash bo'yicha tavsiyalar beruvchi interaktiv ko'rsatkichlardan foydalaniladi. Bundan tashqari, majmua to'g'ri yo'naltirilgan va o'z-o'zidan tashxis qo'yilgan taqdirdagina yo'l harakati qoidalari buzilishini foto-video qayd etishni boshlaydi.",
      text3:
        "Elektr ta'minoti tarmoqlariga ulanish uchun turli xil variantlar mavjud, jumladan yorug'lik tarmog'iga ulanish va batareyadan avtonom ishlash.",
    },
  },
  rcar: {
    title: "R-AVTO",
    text: "Avtomobilning yukxona reylingiga o'rnatilgan mahkamlash to'plami majmuani mobil rejimda ishlatayorganda majmuani boshqa joyga operativ ko'chirish imkonini beradi. Shunday qilib, majmua yo'lning tanlangan hududida, jumladan, standart shtativ qo'llashga ruxsat berilmaydigan sharoitlarda operativ tarzda yoyish mumkin. Kronshteyn yukxona reylingiga ega har qanday avtomobilga o'rnatilishi mumkin va datchikning burchak egilishi va burilishiga qulaylikni ta'minlaydi. ",
  },
  protection: {
    title: "ZIRHLI HIMOYA",
    text: `Zirhli po'latdan tayyorlangan g'ilof  "Cordon. Pro" to'plami uchun maxsus ishlab chiqilgan. O'qotar quroldan himoyani ta'minlaydi, "Br2" (SPS va TT to'pponchalari) va "C1" (ruxli o'qqa ega ov miltig'i) toifalari bo'yicha o'qqa chidamlilikdan  sertifikatlangan.  G'ilof ikkita ko'rinishda tayyorlanishi mumkin: zirhli oynali va oynasiz. Zirhli himoya standart kronshteyn yordamida tayanchga o'rnatilgan to'plamga mahkamlanadi. O'rnatish uchun ikki nafar kishi kerak bo'ladi.`,
  },
  base: {
    title: "ISBOTLOVCHI (ASOSLOVCHI) BAZA",
    text: "To'plam nazorat qilinayotgan hududda YHQ buzilishini avtomatik qayd etadi va sharhli suratlar, vizual farqlovchi raqam belgisi, har bir qoidabuzarlikning videoyozuvi kabi har bir maqsad uchun zarur asosli dalillarni shakllantiradi. Bir vaqtning o'zida to'plam nazorat qilayotgan hududda voqealarni uzluksiz videoyozuvi olib boriladi. TVning tungi vaqtdagi fotovideo qaydi maxsus o'rnatilgan infraqizil chiroqlar yordamida amalga oshiriladi, tasvir sifati shuningdek avtomobil markasini ham aniqlash imkonini beradi.",
  },
  copyright: {
    copyright: {
      copyright1: "Mualliflik huquqi",
      copyright2: "MChJga tegishli",
    },
    rights: "Barcha huquqlar himoyalangan",
  },
  cross: {
    link: {
      link1: "CORDON PRO M",
      link2: "CORDON CROSS",
    },
    title: {
      top: "CHORRAHALARNING AVTOMATIK NAZORATI",
      bot: "CORDON-CROSS",
    },
    subtitle: "«CORDON-CROSS» video qayd qilishli o'lchov kompleksi",
    cordon: {
      subtitle:
        "«CORDON-CROSS» stasionar kompleksi chorrahalarda yo'l harakat qoidalari (YHQ) buzilishini nazorat qilish va foto-videoli qaydlar ma'lumotlarini qayta ishlash markaziga (MQIM) yuborish uchun mo'ljallangan. Barcha raqamlarni avtomatik aniqlash va turli hil ma'lumotlar bazasidan transport vositalarini tekshirishni ta'minlaydi.",
    },
    slider: {
      slider1: {
        title: "TUNGI ISH",
        subtitle: "Avtomatik tungi rejimga o'tish",
      },
      slider2: {
        title: "SVELOFORNI ANGILASH",
        subtitle:
          "Video ramkalar va / yoki yo'l boshqaruvchisiga simli ulanish yordamida tanib olish.",
      },
      slider3: {
        title: "(radar bilan). TEZLIKNI 300 KM/SOATGA O'LCHISHI",
        subtitle:
          "2 dan 300 km / soat gacha bo'lgan tezlikni o'lchash (radar mavjudligida).",
      },
    },
    fixation: {
      title: "QAYD ETILADIGAN YHQ BUZILISH HOLATLARI",
      subtitle:
        "YHQ qayd etilgan ro'yhat, chorrahalarda kameralarning joylashuv sxemasi va kompleksning to'liq to'plamiga bog'liq",
      items: {
        item1: "Svetoforning taqiqlovchi ishorasiga o'tish",
        item2:
          "To'xtash chizig'idan oldin sfetoforning taqiqlovchi to'xtash talabiga rioya qilmaslik",
        item3: "Ajratuvchi chiziq bo'ylab harakat",
        item4: "Qarama-qarshi chiziqqa chiqib ketish",
        item5: "Qatnov qismining chetidan transport vositalarining harakati",
        item6: "Velosiped, piyodalar yo'lkasi yoki trotuarlardan harakatlanish",
        item7:
          "Yuk tashuvchi transport vositalarining harakatlanishi taqiqini buzish (shu jumladan alohida chiziqlar bo'ylab)",
        item8:
          "Harakatlanayotganda transport vositalari orasida minimal masofani buzish",
        item9:
          "Haydovchi yoki yo'lovchi tomonidan xavfsizlik kamari taqilmagan holda harakatlanish",
        item10: "Belgilangan harakat tezligidan oshib ketish",
        item11:
          "Bir taraflama harakatlanish qismida qarama-qarshi yo'nalishda harakatlanish",
        item12: "Burilish qoidalarini buzish",
        item13:
          "Marshrut transport vositalari uchun mo'ljallangan chiziqdan harakatlanish",
        item14:
          "Bo'ylanma yotiq chiziqlar bo'ylab transport vositalarining harakatlanish yo'nalishini o'zgartirish",
        item15:
          "Yo'l belgilari yoki chiziqlar bilan  bilan belgilab qo'yilgan talablarni buzish",
        item16:
          "«Vafel chizig'i»da to'xtash va tirbandlik vaqtida chorrahaga chiqib ketish",
        item17:
          "Yo'lning maxsus kengaytirilgan qismida o'rta tezlikni oshirish",
        item18:
          "Faralar yoqilmagan holatda harakatlanish (oldi chiroqlar/kunduzgi signal chiroqlar)",
      },
    },
    compound: {
      title: "KOMPLEKS TARKIBI",
      subtitle:
        "Kompleks bir yoki bir nechta video datchiklardan (aniqlovchi kameralar) IQ- projektorlar, kuzatuv kameralari va ma'lumotlarni qayta ishlash blokidan («Kross» MQIB) iborat.",
      text: {
        text1:
          "Modulliligi tufayli kompleks o'ziga xos noyob masshtablikka ega. Tezlik rejimini boshqarish uchun kompleksning bir yoki bir nechta video datchiklari radar modullari bilan jihozlanishi mumkin.",
        text2: `<p>Talabga ko'ra, videodatchiklar qo'shimcha ravishda quyidagilar bilan jihozlanishi mumkin: 
        - oynani avtomatik tozalash tizimlari; 
        - masofadan boshqariladigan motorli egilish va burilish moslamalari.</p>`,
        text3:
          "Barcha kameralar optik interfeyslarga ega va optik tolali aloqa liniyalari (OTAL) yordamida axborotni qayta ishlash blokiga ulangan.",
      },
    },
    scheme: {
      title: "JOYLASHUV SXEMASI VA ISH TARTIBI",
      subtitle:
        "Video datchiklar va kuzatuv kameralari bir necha metr balandlikda stasionar moslamalarga (yorug'lik minorasi, tayanchlari va boshqalar) o'rnatiladi va 220V tarmoqqa ulanadi.",
      text: {
        text1: "Nazorat zonalari va chorrahada jihozlarni joylashtirish misoli",
        text2: "Belgilar: VD - Videodatchik, OK - Kuzatish kamerasi",
        text3:
          "Har yo'nalishda ikki chiziqlarga va ikki tomonlama harakatga ega odatiy ikki yo'l chorrahasini to'liq nazorat qilish uchun 4 videodatchik, 4 kuzatuv kamerasi va bitta ma'lumotlarni qayta ishlash bloki kerak bo'ladi. Aniq soni va jihozlarning tarkibi har bir loyihada chorrahaning konfigurasiyasidan kelib chiqqan holda aniqlanadi.",
        text4:
          "Kompleks ishining parametrlarini o'rnatish kompyuter yoki planshet yordamida o'rnatilgan Web-interfeys orqali amalga oshiriladi. Svetofor signalini aniqlash (qo'shimcha seksiyalar) avtomatik ravishda kuzatuv kameradan yoki birlashgan usulda svetofor nazoratiga ulangan holda amalga oshiriladi va bu yuqori ishonchli va xatolardan himoyalanish darajasini ta'minlaydi.",
        text5:
          "«Cross» ma'lumotlarni qayta ishlash bloki videodatchiklardan olingan ma'lumotlar orqali ro'yxatdan o'tkazish davlat raqamlarini, kadrdagi harakat tahlilini va avtomatik svetofor signallarini aniqlaydi. Ma'lumotlarni qayta ishlash jarayonida kompleks, chorrahaning umumiy suratini va qoidabuzarning kattalashtirilgan rasmini yoki belgilangan oraliqda amalga oshirilgan yo'l harakati holati aks etgan fotolarni o'z ichiga olgan qoidabuzarning suratlaridan iborat kollajni hosil qiladi. Davlat raqamlari haqidagi aniqlangan ma'lumotlar himoyalangan aloqa kanallari orqali ma'lumotlarni qayta ishlash markaziga qoidabuzarliklar ustida ishlash uchun yuboriladi.",
        text6:
          "Kompleks, ishlash jarayonida chorrahadan o'tgan barcha transport vositalarini qayd etadi. Aniqlanmagan yoki davlat raqamlari noma'lum bo'lgan transport vositalarini qidiruv maqsadlari uchun qayd etish imkoni ko'zda tutilgan.",
      },
    },
    functions: {
      title: "ASOSIY FUNKSIYALAR VA XUSUSIYATLARI",
      items: {
        item1: {
          title: "AVTOMATIK FOTO-VIDEO QAYD ETISH",
          info: `<ul>
          <li>
          "Barcha transport vositalarining chorrahadagi yo'l chiziqlaridagi o'rnini avtomatik tarzda aniqlash va yo'l harakati qoidalari buzilishining foto-videoga qayd etish. 
          </li>
          <li>
          Oltitagacha bo'lgan harakat chiziqlarini bitta kamera orqali nazorat qilish. 
          </li>
          <li>
          Svetofor signallarini aniqlash - videokadrlar orqali yoki yo'l nazoratiga simli ulanish orqali aniqlash. 
          </li>
          <li>
          2dan 300km/soatgacha (radar mavjudligida).
          </li>
          <li>Qayd etilgan har bir qoidabuzarlik bo'yicha fotomateriallar va vodeolavhalarning avtomatik saqlanib qolinishi. 
          </li>
          <li>
          Kompleks tizimli vaqtni avtomatik to'g'rilash bilan GLONASS/GPS moduli.
          </li>
        </ul>`,
        },
        item2: {
          title: "DAVLAT RAQAMLARINI ANIQLASH VA transport vositalari QIDIRUVI",
          info: `<ul>
            <li>
            "Yangi formatlarni tez o'rganish va dunyoning ko'plab mamlakatlari davlat raqamlarini, jumladan, ikki qatorli raqamlarni aniqlashni ta'minlaydigan, neyron tarmoqlar texnologiyasi asosida davlat ro'yhatga olish raqamlarini avtomatik aniqlash.
            </li>
            <li>
            U yoki bu mamlakatlarning davlat ro'yhatga olish raqamlarini aniqlashni o'chirish yoki yoqish imkoni. 
            </li>
            <li>
            Ma'lumotlar bazasiga ko'ra aniqlangan raqamlarni tekshirib, operatorni xabordor qilish hamda raqamni ovozli e'lon qilish.
            </li>
            <li>
            Ko'rinmaydigan yoki davlat raqami mavjud bo'lmagan transport vositalarini qidirush uchun maxsus «Ushlash» rejimi
            </li>
          </ul>`,
        },
        item3: {
          title: "Transport vositalari TASNIFI",
          info: `<ul>
            <li>
            "Avtotransport vositalarining turini avtomatik aniqlash va ularni to'rtta asosiy toifa bo'yicha (yengil va yuk mashinalari, avtobuslar, o'rta o'lchamli transport vositalari) tasniflash.
            </li>
            <li>
            Avtotransport vositalarining rusumi va modeli bo'yicha avtomatik aniqlash (so'rov bo'yicha).
            </li>
            <li>
            Avtotransport vositalari toifasiga ko'ra, YHQga muvofiq avtomatik tarzda tezlik chegarasini o'zlashtirish. 
            </li>
            <li>
            Transport vositalarining berilgan toifada (yuk, avtobus va boshqalar) alohida chiziqlar yoki umumiy yo'l bo'yicha harakatlanishiga qo'yilgan taqiqni avtomatik nazorat qilish.
            </li>
          </ul>`,
        },
        item4: {
          title: "VIDEOKUZATUV",
          info: `<ul>
            <li>
            RTSP protokoli orqali yuqori aniqlikdagi video oqimini translyasiya qilish. 
            </li>
            <li>
            Ma'lum bir davr oralig'idagi videoni yuklab olish imkoniyati bilan uzluksiz video yozish.
            </li>
          </ul>`,
        },
        item5: {
          title: "MA'LUMOT UZATISH",
          info: `<ul>
            <li>
            "Ma'lumotlarni shifrlangan simli yoki simsiz aloqa kanallari (3G/4G) orqali ma'lumotlar markazi serviriga yuborish. 
            </li>
            <li>
            Asosiy kanal javob bermay yoki ishlamay qolganda (Wi-Fi, 4G) avtomatik ravishda zahira kanallariga ulanish. 
O'zbekiston Respublikasi IIV tizimi bilan moslik.
            </li>
            <li>
            Kompleksdan parallel ravishda turli hil serverlarga ma'lumotlarni uzatish imkoni.
            </li>
          </ul>`,
        },
        item6: {
          title: "HIMOYA VA XAVFSIZLIK",
          info: `<ul>
            <li>
            Ma'lumotlar va o'rnatilgan dasturiy ta'minotni ruxsat etilmagan o'zgarishlardan himoyalash. 
            </li>
            <li>
            Eksport qilinadigan ma'lumotlar elektron-raqamli imzo bilan himoyalangan. 
            </li>
            <li>
            Kompleks foydalanuvchisi harakatlari va hodisalari jurnalini yuritish."
            </li>
          </ul>`,
        },
        item7: {
          title: "TUNGI VAQTDA ISHLASH",
          info: `<ul>
            <li>
            Tungi vaqtda ishlash uchun infraqizil yoritgichlar. 
            </li>
            <li>
            Qo'shimcha IQ-projektorlarni o'rnatish imkoni.
          </li>
          </ul>`,
        },
        item8: {
          title: "STATISTIKA",
          info: `<ul>
            <li>
            Chorrahada transport oqimi intensivligi haqidagi ma'lumotlar statistikasini to'plash. 
            </li>
            <li>
            Barcha chorrahada yoki tanlangan kameralar orqali YHQ buzilishi turlari, transport vositalari toifalari hamda qayd etilgan yo'l harakati qoidalari bo'yicha tahlil.
          </li>
          </ul>`,
        },
      },
    },
    guarantee: {
      bot1: "Yevroosiyo iqtisodiy ittifoqining muvofiqlik sertifikati№ YeAES RU C-RU.ME83.B.00005/19. 28.08.2024 yilgacha amal qilish muddati.",
      bot2: "O'rtacha tezlikni o'lchash metodikasi attestasiyasi haqidagi guvohnoma №1084/253-(RA.RU.310494)-2019.",
    },
  },
};
