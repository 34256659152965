import "./App.css";
import Main from "./pages/main/Main";
import "./assets/scss/_styles.scss";
import { BrowserRouter, Route } from "react-router-dom";
import SpeedChange from "./pages/SpeedChange";
import WorkInMove from "./pages/WorkInMove";
import Classifier from "./pages/Classifier";
import Wanted from "./pages/Wanted";
import Settings from "./pages/Settings";
import RCar from "./pages/RCar";
import Protection from "./pages/Protection";
import Base from "./pages/Base";
import "./i18n";
import CordonCross from "./pages/CordonCross";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route exact path="/" render={(props) => <Main />} />
        <Route exact path="/CordonCross" component={CordonCross} />
        <Route path="/SpeedChange" component={SpeedChange} />
        <Route path="/WorkInMove" component={WorkInMove} />
        <Route path="/Classifier" component={Classifier} />
        <Route path="/Wanted" component={Wanted} />
        <Route path="/Settings" component={Settings} />
        <Route path="/RCar" component={RCar} />
        <Route path="/Protection" component={Protection} />
        <Route path="/Base" component={Base} />
      </BrowserRouter>
    </div>
  );
}

export default App;
