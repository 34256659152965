import i18n from "i18next";
import transEn from "src/locales/en";
import transRu from "src/locales/ru";
import transUz from "src/locales/uz";
import transOz from "src/locales/o'z";
import { initReactI18next } from "react-i18next";

const lang = localStorage.getItem("lang");

i18n.on("languageChanged", (lng) => {
  localStorage.setItem("lang", lng);
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: transEn,
      },
      ru: {
        translation: transRu,
      },
      уз: {
        translation: transUz,
      },
      "o'z": {
        translation: transOz,
      },
    },
    lng: lang || "o'z",
    fallbackLng: "o'z",
    detection: {
      order: ["queryString", "cookie", "localStorage"],
      cache: ["cookie"],
    },

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
