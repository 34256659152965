//eslint-disable-next-line
export default {
  header: {
    purpose: "ҚЎЛЛАНИЛИШИ",
    workPrinciples: "ИШЛАШ ТАМОЙИЛИ (ТАРТИБИ)",
    installation: "ЎРНАТИЛИШИ",
    functions: "ВАЗИФАЛАРИ",
    specifications: "ТАВСИФИ (ХУСУСИЯТИ)",
    questions: "САВОЛЛАР",
    contacts: "МУРОЖААТ УЧУН",
  },
  video: {
    title: {
      top: "Ҳаракат пайтида ўлчаш",
      bottom: "кордон.про",
    },
    subtitle: "ЙҲҚ бузганликни фото ва видео қайд этувчи янги универсал мажмуа",
    button: "БАТАФСИЛ БИЛИШ",
  },
  cordon: {
    title: "КОРДОН.ПРО",
    subtitle: "ТЕЗ ВА ЎРТАЧА ТЕЗЛИКНИ НАЗОРАТ ҚИЛИШ БЎЙИЧА",
    info: `Мажмуа турли хил режимларда (мобил, ҳаракатланаётганда, стационар ҳолатда) ЙҲҚ бузилишини автоматик тарзда фото ва видео қайд этиш ва реал вақт режимида қайд этилган қоидабузарликлар тўғрисидаги маълумотларни "Маъмурий амалиёт" КАТ тизимининг  серверига узатишга мўлжалланган.`,
  },
  qualities: {
    title: "Асосий хусусиятлари:",
    list: {
      speed:
        "Тезликни автоматик равишда ўлчаш ва ЙҲҚ бузилишини фото ва видео қайд этиш.",
      install:
        "Мажмуани автомобилга ўрнатиб, ҳаракатланаётган ҳолда ишлаш имконияти мавжудлиги.",
      switch:
        "Мажмуа оператив тарзда стационар, ҳаракатланаётган ва мобил режимига ўтказиш имкониятлари мавжудлиги.",
      recognition:
        "Бир вақтнинг ўзида транспорт воситаларнинг турини таснифлаш, давлат рақам белгиларни аниқлаш ва юқланган маълумотлар базаси орқали текшириш",
    },
  },
  slider: {
    slide1: {
      title: "ҲАРАКАТЛАНАЁТГАН ҲОЛДА ИШЛАЙДИ",
      subtitle:
        "Мажмуа автомобил кўринишини ўзгармаган ҳолатида ўрнатилади ва ҳаракатланаётган ҳолда ишлайди.",
    },
    slide2: {
      title: "УНИВЕРСИАЛ",
      subtitle: "Стационар, кўчиб юрувчи ва мобил режимларида ишлайди",
    },
    slide3: {
      title: "ИШОНЧЛИ",
      subtitle: `Вақт синовидан ўтган "КОРДОН", "КОРДОН-М" мажмуалари негизида ишлаб чиқилган`,
    },
  },
  fixation: {
    title: "НИМАНИ ҚАЙД ЭТАДИ?",
    subtitle:
      "Тўплам томонидан ЙҲҚ бузилишининг қайд этилиши. У ёки бошқа қоидабузарликларни қайд этиш имконининг мавжудлиги тўпламнинг танланган жойи ва ишлаш режимига боғлиқ бўлади.",
    items: {
      item1: "Белгиланган ҳаракат тезлигининг оширилиши (тез ва/ёки ўртача)",
      item2: "Қарама-қарши ҳаракат учун мўлжалланган чизиқни кесиб ўтиш",
      item3: "Қарама-қарши йўналишдаги трамвай йўлини кесиб ўтиш.",
      item4:
        "Бир томонлама ҳаракатли йўл бўйлаб қарама-қарши йўналишда ҳаракатланиш",
      item5: "Ажратувчи чизиқ бўйича ҳаракатланиш.",
      item6: "Жамоат транспортига мўлжалланган чизиқ бўйлаб ҳаракатланиш.",
      item7: "Йўл чети бўйлаб ҳаракат",
      item8: "Велосипед ёки йўловчи, ёхуд йўлакча бўйлаб ҳаракатланиш.",
      item9: "Йўлга туриб олишда узун чизиқ белгисини кесиб ўтиш.",
      item10: "Юк ташувчи ТВ ҳаракатланиши тақиқланишининг бузилиши",
      item11:
        "Юк ташувчи транспорт воситасининг кейинчалик иккинчи йўл бўйлаб ҳаракатланиши.",
      item12: "ТЎХТАШ белгисини кесиб ўтишда тўхтамасдан ўтиб кетиш.",
    },
  },
  work: {
    title: "БУ ҚАНДАЙ ИШЛАЙДИ?",
    subtitle: `Суратга ва тасвирга олишни автоматик равишда қайд этувчи янги универсал "Кордон.про" тўплами ажойиб имкониятларга эга.`,
    links: {
      link1: "Тезликни ўлчаш",
      link2: "Монтаж қилиш ва созлаш",
      link3: "Ҳаракатланиш пайтида қандай ишлайди",
      link4: "Автомобил томида",
      link5: "Классификатор нима учун керак",
      link6: "Ўқ отувчи қуролдан ҳимоя",
      link7: "ТВни база бўйича қидириш",
      link8: "Қоидабузарликларга мисоллар",
    },
    previews: {
      preview: "Тез ва ўртача тезликни автоматик назорат ",
      preview1: {
        top: "ҲАРАКАТЛАНАЁТГАН ПАЙТДА ИШЛАШ",
        bot: "Aвтомобилга ўзгартиришларсиз ўрнатиш",
      },
      preview2: {
        top: "КЛАССИФИКАТОР",
        bot: "ТВни категориясини расм бўйча аниқлаш",
      },
      preview3: {
        top: "қидириш",
        bot: "Юклаб олинган маълумотлар базалари ёрдамида рақамларни аниқлаш ва автомобилни қидириш",
      },
      preview4: {
        top: "МОНТАЖ ҚИЛИШ ВА СОЗЛАШ",
        bot: "Осон ва тез созлаш",
      },
      preview5: {
        top: "Р-АВТО",
        bot: "Машинанинг томида мажмуани ўрнатиш",
      },
      preview6: {
        top: "зирҳли ҳимоя",
        bot: "Ўқотар қуроллардан ҳимоя қилиш",
      },
      preview7: {
        top: "ДАЛИЛЛАР БАЗАСИ",
        bot: "Рўйхатга олинган қоидабузарликларга мисоллар",
      },
    },
  },
  installation: {
    title: "ҚАЕРГА ЎРНАТИШ МУМКИН?",
    subtitle: "Мажмуа мобил, кўчма ва стационар режимларда ишлаши мумкин.",
    text: "Мажмуа олдида қўйилган вазифаларга қараб ва техник имкониятлардан келиб чиқиб бир неча усулларда ўрнатилиши ва қувват тармоққа уланиши мумкин. Буюртмачи буюртма беришда зарур монтаж-эксплуатация тўпламини танлаши мумкин.",
    tabs: {
      tab1: "СТАЦИОНАР РЕЖИМ",
      tab2: "КЎЧМА РЕЖИМ",
      tab3: "МОБИЛ РЕЖИМИ",
    },
    tabpanes: {
      tabpane1: {
        paragraph1: {
          title: "220В тармоғига улаш орқали ўрнатиш.",
          text: "220В тармоғига улаш билан тирговчиларга маҳкамлаш тўплами. Йўлнинг ўтиш қисми билан ёнма-ён бўлган 10 метргача бўлган баландликка ўрнатилади. Шунингдек, йўлнинг ўтиш қисми ёки ажратувчи чизиқ қисмига ўрнатишга рухсат этилади.",
        },
        paragraph2: {
          title: "Ёритиш тирговчиларда жойлашган тармоқа уланиш орқали ўрнатиш",
          text: "Тунги вақтда ишловчи ёритиш тармоғини ёқиш учун тўпламни таянчга БПФ блоклари билан маҳкамлаш. Аккумулятор доимо уни тунги пайтда қувватлантириб турувчи БПФ24/220 блоки орқали кўчани ёритиш тармоғига уланган бўлади.",
        },
      },
      tabpane2: {
        paragraph1: {
          title: "Штативга ўрнатиш",
          text: "Штатив комплекти билан аккумулятор учун боксли тўплам. Ушбу тўпламга планшет ёки ноутбук кириши мумкин. Датчик йўлнинг ўтиш қисми ёнга ўрнатилади ва аккумулятор боксига уланади.",
        },
        paragraph2: {
          title: "Автомобилга ўрнатиш",
          text: "Датчик автомобилнинг орқа ўриндиғи ёки юкхонасига ўрнатилиши мумкин ва нафақат мобил, балки кўчма режимда ҳам фойдаланса бўлади. Зарурат туғилганда тўпламни оператив равишда кўчма режимга ўтказиш мумкин.",
        },
        paragraph3: {
          title: "«Р-Авто» — автомобиль томига ўрнатиш",
          text: `Автомобиль томига маҳкамлаш тўплами. Тўпламга шунингдек, аккумулятор бокси ва ноутбук киради. <a href="/">Батафсил</a>`,
        },
      },
      tabpane3: {
        paragraph1: {
          title: "Автомобилга ўрнатиш",
          text: `<div class="installation__tabPane-text">
          <strong>«С-Авто» тўплами.</strong>
           Хетчбек, микроавтобус, йўлтанламас ёки универсал кўринишдаги транспорт воситаларига мажмуани ўрнатиш тўплами. Датчик патрул автомобилининг юкхонасига қаътий ва ишончли қайдни амалга ошириш мақсадида миништативга қўшимча қайд этгичлар билан ўрнатилади. Зарурат туғилганда тўпламни кўчма режимга ўтказиш ва штативга йўлнинг қатнов қисмининг четига ўрнатиш мумкин бўлади."
        </div>
        <div class="installation__tabPane-text">
          <strong>«Д-Авто» тўплами. </strong>
          Тўпламни енгил автомобилнинг салонига ўрнатиш учун қўлланилади. Датчик орқа ўриндиққа махсус кронштейн, подголовник адаптери ва ИСОФИХ стандартининг оддий маҳкамлагичи ёрдамида маҳкамланади. Тўплам Буюртмачининг автомобили хусусиятларини инобатга олади (автомобиль маркаси буюртма вақтида кўрсатилади).
        </div>
        <div class="installation__tabPane-text">
        "Иккала тўплам ҳам ўзида мажмуани ва оператор планшетини қувватлантириш учун аккумулятор боксини қамраб олган. Мажмуани ўрнатиш учун қандайдир ўзгартиришлар ва қўшимчалар талаб этилмайди.
          <strong>
          Иккала мажмуада ҳам монтаж ишлари 5 дақиқадан ошмайди."
          </strong>
        </div>`,
        },
      },
    },
  },
  functions: {
    title: "ҚАНДАЙ АСОСИЙ ВАЗИФАЛАРИ БОР?",
    items: {
      item1: {
        title: "АВТОМАТИК РАВИШДА ФОТО ВА ВИДЕО ҚАЙД ЭТИШ",
        info: `<ul>
        <li>
        Олти қаторгача бўлган ҳаракатланиш йўлларида бир вақтнинг ўзида иккала йўналишда ЙҲҚ бузилишини автоматик фото ва видео қайддан ўтказиш.
        </li>
        <li>
        Ҳар бир йўл қаторига алоҳида тезлик чегараларини  ва  "Б", "C" ва "Д" тоифадаги транспорт воситалар учун алоҳида тезлик чегаралари қайд этиш.
        </li>
        <li>
        Ҳар бир қайд этилган қоидабузарликнинг  фото ва видео материалларини автоматик сақлаш.
        </li>
        <li>
        ГЛОНАСС/ГПС модули орқали мажмуанинг тизим вақтини автоматик равишда созланиши.
        </li>
      </ul>`,
      },
      item2: {
        title: "ДАВЛАТ РАҚАМ БЕЛГИЛАРИНИ БИЛИБ ОЛИШ",
        info: `<ul>
        <li>
        Дунёдаги кўплаб мамлакатларининг давлат рақам белгиларини, жумладан, икки қаторли рақамларини автоматик равишда аниқлаш.
        </li>
        <li>
        Бошқа давлатларнинг ДРБни таниб олиш дастурини ёқиш ва ўчириш.
        </li>
        <li>
        Аниқланган рақамларни маълумотлар базасидан операторни хабардор қилиш ва рақамни овозли эшиттириш орқали текшириб кўриш.
        </li>
        <li>
        Рақами йўқ бўлган ёки ўқиб бўлмайдиган транспорт воситасини қидириш учун "Ушлаб олиш" махсус иш режимини ишга тушириш.
        </li>
      </ul>`,
      },
      item3: {
        title: "ТВнинг таснифи",
        info: `<ul>
        <li>
        ТВ турининг автоматик аниқланиши ва уларнинг  
          <strong>
          тўртта асосий тоифалар бўйича таснифланиши
          </strong>
          (енгил, юк ташувчи, автобуслар, ўртача ҳажмдаги). 
        </li>
        <li>
        ТВга тезлик чегараси бўйича тегишли тоифани автоматик тартибда бериш.
        </li>
        <li>
        ТВ ҳаракатининг берилган тоифа учун алоҳида чизиқлар ёки бутун йўл бўйича ҳаракатланишини тақиқланишини автоматик назоратга олиш.
        </li>
      </ul>`,
      },
      item4: {
        title: "МАЪЛУМОТЛАРНИ УЗАТИШ",
        info: `<ul>
        <li>Маълумотларни шифрланган симли ёки симсиз алоқа йўли орқали маълумотларга ишлов бериш маркази хизматига узатиш (3G/4G). 
        </li>
        <li>Асосий канал узилиб қолганда ёки тўхтаб қолганда алоқанинг захира каналларига автоматик равишда уланиш (Wi-Fi, 4G). 
        </li>
        <li>
        Турли хил хизматларга мажмуадан маълумотларни бир вақтнинг ўзида узатиш имконининг мавжудлиги.
        </li>
      </ul>`,
      },
      item5: {
        title: "ВИДЕОКУЗАТУВ",
        info: `<ul>
        <li>
        РТСП протоколи бўйича юқори оқимли видеооқимни узатиш.
        </li>
        <li>
        Берилган оралиқ вақт бўйича видеороликни юклаш имконига эга узлуксиз видеоёзувни олиб бориш.
        </li>
      </ul>`,
      },
      item6: {
        title: "ТУНГИ ВАҚТДА ИШЛАШ",
        info: `<ul>
        <li>
        Тунги пайтда ишлаш учун мажмуа ичкарисида инфрақизил ёритқич ўрнатилган.
        </li>
        <li>
        Қўшимча ташқи инфракизил ёритиш ускунаси орқали тасвир бўйича транспорт воситасининг русумини кафолатли аниқлаш.
        </li>
      </ul>`,
      },
    },
  },
  specifications: {
    title: "ТЕХНИК ТАСНИФЛАРИ",
    tabs: {
      tab1: {
        title: "МЕТРОЛОГИК МЕЗОНЛАРИ (ЎЛЧАМЛАРИ)",
        text: `<table>
        <tbody>
          <tr>
            <td>Нурланишнинг ишчи частотаси</td>
            <td>24,05 дан 24,25 ГГц га қадар</td>
          </tr>
          <tr>
            <td>Ўлчанадиган тезликларнинг диапазони</td>
            <td>2 — 300 км/с</td>
          </tr>
          <tr>
            <td>
            Тезликни ўлчашнинг йўл қўйиладиган мутлақ хатоларининг чегараси
            </td>
            <td>± 1,0 км/с</td>
          </tr>
          <tr>
            <td>
            Ички шкала вақтини хато синхронлаштиришнинг йўл қўйилган чегараси UTC(SU) билан
            </td>
            <td>± 5 мкс</td>
          </tr>
        </tbody>
      </table>`,
      },
      tab2: {
        title: "ЭЛЕКТР ҚУВВАТИ ВА КАТТА ҲАЖМЛИЛАР",
        text: `<table>
        <tbody>
          <tr>
            <td>
            Ишлатиладиган қувват (ижобий ҳароратларда)
            </td>
            <td>25 Втдан ортиқ бўлмаган </td>
          </tr>
          <tr>
            <td>"Ўзгармас токнинг кучланиши</td>
            <td>10—16 В</td>
          </tr>
          <tr>
            <td>
            Ўзгарувчан токнинг қувватлантирувчи мослама билан қувватлантириш кучланиши
            </td>
            <td>90—300 В</td>
          </tr>
          <tr>
            <td>Датчикнинг оғирлиги</td>
            <td>6,0 кг дан ортиқ бўлмаган</td>
          </tr>
          <tr>
            <td>Катта ҳажмдаги ўлчамлар</td>
            <td>460×180×280 мм дан кўп бўлмаган</td>
          </tr>
        </tbody>
      </table>`,
      },
      tab3: {
        title: "ИШ ШАРОИТЛАРИ ВА ИШОНЧЛИЛИГИ",
        text: `<table>
        <tbody>
          <tr>
            <td>
            Атроф-муҳитнинг ҳарорати:
              <div></div>
              — стандарт кўриниш:
              <br />
              —  арктик кўриниш:
            </td>
            <td>
              <div></div>
              <br />
              <div></div>
              −40° C дан +60° С гача
              <br />
              −55° С дан +60° С гача
              <br />
            </td>
          </tr>
          <tr>
            <td>Ҳавонинг нисбий намлиги: симоб устунининг</td>
            <td> 98% т°=+25° С га тенг</td>
          </tr>
          <tr>
            <td>Атмосфера босими:</td>
            <td>  60 дан 106,7 кПа гача</td>
          </tr>
          <tr>
            <td>Рад этиш учун ўртача ишлов бериш:</td>
            <td> 35 000 соатдан кам эмас</td>
          </tr>
          <tr>
            <td>Истеъмолдан чиқаргунга қадар ўртача хизмат қилиш муддати:</td>
            <td> камида 6 йил</td>
          </tr>
          <tr>
            <td>
            Қаттиқ буюмлар ва сувнинг кириб қолишидан ҳимояловчи қобиқ даражаси: 
            </td>
            <td>ИП65</td>
          </tr>
          <tr>
            <td>
            Ташқи механик зарбадан ҳимояловчи қобиқ даражаси:
            </td>
            <td>ИК08</td>
          </tr>
          <tr>
            <td>
            Ўқотар қуролдан ҳимоялаш тоифаси (зирҳли сиртдан фойдаланган ҳолда):
            </td>
            <td> «Бр2» и «С1»</td>
          </tr>
        </tbody>
      </table>`,
      },
      tab4: {
        title: "АХБОРОТ ХАВФСИЗЛИГИ",
        lists: {
          list1:
            "Маълумотлар ва ўрнатилган ПОни рухсатсиз ўзгартиришлардан ҳимоя қилиш.",
          list2:
            "Экспорт қилинадиган маълумотлар ЭСПдан сертификатланган калит мосламаси ёрдамида ҳимояланган.",
          list3:
            "Тўплам фойдаланувчиси томонидан воқеалар ва ҳаракатларни қайд этиш журналининг юритилиши.",
          list4:
            "Асбобга жисмоний таъсир этиш ҳолатлари (зарба, тебратиш) қайд этилган ҳолатлар ҳақида СМС ва электрон почта орқали маълумотларни узатиш.",
        },
      },
    },
  },
  statistics: {
    items: {
      item1: {
        title: "АНИҚЛАШ (БИЛИБ ОЛИШ) ЭҲТИМОЛИ",
        progressbar: {
          progressbar1: "ГРН орқали аниқлаш (98%)",
          progressbar2: "Транспорт воситасини тасвир ёрдамида таснифлаш (95%)",
        },
      },
      item2: {
        title: "ХАТО КЎРСАТКИЧЛАР",
        progressbar: {
          progressbar1: "Тезликни ўлчаш (± 1,0 км/с)",
          progressbar2: "УТC (± 5 мкс) билан вақтни синхронлаш ",
        },
      },
    },
  },
  guarantee: {
    title: "СЕРТИФИКАЛАШ ВА КАФОЛАТ",
    top: {
      item1: `Текширувлар оралиғидаги муддат – <span>2 йил</span>`,
      item2: `Кафолат муддати – <span>2 йил</span>`,
    },
    bot: {
      item1: `Ўзбекистон стандартлаштириш, метрология ва сертификатлаштириш агентлигининг ("Ўзстандарт" агентлиги) Ўлчов воситаларининг турини тасдиқлаш O'T 0000590 №02-2.0070 сертификати. Ўзбекистон Республикаси Миллий сертификатлаштириш тизимининг мувофиқлик  13.12.2021й. UZ.SMT.01.354.72116889 -сон сертификати.`,
      item2:
        "Россия Федерациясининг техник тартибга солиш ва метрология бўйича Федерал агентлигининг ОС.C.33.158.A №75141 кўринишидаги ўлчов воситаларини тасдиқлаш тўғрисидаги гувоҳномаси. ЕОИИнинг  TC RU C-RU.ME83.B.00002/18. гувоҳномасига мувофиқлиги.",
    },
  },
  faq: {
    title: "САВОЛЛАР ВА ЖАВОБЛАР",
    subtitle: `"Кордон.про" тўплами бўйича энг кўп бериладиган саволлар.`,
    text: `"Кордон.про" тўплами "Йўл-2019" халқаро кўргазмасида тақдим этилганидан сўнг бизга журналистлардан кўплаб саволлар келиб туша бошлади. Бундан ташқари, матбуотда мазкур лойиҳанинг техник томонини ноаниқ (баъзида эса шунчаки нотўғри) изоҳловчи маълумотлар чоп этила бошланди. Қуйида "Кордон.про" тўпламининг энг муҳим хусусиятларини изоҳловчи жавоблар ва тушунчалар берилмоқда:`,
    accordion: {
      accordion1: {
        head: "Тўпламни агар назорат қилинаётган йўлнинг фотовидео қайд этиш ҳақида огоҳлантирувчи белги бўлмаган ҳудудида партул автомобилига ўрнатган ҳолда ҳаракатда қўллаш қанчалик тўғри?",
        body: `<p>5.43 «Радар» белгили ёки 5.41 ва 7.19  «Фотовидео қайд этиш» белгилари Ўзбекистон Республикасининг  ЙҲҚ (Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2015 йил 24 декабрдаги 370-сон қарори)га мувофиқ стационар тўпламлар қўйилган жойларнинг олдига,"Кордон.про" –  бажарадиган вазифасига қараб ўрнатилиши лозим. Ва ундан стационар режимда фойдаланилганда  огоҳлантирувчи белгиси ўрнатилиши керак.
      </p>
      <p>
      У ёки бу автомагистрал йўлидаги тезликни автоматик равишда назорат қилиш тўғрисида огоҳлантириш мавжуд бўлган электрон табло ҳозирги кунда кенг қўлланилмоқда. Шунинг учун ҳам ҳайдовчи назорат остида эканлиги ҳақида огоҳлантирилади. 
      </p>
      <p>
      Бироқ сунъий салоҳият имкониятлари ўсиб бормоқда, эртага бундай огоҳлантиришлар, шаҳарга кириш жойларида ҳам пайдо бўлиши мумкин. Нимаси ажабланарли? Биз сиз ҳам йўлларимизда экстремал пойгачиларнинг ҳаракатлари жиловланишига қарши эмаслигига чин дилдан умид қилиб қоламиз. 
      </p>`,
      },
      accordion2: {
        head: `"Кордон.про" тўпламининг янгилиги нимадан иборат, ахир машинага ўрнатилган ва ҳаракатланаётган пайтдаги тезликни ўлчовчи  асбоблар аллақачон мавжуд эди-ку?`,
        body: `<p>
        Мутлақо тўғри, айнан Симикон компанияси <strong> илк бор 1999 йилда Россияда </strong> ҳаракат пайтида ишловчи полиция радарини ишлаб чиқарди. Асбоб <strong> "Искра-1Д" </strong> (Учқун) деб номланиб, РФнинг ўлчов воситалари реестрига киритилди ҳамда шахсий тезлик ва қоидабузар автомобилнинг тезлигини радиолокацион тарзда ўлчанишини таъминлади. Қарор рухсатнома олди. Шундай қилиб шахсий тезликни ўлчовчи радиолокацион алгоритмлар <strong> 20 йилдан ортиқ </strong> вақт мобайнида такомиллаштириб борилмоқда. 
      </p>
      <p>
        Кейинчалик ҳаракатланаётганда ишловчи радиолокацион видео қайд этувчилар (<strong>"Искра-Видео", "Бинар"</strong>) пайдо бўлди. Бундай асбоблар нозир??? томонидан бошқарилиши лозим бўлган: улар инсон иштирокисиз ишлай олмаган. Нозир қоидабузарни тўхтатган ва унга содир этган қоидабузарлигининг далилини тақдим этган, чунки оқим асбоб ичида рақамли белгиларни таниб олиш ва қоидабузарни аниқлаш  имконига эга бўлмаган. Айнан мана шу ҳолат "инсон омили"ни ва "коррупцион таркибни" истисно этилишини талаб қилган. Ҳозирда бу муаммо ҳал қилинган. 
      </p>
      <p>
        <strong>
        Эндиликда "Кордон.про" бир қанча вазифаларни автоматик равишда бажаради:
        </strong>
      </p>
      <p>
        <strong>
        1. доплер радиолокатор ёрдамида шахсий тезликни, шунингдек, тезликни ва унинг назарига тушган барча мақсадларнинг нуқталарини ўлчайди;
        </strong>
      </p>
      <p>
        <strong>
        2. оптик усулда рақам белгиларини таниб олади ва уларнинг жойлашган ҳудудини аниқлайди;
        </strong>
      </p>
      <p>
        <strong>
        3. қайси рақамга қайси тезлик тегишлилигини хатосиз аниқлаб беради;
        </strong>
      </p>
      <p>
        <strong>
        4. ҳар бир қоидабузар бўйича фотоматериал ва видеороликни шакллантиради ва уларни хотирасида сақлаб қолади;
        </strong>
      </p>
      <p>
        <strong>
        5. тезликнинг қайси чегарасидан бузилганлигини ҳисоблаш учун транспорт воситасининг тоифасини аниқлайди – тезлик ахир ҳамма автомобилда ҳар хилдир!
        </strong>
      </p>
      <p>
        <strong>
        6. Фотоматериалларни зудлик билан 3G/4G/ Интернеталоқа канали орқали МИБМда жўнатади;
        </strong>
      </p>
      <p>
        <strong>
        7. ва асосийси — инсоннинг мазкур жараёнда қатнашишига йўл қўймайди, буларнинг ҳаммаси автоматик тарзда содир бўлади.
        </strong>
      </p>
      <p>
        <strong>
        Умуман олганда, Сиз ҳақсиз, ҳеч қандай янгилик йўқ, оддий сунъий салоҳият холос!..
        </strong>
      </p>`,
      },
      accordion3: {
        head: "Тезлик режими тез-тез ўзгартирилган шароитларда назорат қилинаётган ҳудудда қоидабузарни исботловчи маълумот қандай таъминланади? Ахир ГПС нуқталарини аниқ аниқлаш ҳозирча унчалик ҳам кучли эмас. ",
        body: `<p>
        "Кордон.про" мажмуасини ишлатиш учун олдиндан электрон карта тайёрлаб қўйилиб, унда тезликни чеклашнинг нуқта белгилари, аҳоли ҳудудларининг чегаралари, кўчаларнинг номланиши, уй рақамлари кўрсатилади. 
      </p>
      <p>
      Карта мажмуанинг хотирасига юкланади, сўнгра эса у айнан ушбу карта бўйлаб белгиланади, яъни мазкур автомобиль қоидабузарми ёки йўқлиги. Аҳоли ҳудудининг ичида тезликни чеклашнинг 70 км/с дан ошмаслигига, яшаш ҳудудлари ва унга яқин жойларда (уй-жой қурилишлари оралиғидаги ҳудуд) 30 км/с дан ортиқ бўлмаслиги лозим (Ўзбекистон Республикасининг ЙҲҚ 78-моддаси).Аҳоли яшамайдиган ҳудудларда қуйидаги ҳаракатланишга (Ўзбекистон Республикасининг ЙҲҚ 79 моддаси): —рухсат этилган максимал оғирлиги 3,5 тонна бўлган енгил ва юк ташувчи машиналарга — 100 км/с дан тезликни оширмасликка;
— шаҳарлараро автобуслар ва микроавтобусларга — 90 км/с дан тезликни оширмасликка;
— бошқа рухсат этилган максимал оғирлиги 3,5 тоннадан ортиқ бўлган автобуслар, тиркама уланган енгил автомобиллар, —мотоцикллар, юк автомобилларига — 80 км/с дан тезликни оширмасликка;
тиркама уланган юк ташиш автомобилларига — 70 км/с дан оширмасликка  рухсат этилади.
      </p>
      <p>
      Тезлик режимини бузиш билан боғлиқ қоидабузарликлар содир этилган тақдирда маъмурий жазо махсус мосламанинг умумий хато кўрсаткичларини, тезлик ўлчагичлар ва ТВ пидометрининг соатига 5 километр ҳисоблаган ҳолда қайд этилган тезликни  инобатга олган ҳолда қўлланилади (ЎзР МЖК 128-3-моддаси).
      </p>
      <p>
      Сунъий координаталарни аниқлашдаги хатолик <strong> метреологик тарзда тасдиқланган, сертификатланган ва  ±4,5 м дан </strong> ортиқни ташкил этмайди. Бироқ чегарани автоматик аниқлаш учун "Кордон.про" мажмуасида ноаниқликларни тузатиш чеклови <strong> уч баробар оширилган (!) ва  ±15 м ни ташкил этади.</strong>
      </p>
      <p>
      Бу ўрнатилган чекловларни нотўғри қўллаш билан боғлиқ тасодифий хатолар бўлмаслиги учун қилинган. Тезликни чеклаш белгисининг географик ҳолати ва мазкур белгини амал қилиш ҳудудининг тугаши доимо ушбу тузатишни инобатга олган ҳолда амалга оширилади.
      </p>`,
      },
      accordion4: {
        head: "Патрул автомобили ҳаракатланаётганда 1 км/с да тезликни ўлчашдаги хато қандай таъминланади?",
        body: `<p>
        <strong> ±1 км/с даги хатолик </strong> ҳозирги кунда бу — чегара эмас. "Кордон.про" мажмуасида ўлчаш аниқлигини текшириш амалга оширилган метрологик мослама ±0,3 км/с хатога эга. 
      </p>
      <p>
      Замонавий юқори самара берувчи процессорлар математик таҳлилнинг батафсил ишлаб чиқилган алгоритмлари билан уйғунликда кўп нарсаларга қодир! Мажмуага татбиқ этилган радиолокацион ўлчам алгоритмлари ўлчовларни тез-тез ва кўп маротаба такрорланишини, шунингдек, уларнинг ишончлилигини текширишни назарда тутади.
      </p>
      <p>
      Натижалар кўплаб тадқиқотлар билан тасдиқланган. Олинган таснифлар "Ўлчов воситаларининг турини тасдиқлаш тўғрисида"ги 2019 йил 19 сентябрдаги <span> ОС.C.33.158.A  75141-сонли </span> Гувоҳнома билан тасдиқланган.
      </p>`,
      },
    },
  },
  footer: {
    address: {
      city: "100164, Тошкент шаҳри, Мирзо Улуғбек тумани",
      street: "м.Ялангач, 119а",
    },
    titles: {
      title1: "СОТУВ БЎЛИМИ",
      title2: "ТЕХНИК ҚЎЛЛАБ-ҚУВВАТЛАШ",
      title3: "ИЖТИМОИЙ ТАРМОҚЛАР",
    },
  },
  buttons: {
    prev: "Олдинги",
    next: "Кейинги",
  },
  speedChange: {
    title: "ТЕЗЛИКНИ ЎЛЧАШ",
    text: {
      text1:
        "КОРДОН оиласига кирувчи тўпламлар бир қатор ажойиб имкониятлар билан таъминловчи кўп мақсадли радарлар билан жиҳозланган:",
      text2:
        "Тўпламни ўрнатиш бурчагига нисбатан қатъий талабнинг йўқлиги. Кўп мақсадли радар бир зумда мақсад ҳаракатининг барча ўлчамларини ўлчаб чиқади (тезлик, масофа, азимут). Бир вақтнинг ўзида назорат ҳудудидаги барча мақсадларнинг қайд этилиши.",
      text3:
        " Кўп мақсадли радар билан фақат бир дона ТВни суратга олишнинг мавжудлиги тўғрисидаги талаб йўқолди. Назорат ҳудудида ҳаттоки, бир неча ТВ мавжуд бўлганда ҳам ўлчанган тезликларнинг қайд этилган автомобилларга аниқ боғлиқлиги кафолатланади. ",
      text4: `Биринчи авлод фоторадарлари 1-2 чизиқдан ортиғини қайд эта олмаган. "КОРДОН" туркумига кирувчи тўпламнинг биттасинининг ўзи бутун йўлни назорат қилиши ва бу билан аввалги авлоднинг 6 тасининг ўрнини босиши мумкин. Назорат ҳудудининг ўлчамлари  — тўпламнинг ўқидан бурчаги бўйлаб ±15°, тўпламни ўрнатиш жойидан масофаси бўйлаб 10 дан 80 м гачани ташкил этади.`,
      text5: `Мажмуа ҳудудларда ва исталган бошқа "Кордон.про", "Кордон-М" ёки  "Кордон-Темп" мажмуаларида ўртача тезликни аниқлаш имконини таъминлайди. Ўртача тезликни аниқлашнинг минимал масофаси бор-йўғи 200 метрни ташкил этади, бунда ўлчанадиган тезликларнинг диапазони 1 дан 350 км/с ни ташкил этади.`,
      text6:
        "Ҳаракатнинг ўртача тезлигини ўлчаш тўғридан-тўғри усулда олиб борилади: тезлик босиб ўтилган йўлнинг сарф қилинган вақтга узунлик нисбати сифатида аниқланади. Ўтган йўлни назорат қилиш зарурати туғилганда  икки, уч ва ундан ортиқ мажмуаларни занжирда қўллашга рухсат этилади. Ҳудуддан ўтиш вақти кириш ва чиқиш вақтини қайд этишдаги тафовут баилан аниқланади. Мажмуаларни ўрнатиш нуқталари ўртасидаги масофа текширилган ўлчов узунлиги (масофа) билан ўлчанади, бунда қайд этиш вақтида ТВнинг иккала мажмуадаги радар координатлари ҳам ҳисобга олинади.",
    },
  },
  workInMove: {
    title: "ҲАРАКАТЛАНАЁТГАН ПАЙТДА ИШЛАШ",
    text: {
      text1:
        "Кордон.про тўпламини автомобилга ҳаракатланаётганда ишлаши учун ўрнатиш унинг ажойиб хусусияти ҳисобланади.",
      text2:
        "Ҳаракат вақтида тўплам автоматик равишда назорат ҳудудида радиолокацион усулда ўзининг тезлигини ва транспорт воситасининг тезлигини ўлчайди. Ҳаракатда ишлаётганда рухсат этилган тезлик чегарасини тўғри аниқлаш учун тўпламнинг хотирасига чекланган тезлик ҳудудларининг нуқталари қайд этилган олдиндан тайёрланган  электрон карта юкланади. Карта маъмур томонидан таҳрир қилинади.",
      text3:
        "Метрологик ишончли маълумотлар асосида тўплам автоматик равишда тезлик режимини бузувчиларни аниқлайди ва қайд этилган маълумотларни зудлик билан маъмурий ҳуқуқбузарликларни автоматик қайд этиш Марказига узатади.",
    },
  },
  classifier: {
    title: "ТАСНИФЛАГИЧ",
    text: {
      text1:
        "Мажмуа қайд этилган ТВнинг тоифасини автоматик танийди (енгил, юк ташувчи, автобуслар). Таснифлаш ҳеч қандай маълумотлар базасига мурожаат қилмаган ҳолда амалга оширилади. Бу Юк ташувчи ТВни ҳаракатини тақиқлашнинг бузилишини қайд этиш, шунингдек,  ЙҲҚ бўйича ТВ тоифасига тегишли тезликни чеклашни белгилаш имконини беради.",
      text2:
        "Aвтотранспорт воситасининг ўрнатилган оптик таснифлагичи маълумотлари асосида йўл ҳаракати қоидаларига мувофиқ транспорт воситаларининг (автомобиллар, юк машиналари, автобуслар) турига мос келадиган тезлик чегарасини автоматик равишда тўғри белгилаш. Aвтотранспорт воситалари ҳайдовчилари томонидан Ўзбекистон ЙҲҚ 91-банди бузилишини автоматик квалификация қилиш функсияси. Aвтотранспорт воситаларининг ўрнатилган оптик классификатори маълумотлари асосида юк машиналарининг ҳаракатланишини тақиқлаш (Ўзбекистон ЙҲҚ 3.4 белгиси) бузилишини автоматик тарзда аниқлаш.",
    },
  },
  wanted: {
    title: "ҚИДИРУВ",
    text: {
      text1:
        "Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2007.27.06 даги 131-сон Қарорига мувофиқ, кўплаб мамлакатларнинг рақамли белгиларини, жумладан икки қаторли, уч қаторли ДРБни автоматик тарзда аниқлаш мумкин. ДРБни автоматик аниқлай олмаслик одатда уни оператор томонидан ишончли равишда аниқланмаганлигини англатади.",
      text2:
        "Кордон туркумига мансуб мажмуалар Узоқ Шарқда Хитойнинг транспорт воситаларининг ҳаракатланишини (иероглифларни таниб олишда), шунингдек, Эрон (араб ёзуви) ва Корея каби мамлакатларда назорат қилиш мақсадида муваффақиятли қўлланилмоқда.",
      text3: `Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2021 й. 21 апрелдаги 232-сон Қарорига, ДавСт Р 57144-2016В 5.2. банд талабларига мувофиқ "Кордон" оиласига кирувчи ўлчов асбоблари 4 тадан кам бўлмаган хорижий давлатнинг ДРБни сўровнома бўйича ва Буюртмачининг рўйхатига кўра таниб олинишини таъминлайди. Буюртмачи билан келишилган шартноманинг шартларига, заводдан туширилган мажмуаларга қараб, у ёки бошқа хорижий давлатнинг ДРБни аниқлаш вазифаси киритилади. Шунингдек, унда тижорат асосида бошқа давлатларнинг рақамларини таниб олиш тизимини қайта созлаш ёки ДРБни таниб олиш учун мамлакатлар сонини кўпайтириш  имкони мавжуд.`,
      text4:
        "Нейрон тармоғининг технологияси ГПНнинг янги форматларига тезда ўқитишни таъминлайди.",
      text5:
        "Мажмуа асбобнинг қидирув базаси хотирасига юкланиши бўйича топилган транспорт воситалари тўғрисидаги маълумотни, шунингдек, картадаги видеороликнининг оператив ёзувини СD хотирага  олиш имконини беради. Мажмуага улашда оператор базаларда топилган рақам белгиларини овоз чиқариб айтиш вазифасидан фойдаланиши мумкин.",
      text6: `"Кордон" оиласига кирувчи барча тўпламлар ТВни қидириш ва уларни рақам белгиларини аниқлаб бўлмайдиган ёки улар йўқ бўлган ТВни қайд этишга мўлжалланган махсус "Ушлаб олиш"  режимига эга. Бизнинг қурилмамиздан фойдаланувчиларнинг тақризларига кўра, бундай имконият опертив*қидирув тадбирларини олиб боришда бебаҳо ҳисобланади.`,
      text7:
        "TCP/XML маълумотлар узатиш протоколи марказлаштирилган автомобил қидирув тизимлари билан комплексларни бирлаштириш имконини беради.",
    },
  },
  settings: {
    title: "СОЗЛАШ ВА ЎРНАТИШ",
    text: {
      text1: `"Кордон.Про" комплекси стационар таянчга ўрнатилиши мумкин - айланма кронштейн ёрдамида. Кронштейн бурчаклар ёки ўрнатиш лентаси билан таянчга ўрнатилиши мумкин.`,
      text2:
        "Конфигурация веб-интерфейс орқали масофадан туриб, ёки ўрнатиш жойида ноутбук (ёки планшет) ёрдамида амалга оширилади. Дастлабки созлаш учун талаб қилинадиган вақтни сезиларли даражада қисқартирди. Интерфейсда созлама сифатини акс еттирувчи ва сенсорнинг ҳолатини тўғрилаш бўйича тавсиялар берувчи интерактив кўрсаткичлардан фойдаланилади. Бундан ташқари, мажмуа тўғри йўналтирилган ва ўз-ўзидан ташхис қўйилган тақдирдагина йўл ҳаракати қоидалари бузилишини фото-видео қайд етишни бошлайди.",
      text3:
        "Электр таъминоти тармоқларига уланиш учун турли хил вариантлар мавжуд, жумладан ёруғлик тармоғига уланиш ва батареядан автоном ишлаш.",
    },
  },
  rcar: {
    title: "Р-АВТО",
    text: "Автомобилнинг юкхона рейлингига ўрнатилган маҳкамлаш тўплами мажмуани мобил режимда ишлатаётганда мажмуани бошқа жойга оператив кўчириш имконини беради. Шундай қилиб, мажмуа йўлнинг танланган ҳудудида, жумладан, стандарт штатив қўллашга рухсат берилмайдиган шароитларда оператив тарзда ёйиш мумкин. Кронштейн юкхона рейлингига эга ҳар қандай автомобилга ўрнатилиши мумкин ва датчикнинг бурчак эгилиши ва бурилишига қулайликни таъминлайди. ",
  },
  protection: {
    title: "ЗИРҲЛИ ҲИМОЯ",
    text: `Зирҳли пўлатдан тайёрланган ғилоф  "Кордон. Про" тўплами учун махсус ишлаб чиқилган. Ўқотар қуролдан ҳимояни таъминлайди, "Бр2" (СПС ва ТТ тўппончалари) ва "C1" (рухли ўққа эга ов милтиғи) тоифалари бўйича ўққа чидамлиликдан  сертификатланган.  Ғилоф иккита кўринишда тайёрланиши мумкин: зирҳли ойнали ва ойнасиз. Зирҳли ҳимоя стандарт кронштейн ёрдамида таянчга ўрнатилган тўпламга маҳкамланади. Ўрнатиш учун икки нафар киши керак бўлади.`,
  },
  base: {
    title: "ИСБОТЛОВЧИ (АСОСЛОВЧИ) БАЗА",
    text: "Тўплам назорат қилинаётган ҳудудда ЙҲҚ бузилишини автоматик қайд этади ва шарҳли суратлар, визуал фарқловчи рақам белгиси, ҳар бир қоидабузарликнинг видеоёзуви каби ҳар бир мақсад учун зарур асосли далилларни шакллантиради. Бир вақтнинг ўзида тўплам назорат қилаётган ҳудудда воқеаларни узлуксиз видеоёзуви олиб борилади. ТВнинг тунги вақтдаги фотовидео қайди махсус ўрнатилган инфрақизил чироқлар ёрдамида амалга оширилади, тасвир сифати шунингдек автомобиль маркасини ҳам аниқлаш имконини беради.",
  },
  copyright: {
    copyright: {
      copyright1: "Муаллифлик ҳуқуқи ",
      copyright2: "МЧЖга тегишли",
    },
    rights: "Барча ҳуқуқлар ҳимояланган",
  },
  cross: {
    link: {
      link1: "КОРДОН ПРО М",
      link2: "КОРДОН КРОСС",
    },
    title: {
      top: "ЧОРРАҲАЛАРНИНГ АВТОМАТИК НАЗОРАТИ",
      bot: "КОРДОН-КРОСС",
    },
    subtitle: "«КОРДОН-КРОСС» видео қайд қилишли ўлчов комплекси",
    cordon: {
      subtitle:
        "«КОРДОН-КРОСС» стационар комплекси чорраҳаларда йўл ҳаракат қоидалари (ЙҲҚ) бузилишини назорат қилиш ва фото-видеоли қайдлар маълумотларини қайта ишлаш марказига (МҚИМ) юбориш учун мўлжалланган. Барча рақамларни автоматик аниқлаш ва турли ҳил маълумотлар базасидан транспорт воситаларини текширишни таъминлайди.",
    },
    slider: {
      slider1: {
        title: "ТУНГИ ИШ",
        subtitle: "Автоматик тунги режимга ўтиш",
      },
      slider2: {
        title: "СВЕЛОФОРНИ АНГИЛАШ",
        subtitle:
          "Видео рамкалар ва / ёки ёъл бошқарувчисига симли уланиш ёрдамида таниб олиш.",
      },
      slider3: {
        title: "(РАДАК БИЛАН). ТЕЗЛИКНИ 300 КМ/СОАТГА ЎЛЧИШИ",
        subtitle:
          "2 дан 300 км / соат гача бўлган тезликни ўлчаш (радар мавжудлигида).",
      },
    },
    fixation: {
      title: "ҚАЙД ЭТИЛАДИГАН ЙҲҚ БУЗИЛИШ ҲОЛАТЛАРИ",
      subtitle:
        "ЙҲҚ қайд этилган рўйҳат, чорраҳаларда камераларнинг жойлашув схемаси ва комплекснинг тўлиқ тўпламига боғлиқ",
      items: {
        item1: "Светофорнинг тақиқловчи ишорасига ўтиш",
        item2:
          "Тўхташ чизиғидан олдин сфетофорнинг тақиқловчи тўхташ талабига риоя қилмаслик",
        item3: "Ажратувчи чизиқ бўйлаб ҳаракат",
        item4: "Қарама-қарши чизиққа чиқиб кетиш",
        item5: "Қатнов қисмининг четидан транспорт воситаларининг ҳаракати",
        item6: "Велосипед, пиёдалар йўлкаси ёки тротуарлардан ҳаракатланиш",
        item7:
          "Юк ташувчи транспорт воситаларининг ҳаракатланиши тақиқини бузиш (шу жумладан алоҳида чизиқлар бўйлаб)",
        item8:
          "Ҳаракатланаётганда транспорт воситалари орасида минимал масофани бузиш",
        item9:
          "Ҳайдовчи ёки йўловчи томонидан хавфсизлик камари тақилмаган ҳолда ҳаракатланиш",
        item10: "Белгиланган ҳаракат тезлигидан ошиб кетиш",
        item11:
          "Бир тарафлама ҳаракатланиш қисмида қарама-қарши йўналишда ҳаракатланиш",
        item12: "Бурилиш қоидаларини бузиш",
        item13:
          "Маршрут транспорт воситалари учун мўлжалланган чизиқдан ҳаракатланиш",
        item14:
          "Бўйланма ётиқ чизиқлар бўйлаб транспорт воситаларининг ҳаракатланиш йўналишини ўзгартириш",
        item15:
          "Йўл белгилари ёки чизиқлар билан билан белгилаб қўйилган талабларни бузиш",
        item16:
          "«Вафель чизиғи»да тўхташ ва тирбандлик вақтида чорраҳага чиқиб кетиш",
        item17: "Йўлнинг махсус кенгайтирилган қисмида ўрта тезликни ошириш*",
        item18:
          "Фаралар ёқилмаган ҳолатда ҳаракатланиш (олди чироқлар/кундузги сигнал чироқлар)",
      },
    },
    compound: {
      title: "КОМПЛЕКС ТАРКИБИ",
      subtitle:
        "Комплекс бир ёки бир нечта видео датчиклардан (аниқловчи камералар) ИҚ- прожекторлар, кузатув камералари ва маълумотларни қайта ишлаш блокидан («Кросс» МҚИБ) иборат.",
      text: {
        text1:
          "Модуллилиги туфайли комплекс ўзига хос ноёб масштабликка эга. Тезлик режимини бошқариш учун комплекснинг бир ёки бир нечта видео датчиклари радар модуллари билан жиҳозланиши мумкин.",
        text2: `<p>"Талабга кўра, видеодатчиклар қўшимча равишда қуйидагилар билан жиҳозланиши мумкин: 
        - ойнани автоматик тозалаш тизимлари; 
        - масофадан бошқариладиган моторли эгилиш ва бурилиш мосламалари.
        </p>`,
        text3:
          "Барча камералар оптик интерфейсларга эга ва оптик толали алоқа линиялари (ОТАЛ) ёрдамида ахборотни қайта ишлаш блокига уланган.",
      },
    },
    scheme: {
      title: "ЖОЙЛАШУВ СХЕМАСИ ВА ИШ ТАРТИБИ",
      subtitle:
        "Видео датчиклар ва кузатув камералари бир неча метр баландликда стационар мосламаларга (ёруғлик минораси, таянчлари ва бошқалар) ўрнатилади ва 220В тармоққа уланади.",
      text: {
        text1: "Назорат зоналари ва чорраҳада жиҳозларни жойлаштириш мисоли",
        text2: "Белгилар: ВД - Видеодатчик, ОК - Кузатиш камераси",
        text3:
          "Ҳар йўналишда икки чизиқларга ва икки томонлама ҳаракатга эга одатий икки йўл чорраҳасини тўлиқ назорат қилиш учун 4 видеодатчик, 4 кузатув камераси ва битта маълумотларни қайта ишлаш блоки керак бўлади. Аниқ сони ва жиҳозларнинг таркиби ҳар бир лойиҳада чорраҳанинг конфигурациясидан келиб чиққан ҳолда аниқланади.",
        text4:
          "Комплекс ишининг параметрларини ўрнатиш компютер ёки планшет ёрдамида ўрнатилган Web-интерфейс орқали амалга оширилади. Светофор сигналини аниқлаш (қўшимча секциялар) автоматик равишда кузатув камерадан ёки бирлашган усулда светофор назоратига уланган ҳолда амалга оширилади ва бу юқори ишончли ва хатолардан ҳимояланиш даражасини таъминлайди.",
        text5:
          "«Кросс» маълумотларни қайта ишлаш блоки видеодатчиклардан олинган маълумотлар орқали рўйхатдан ўтказиш давлат рақамларини, кадрдаги ҳаракат таҳлилини ва автоматик светофор сигналларини аниқлайди. Маълумотларни қайта ишлаш жараёнида комплекс, чорраҳанинг умумий суратини ва қоидабузарнинг катталаштирилган расмини ёки белгиланган оралиқда амалга оширилган йўл ҳаракати ҳолати акс этган фотоларни ўз ичига олган қоидабузарнинг суратларидан иборат коллажни ҳосил қилади. Давлат рақамлари ҳақидаги аниқланган маълумотлар ҳимояланган алоқа каналлари орқали маълумотларни қайта ишлаш марказига қоидабузарликлар устида ишлаш учун юборилади.",
        text6:
          "Комплекс, ишлаш жараёнида чорраҳадан ўтган барча транспорт воситаларини қайд этади. Аниқланмаган ёки давлат рақамлари номаълум бўлган транспорт воситаларини қидирув мақсадлари учун қайд этиш имкони кўзда тутилган. ",
      },
    },
    functions: {
      title: "АСОСИЙ ФУНКЦИЯЛАР ВА ХУСУСИЯТЛАРИ",
      items: {
        item1: {
          title: "АВТОМАТИК ФОТО-ВИДЕО ҚАЙД ЭТИШ",
          info: `<ul>
          <li>
          Барча транспорт воситаларининг чорраҳадаги йўл чизиқларидаги ўрнини автоматик тарзда аниқлаш ва йўл ҳаракати қоидалари бузилишининг фото-видеога қайд этиш. 
          </li>
          <li>
          Олтитагача бўлган ҳаракат чизиқларини битта камера орқали назорат қилиш.
          </li>
          <li>
          Светофор сигналларини аниқлаш - видеокадрлар орқали ёки йўл назоратига симли уланиш орқали аниқлаш.
          </li>
          <li>
          2дан 300км/соатгача (радар мавжудлигида).
          </li>
          <li>
          Қайд этилган ҳар бир қоидабузарлик бўйича фотоматериаллар ва водеолавҳаларнинг автоматик сақланиб қолиниши. 
          </li>
          <li>
          Комплекс тизимли вақтни автоматик тўғрилаш билан ГЛОНАСС/GPS модули.
          </li>
        </ul>`,
        },
        item2: {
          title: "ДАВЛАТ РАҚАМЛАРИНИ АНИҚЛАШ ВА транспорт воситалари ҚИДИРУВИ",
          info: `<ul>
            <li>
            Янги форматларни тез ўрганиш ва дунёнинг кўплаб мамлакатлари давлат рақамларини, жумладан, икки қаторли рақамларни аниқлашни таъминлайдиган, нейрон тармоқлар технологияси асосида давлат рўйҳатга олиш рақамларини автоматик аниқлаш.
            </li>
            <li>
            У ёки бу мамлакатларнинг давлат рўйҳатга олиш рақамларини аниқлашни ўчириш ёки ёқиш имкони. 
            </li>
            <li>
            Маълумотлар базасига кўра аниқланган рақамларни текшириб, операторни хабордор қилиш ҳамда рақамни овозли эълон қилиш. 
            </li>
            <li>
            Кўринмайдиган ёки давлат рақами мавжуд бўлмаган транспорт воситаларини қидируш учун махсус «Ушлаш» режими
            </li>
          </ul>`,
        },
        item3: {
          title: "Транспорт воситалари ТАСНИФИ",
          info: `<ul>
            <li>
            "Автотранспорт воситаларининг турини автоматик аниқлаш ва уларни тўртта асосий тоифа бўйича (енгил ва юк машиналари, автобуслар, ўрта ўлчамли транспорт воситалари) таснифлаш.
            </li>
            <li>
            Автотранспорт воситаларининг русуми ва модели бўйича автоматик аниқлаш (сўров бўйича).
            </li>
            <li>
            Автотранспорт воситалари тоифасига кўра, ЙҲҚга мувофиқ автоматик тарзда тезлик чегарасини ўзлаштириш. 
            </li>
            <li>
            Транспорт воситаларининг берилган тоифада (юк, автобус ва бошқалар) алоҳида чизиқлар ёки умумий йўл бўйича ҳаракатланишига қўйилган тақиқни автоматик назорат қилиш.
            </li>
          </ul>`,
        },
        item4: {
          title: "ВИДЕОКУЗАТУВ",
          info: `<ul>
            <li>
            RTSP протоколи орқали юқори аниқликдаги видео оқимини трансляция қилиш. 
            </li>
            <li>
            Маълум бир давр оралиғидаги видеони юклаб олиш имконияти билан узлуксиз видео ёзиш.
            </li>
          </ul>`,
        },
        item5: {
          title: "МАЪЛУМОТ УЗАТИШ",
          info: `<ul>
            <li>
            Маълумотларни шифрланган симли ёки симсиз алоқа каналлари (3G/4G) орқали маълумотлар маркази сервирига юбориш.
            </li>
            <li>
            Асосий канал жавоб бермай ёки ишламай қолганда (Wi-Fi, 4G) автоматик равишда заҳира каналларига уланиш. 
            Ўзбекистон Республикаси ИИВ тизими билан мослик.
            </li>
            <li>
            Комплексдан параллель равишда турли ҳил серверларга маълумотларни узатиш имкони.
            </li>
          </ul>`,
        },
        item6: {
          title: "ҲИМОЯ ВА ХАВФСИЗЛИК",
          info: `<ul>
            <li>
            Маълумотлар ва ўрнатилган дастурий таъминотни рухсат этилмаган ўзгаришлардан ҳимоялаш. 
            </li>
            <li>
            Экспорт қилинадиган маълумотлар электрон-рақамли имзо билан ҳимояланган. 
            </li>
            <li>
            Комплекс фойдаланувчиси ҳаракатлари ва ҳодисалари журналини юритиш.
            </li>
          </ul>`,
        },
        item7: {
          title: "ТУНГИ ВАҚТДА ИШЛАШ",
          info: `<ul>
            <li>
            Тунги вақтда ишлаш учун инфрақизил ёритгичлар. 
            </li>
            <li>
            Қўшимча ИҚ-прожекторларни ўрнатиш имкони.
          </li>
          </ul>`,
        },
        item8: {
          title: "СТАТИСТИКА",
          info: `<ul>
            <li>
            Чорраҳада транспорт оқими интенсивлиги ҳақидаги маълумотлар статистикасини тўплаш. 
            </li>
            <li>
            Барча чорраҳада ёки танланган камералар орқали ЙҲҚ бузилиши турлари, транспорт воситалари тоифалари ҳамда қайд этилган йўл ҳаракати қоидалари бўйича таҳлил.
          </li>
          </ul>`,
        },
      },
    },
    guarantee: {
      bot1: "Евроосиё иқтисодий иттифоқининг мувофиқлик сертификати№ ЕАЭС RU C-RU.ME83.B.00005/19. 28.08.2024 йилгача амал қилиш муддати.",
      bot2: "Ўртача тезликни ўлчаш методикаси аттестацияси ҳақидаги гувоҳнома №1084/253-(RA.RU.310494)-2019.",
    },
  },
};
